import React from 'react';


const ButtonDelete = () => {
    return <div className="button-delete">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="#888888;" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 1L5 5M5 5L9 9M5 5L1 1M5 5L1 9" stroke="#C4C4C4" strokeWidth="2"/>
        </svg>
        Удалить программу
    </div>
}

// const ButtonView = () => {
//     return <div className="button-view">
//         Предварительный просмотр
//     </div>
// }

// const ButtonSave = (props) => {
//     return <div className="button-save">
//         {props.label}
//     </div>
// }

// const ButtonSaveSmall = (props) => {
//     return <div className="button-save button-save--small">
//         {props.label}
//     </div>
// }


const ENUM_STATES = {
    delete: [<ButtonDelete key={'btn_delete'}/>], 
    // <ButtonSave key={'btn_save'} label='Сохранить изменения'/> ],

    // view: [<ButtonView key={'btn_view'}/>, 
    // <ButtonSaveSmall  key={'btn_small'} label='Сохранить'/>],
    
    // default: <ButtonSave key={'btn_save'} label='Сохранить изменения'/>
}

function FormFooter({state}) {
    return <div style={{ display: "flex"}}>
        {ENUM_STATES[state]}
    </div>
}

export default FormFooter;
import Logo from "../../components/Sidebar/Logo/Logo";
import { useEffect, useState } from "react";
import UserService from "../../services/UserService";
import { useHistory } from "react-router-dom";
import {Link} from "react-router-dom";
import { useTranslations } from "../../context/LanguageContext";
import SideLangSwitcher from "../../components/LangSwitcher/SideLangSwitcher";

// Страничка для подтверждения почты

const Authentication = () => {
    // потом в апи кидаем элемент с индексом 1, чтобы передать токен
    let url = window.location.href.split('?')

    let history = useHistory();
    let t = useTranslations()

    useEffect(() => {
        const data = async() => {
            let service = new UserService();
            await service.confirmEmail(url[1]);

            setTimeout(() => {
                history.push(`/`);
            }, 10000);
        }
        data()
    }, []);

    return <div className="section__confrimEmail">
        <Logo />
        <div className="confrimEmail--langSwitch">
            <SideLangSwitcher/>
        </div>
        <div className="confrimEmail--title">
            <h1>{t("confirmEmail.title")}</h1>
        </div>
        <h1>{t("confirmEmail.subtitle")}</h1>
        <Link to='/' className="confrimEmail--button">
            {t("confirmEmail.button")}
        </Link>
    </div>
}

export default Authentication;
class CkEditorLoadManager {
    constructor() {
        this.loaded = false;
        this.loading = false;
        this.handlers = [];
    }

    load() {
        if (this.loaded && !this.loading) {
            while (this.handlers.length > 0) {
                let handler = this.handlers.pop();
                handler();
            }
            return;
        }
        if (this.loading) {
            return;
        }

        this.loading = true;

        let script = document.createElement('script');
        script.src = '/ckeditor/ckeditor.js';
        document.head.appendChild(script);
        script.onreadystatechange = script.onload = () => {
            this.loading = false;
            this.loaded = true;
            this.load();
        };

    }

    addEventHandler(cb) {
        this.handlers.push(cb);
        this.load();
    }


}

export default new CkEditorLoadManager();


import Tooltip from "../Tooltip/Tooltip";
import React from "react";
import BaseLangSwitcher from "../LangSwitcher/BaseLangSwitcher";

/**
 * Некий блок на форме, который отбрасывает день и может иметь пурпурный бордюр сверху
 * props: {changed}
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormSection(props) {
    let classes = ["form-section"];

    if (props.changed) {
        classes.push("form-section--changed")
    }

    let {required, subtitle, title, tip, inputTitle, langSwitch} = props;

    return <div className={classes.join(' ')}>
        <div className="form-section__head">
            <div className="form-section__head-top">
                <div>
                    <div className="form-section__title">
                        {title} {required ? <span className='star'>*</span> : null}
                    </div>
                    <Tooltip text={tip}/>
                </div>
                {
                    langSwitch ?  null : <BaseLangSwitcher {...props}/>
                }
            </div>
            {
                subtitle ? <div className="form-section__subtitle">{subtitle}
                </div> : null
            }

        </div>
        {
            inputTitle ? <div className="form-section__input-title">{inputTitle}
            </div> : null
        }

        {
            props.children
        }
    </div>
}
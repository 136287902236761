import React, { useState, useEffect } from 'react'
import Logo from "../../components/Sidebar/Logo/Logo";
import UserPanel from "../../components/Sidebar/UserPanel/UserPanel";
import SideLangSwitcher from "../../components/LangSwitcher/SideLangSwitcher";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { useRecoilState, useRecoilValue } from "recoil";
import { localIsAdminState, localIsAdmin, userState } from '../../store';
import { useTranslations } from '../../context/LanguageContext';
import UserService from '../../services/UserService';

function Side(props) {
    let user = useRecoilValue(userState);
    let t = useTranslations();

    // для проверки юзера, если он вошел, то показывать левое меню
    const [userApi, setUserApi] = useState([''])
    useEffect(() => {(async () => {
        let service = new UserService();
        let API = await service.getUserInfo();

        setUserApi(API.message)
    })();
    }, [])

    // Является ли пользователь на самом деле админом
    let isAdminFromApi = user.model.is_admin;

    let [checked, setChecked] = useState(false);
    let toggleChecked = () => setChecked(value => !value);

    let [localIsAdmin, setLocalIsAdmin] = useRecoilState(localIsAdminState);
    
    let menuList = [];

    menuList = [
        // {
        //     label: <FormattedMessage id="side.overview" />,
        //     link: "/overview"
        // },
        {
            label: <FormattedMessage id="side.institutions" />,
            link: "/institutions"
        },
        // {
        //     label: <FormattedMessage id="side.services" />,
        //     link: "/paid-services"
        // },
        // {
        //     label: <FormattedMessage id="side.finance" />,
        //     link: "/finances"
        // },
        {
            label: <FormattedMessage id="side.settings" />,
            link: "/settings"
        },
    ];

    return <> {userApi != 'Unauthenticated.' ? 
        <div className="sidenav">
        <div className={checked ? "mobile-menu mobile-menu--active" : "mobile-menu"}>
            <label
                className={checked ? "hamburger-icon hamburger-icon--active" : "hamburger-icon"}
                htmlFor="menu-toggle"
            />
            <input type="checkbox" id="menu-toggle" checked={checked} onChange={toggleChecked} />
            <div className="sidenav__inner">
                <Logo />
                <div className="sidenav__menu">
                    {
                        menuList.map(
                            (item, index) => {
                                return <NavLink
                                    className="sidenav__menu-link"
                                    key={index}
                                    activeClassName="active"
                                    to={item.link}
                                    onClick={toggleChecked}
                                >
                                    {item.label}
                                </NavLink>
                            }
                        )
                    }

                    {
                        isAdminFromApi && <div>
                            {t("general.admin")}? &nbsp;
                            <input checked={localIsAdmin} type="checkbox" onChange={() => setLocalIsAdmin(!localIsAdmin)} />
                        </div>
                    }
                </div>
                <div className="sidenav__bottom sidenav-bottom">
                    <div className="sidenav-bottom__user">
                        <UserPanel />
                    </div>

                    <div className="sidenav-bottom__lang">
                        <SideLangSwitcher />
                    </div>
                </div>
            </div>
        </div>
        </div> : ''
    }
    </>

}

export default Side;
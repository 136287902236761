import React, {useContext, useEffect, useState} from 'react';
import DefaultTable from "../../Table/DefaultTable";
import SchoolProgramService from "../../../services/SchoolProgramService";
import {LanguageContext, useTranslations} from "../../../context/LanguageContext";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";


function Icon(item) {
    let mapIconToResidence = {
        0: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.6464 13.3536C12.8417 13.5488 13.1583 13.5488 13.3536 13.3536C13.5488 13.1583 13.5488 12.8417 13.3536 12.6464L12.6464 13.3536ZM7.35355 6.64645C7.15829 6.45118 6.84171 6.45118 6.64645 6.64645C6.45118 6.84171 6.45118 7.15829 6.64645 7.35355L7.35355 6.64645ZM6.64645 12.6464C6.45118 12.8417 6.45118 13.1583 6.64645 13.3536C6.84171 13.5488 7.15829 13.5488 7.35355 13.3536L6.64645 12.6464ZM13.3536 7.35355C13.5488 7.15829 13.5488 6.84171 13.3536 6.64645C13.1583 6.45118 12.8417 6.45118 12.6464 6.64645L13.3536 7.35355ZM19.5 10C19.5 4.75511 15.2449 0.5 10 0.5V1.5C14.6926 1.5 18.5 5.30739 18.5 10H19.5ZM10 0.5C4.75511 0.5 0.5 4.75511 0.5 10H1.5C1.5 5.30739 5.30739 1.5 10 1.5V0.5ZM0.5 10C0.5 15.2449 4.75511 19.5 10 19.5V18.5C5.30739 18.5 1.5 14.6926 1.5 10H0.5ZM10 19.5C15.2449 19.5 19.5 15.2449 19.5 10H18.5C18.5 14.6926 14.6926 18.5 10 18.5V19.5ZM13.3536 12.6464L7.35355 6.64645L6.64645 7.35355L12.6464 13.3536L13.3536 12.6464ZM7.35355 13.3536L13.3536 7.35355L12.6464 6.64645L6.64645 12.6464L7.35355 13.3536Z"
                fill="#C4C4C4"/>
        </svg>,

        1: <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM12.2629 6C12.0698 6.0005 11.8846 6.07261 11.7475 6.20072C11.7405 6.2071 11.7349 6.21389 11.7289 6.22104L8.32508 10.3075L6.27282 8.37463C6.20553 8.30658 6.12438 8.252 6.03421 8.21414C5.94405 8.17629 5.84671 8.15593 5.74802 8.15429C5.64932 8.15265 5.55129 8.16976 5.45976 8.20459C5.36823 8.23943 5.28509 8.29128 5.21529 8.35705C5.14549 8.42282 5.09047 8.50116 5.0535 8.58741C5.01653 8.67365 4.99837 8.76603 5.00011 8.85902C5.00186 8.95202 5.02346 9.04374 5.06363 9.1287C5.10381 9.21366 5.16173 9.29013 5.23395 9.35353L7.82623 11.7971C7.8961 11.863 7.97936 11.9149 8.071 11.9497C8.16265 11.9845 8.26082 12.0016 8.35963 11.9999C8.45844 11.9982 8.55587 11.9777 8.64608 11.9397C8.7363 11.9016 8.81746 11.8469 8.8847 11.7786L12.7971 7.17039C12.9303 7.03864 13.0032 6.86277 12.9999 6.68082C12.9966 6.49886 12.9174 6.32544 12.7795 6.19804C12.6416 6.07064 12.4561 5.9995 12.2629 6Z"
                fill="#27AE60"/>
        </svg>
    }
    let icon = mapIconToResidence[item.price_accommodation] || '';
    return <div className="cell__checked">
        {icon}
    </div>
}

function ProgramName(item) {
    const {language} = useContext(LanguageContext);

    if (item.translations) {
        return <Link className="table-link"
                    to={location => `${location.pathname}/${item.id}`}>
            {item.translations[language].name}
        </Link>
    }

    return <Link className="table-link" to={{
        pathname: '/' + item.id || "#",
    }}>
        {item.name}
    </Link>


}


function ProgramStatus(item) {
    let t = useTranslations();
    // let mapStatusToClass = {
    //     110: 'cell__label cell__label--warning',
    //     20: 'cell__label cell__label--declined',
    //     30: 'cell__label',
    //     10: 'cell__label cell__label--success',
    //     50: 'cell__placement cell__placement--dash'
    // }

    // function PostStatusCell(item) {
        let mapStatusToClass = {
            110: 'cell__label cell__label--warning',
            10: 'cell__label cell__label--success',
        }
        let mapStatusToLabel = {
            10: t("status.active"),
            110: t("status.change")
        }
        let className = mapStatusToClass[item.program_status.status] || '';
        let label = mapStatusToLabel[item.program_status.status] || '';

        return <div className={className}>
            {label}
        </div>
    // }

    // let className = mapStatusToClass[item.program_status.status] || '';
    
    

    // return <div className={className}>
    //     {item.program_status.text}
    // </div>;
}

function ProgramDuration(item) {
    const {language} = useContext(LanguageContext);
    return `${item.duration || ''}  ${item.translations[language].durationTypeName || ''}`
}

function ProgramAge(item) {
    return `${item.age_from  || ''} - ${item.age_to || ''}`
}

function ProgramPrice(item) {
    let price = Math.floor(item.price)
    return `${price || ''}  ${item.priceTypeName || ''}`
}

function Programs(props) {
    let school_id = props.school_id;
    let [model, setModel] = useState([]);

    let getProgramsData = async () => {
        let spService = new SchoolProgramService();
        let response = await spService.getSchoolProgramsList(school_id);
        setModel(response);
    }


    useEffect(()=>{(async () => {
        await getProgramsData();
    })();
    }, []);

    const columns = [
        {
            header: () => {
                return <>
                    <label className="flex-table__label">
                        <FormattedMessage id="table.name"/>
                    </label>
                </>
            },
            value: ProgramName

        },
        {
            header: () => {
                return <>
                    <label className="flex-table__label">
                        <FormattedMessage id="table.status"/>
                    </label>
                </>
            },
            value: ProgramStatus
        },
        {
            header: () => {
                return <>
                    <label className="flex-table__label">
                        <FormattedMessage id="table.programs.age"/>
                    </label>
                </>
            },
            value: ProgramAge
        },
        {
            header: () => {
                return <>
                    <label className="flex-table__label">
                        <FormattedMessage id="table.programs.price"/>
                    </label>
                </>
            },
            value: ProgramPrice
        },
        {
            header: () => {
                return <>
                    <label className="flex-table__label">
                        <FormattedMessage id="table.programs.duration"/>
                    </label>
                </>
            },
            value: ProgramDuration
        },

        // {
        //     header: () => {
        //         return <>
        //             <label className="flex-table__label">
        //                 <FormattedMessage id="table.programs.accommodation"/>
        //             </label>
        //         </>
        //     },
        //     value: Icon
        // },
    ];


    return <>
        <div className="tabs__block">
            <span>{model.length} программ</span>
            <div className="program-button">
                <Link to={location => `${location.pathname}/create`}>
                    <FormattedMessage id="programs.create"/>
                </Link>
            </div>
        </div>
        <div className="w-container">
            <DefaultTable
                model={model || []}
                columns={columns}
                size="medium"
            />
        </div>
    </>
}

export default Programs;
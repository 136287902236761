import React, { useContext, useEffect, useState } from "react";
import Ckeditor from "../Ckeditor/Ckeditor";
import FormSection from "./FormSection";
import SimpleFileUpload from "../Fields/SimpleFileUpload";
import RenderButtons from "../Buttons/RenderButtons";
// import createProgramButtons from "../Buttons/createProgramButtons";
import { SafeHTML } from "../SafeHtml/SafeHtml";
import { statuses } from "../../constants/statuses";
import { FormattedMessage } from "react-intl";
// пока закоменчу, но вроде не требуются
// import UserService from "../../services/UserService";
// import { useTranslations } from "../../context/LanguageContext";
// import { setegid } from "process";
// import { setRecoilExternalState } from "../../RecoilExternalStatePortal";
// import PostService from "../../services/PostService";

function createProgramButtons({buttons}) {
    let button = [];
    for (let i = 0; i<buttons.length; ++i) {
        if (buttons[i].show == true) {
            button.push(buttons[i]);
        }
    }
    return button;
}

function PostForm(props) {
    let {
        school_id,
        title,
        onChange,
        diff,
        buttonsActions,
        status,
        model,
        isDisabled,
        errors,
        buttons
    } = props;

    function StyleCurrentValue(propertyName) {
        if (diff[propertyName] && status === statuses.STATUS_POST_MODERATION) {
            return {
                border: "3px solid #F5E1A6",
                color: "#333333",
            }
        }
        return {}
    }

    let showError = (field) => {
        return errors[field] ? <div className="form-error">{errors[field].join(', ')}</div> : null;
    }

    function RenderSubmitButtons() {
        let create = props.buttonsActions.create
        if (status === statuses.STATUS_UNDEFINED) {
            return <div style={{ display: "flex" }}>
                <button onClick={create} type="submit" className="button-save button-save--small">
                    <FormattedMessage id="button.save" />
                </button>
            </div>
        }
        return <></>
    }


    function switchCkeditor(status, modelProp) {
        if (isDisabled) {
            return <SafeHTML className="diff-text"
                style={StyleCurrentValue(modelProp)}>
                {diff[modelProp] || model[modelProp] || ''}
            </SafeHTML>

        } else {
            return <Ckeditor value={diff[modelProp] || model[modelProp] || ''}
                onChange={newValue => onChange(modelProp, newValue)} />
        }
    }
    
    // Выводит форму создания статьи
    return <>
        <div className='article-title'>
            <p>{title}</p>
        </div>
        <form onSubmit={buttonsActions.default}>
            <FormSection {...props}
                title={<FormattedMessage id="post.field.name" />}
                langSwitch={true}
            >

                <input type="text"
                    className="diff-text diff-text--admin"
                    style={StyleCurrentValue('name')}
                    value={diff.name || model.name || ""}
                    onChange={e => onChange('name', e.target.value)}
                    disabled={isDisabled}
                />
                {showError('name')}
            </FormSection>
            <FormSection {...props}
                title={<FormattedMessage id="post.field.image" />}
                langSwitch={true}
            >
                <SimpleFileUpload
                    school_id={school_id}
                    image={diff.image || model.image || <FormattedMessage id="tabs.article" />}
                    style={StyleCurrentValue('image')}
                    onChange={newValue => onChange('image', newValue)}
                    disabled={isDisabled}
                    status={status}
                />
            </FormSection>
            <FormSection {...props}
                title={<FormattedMessage id="post.field.short" />}
                langSwitch={true}
            >
                {switchCkeditor(status, 'announcement_description')}

            </FormSection>
            <FormSection {...props}
                title={<FormattedMessage id="post.field.text" />}
                langSwitch={true}>
                {switchCkeditor(status, 'description')}
                {showError('description')}
            </FormSection>
            {/* сама кнопка создания статьи */}
            <RenderSubmitButtons actions={buttonsActions}/>
            {
                status !== statuses.STATUS_UNDEFINED ? <RenderButtons buttons={createProgramButtons({buttons, status})} /> : ''
            }
        </form>
        
    </>

}

export default PostForm;
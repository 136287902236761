import React, {useEffect, useRef, useState} from 'react';
import H from "@here/maps-api-for-javascript";
import onResize from 'simple-element-resize-detector';
import styles from "@here/maps-api-for-javascript/bin/mapsjs-ui.css"
import AutocompleteBqb from "../Fields/AutocompleteBqb";

function HereMap(props) {
    let {value, onChange, fieldName, onClick, language} = props;
    let [localValue, setLocalValue] = useState(value);
    let [changed, setChanged] = useState(false);
    let container = useRef();
    let ui = useRef();
    let [position, setPosition] = useState({});
    let map = useRef();
    let platform = useRef();
    let svg = '<svg width="50" height="50" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.39804 15.804C8.88097 15.0348 14 12.0163 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 12.0163 5.11903 15.0348 6.60196 15.804C6.85483 15.9351 7.14517 15.9351 7.39804 15.804ZM7 10C8.65685 10 10 8.65685 10 7C10 5.34315 8.65685 4 7 4C5.34315 4 4 5.34315 4 7C4 8.65685 5.34315 10 7 10Z" fill="black"/>' +
        '</svg>'
    let apiKey = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_HERE_KEY : process.env.REACT_APP_DEV_HERE_KEY;

    let centerMapByValue = (query) => {
        let service = platform.current.getSearchService();
        service.geocode({
            q: query
        }, (result) => {
            // Add a marker for each location found
            result.items.forEach((item) => {
                map.current.setCenter(item.position);
            });
        }, alert);
    }

    function reverseGeo(lat, lng) {
        let service = platform.current.getSearchService();

        service.reverseGeocode({
            at: `${lat},${lng}`
        }, (result) => {
            result.items.forEach((item) => {
                ui.current.addBubble(new H.ui.InfoBubble(item.position, {
                    content: item.address.label
                }));
                setLocalValue(item.address.label)
                onChange(item.address.label)
                setChanged(true)
            });
        }, alert);
    }

    // let changeMarkerPosition = (item) => {
    //     setPosition(item)
    //     addMarkerToMap(item.data.position.lat, item.data.position.lng)
    // }

    function addMarkerToMap(lat, lng) {
        let icon = new H.map.Icon(svg);
        let marker = new H.map.Marker({lat: lat, lng: lng}, {icon: icon});
        let objects = map.current.getObjects()
        if (objects.length > 0) {
            map.current.removeObject(objects[0])
            ui.current.getBubbles().forEach(bub => ui.current.removeBubble(bub));
        }
        map.current.addObject(marker);
        map.current.setCenter({lat: lat, lng: lng})

        setTimeout(() => {
            reverseGeo(lat, lng)
        }, 1000)
    }


    function setUpClickListener() {
        map.current.addEventListener('tap', function (evt) {
            let coord = map.current.screenToGeo(evt.currentPointer.viewportX,
                evt.currentPointer.viewportY);
            let lat = coord.lat;
            let lng = coord.lng;
            addMarkerToMap(lat, lng)
        });
    }

    useEffect(() => {
        if (!container.current) return;
        let H = window.H;
        platform.current = new H.service.Platform({
            apikey: apiKey
        });
        let defaultLayers = platform.current.createDefaultLayers();
        map.current = new H.Map(
            container.current,
            defaultLayers.vector.normal.map,
            {
                pixelRatio: window.devicePixelRatio || 1,
                zoom: 10,
                center: {lat: 55.7563, lng: 37.613}
            },
        );

        // Enable the event system on the map instance:
        let mapEvents = new H.mapevents.MapEvents(map.current);

        onResize(container.current, () => {
            map.current.getViewPort().resize();
        });

        let behavior = new H.mapevents.Behavior(mapEvents);

        ui.current = H.ui.UI.createDefault(map.current, defaultLayers, 'ru-RU');

        ui.current.getControl('zoom').setDisabled(false);

        setUpClickListener();

        centerMapByValue(value)

        return () => {
            map.current.dispose();
        }
    }, [container])

    return <>
        <AutocompleteBqb
            query={localValue}
            refs={platform}
            onChange={onChange}
            onClick={onClick}
            fieldName={fieldName}
            changed={changed}
            language={language}
            changeMarkerPosition={addMarkerToMap}
            placeholder="Начните вводить адрес"
        />
        <div className="here-map" id='map'
             ref={container}/>
    </>
}

export default HereMap;
import api, { ApiContext } from "../api";


class PostService {

    getBaseUrl(school_id) {
        return `/api/school/${school_id}/post/`;
    }

    upgradeField(field) {
        return {...field, initialRenderStatus: field['status']};
    }

    async getPostsList(school_id, model) {
        return await api.post(this.getBaseUrl(school_id) + 'list/', model)
    }

    async uploadPostImage(school_id, image, id) {
        return await api.upload(this.getBaseUrl(school_id) + 'upload/', image +'/');
    }

    async createNewPostRequest(school_id, model) {
        return await api.post(this.getBaseUrl(school_id) + 'create/', model);
    }

    async getPost(school_id, post_id) {
        let postData = await api.get(`/api/school/${school_id}/post/${post_id}/`);
        return this.upgradeField(postData)
    }

    async updateRequest(school_id, post_id, model) {
        return await api.post(`/api/school/${school_id}/post/${post_id}/update/`, model);
    }

    async applyRequest(school_id, post_id, model) {
        return await api.post(`/api/school/${school_id}/post/${post_id}/apply/`, model);
    }

    async withdrawRequest(school_id, post_id, model) {
        return await api.post(`/api/school/${school_id}/post/${post_id}/withdraw/`, model);
    }

    async deletePost(school_id, post_id, model) {
        return await api.post(`/api/school/${school_id}/post/${post_id}/request-delete/`, model)
    }

    async requestDeletePost(school_id, post_id, model) {
        console.log("Запрос на удаление")
    }
}

export default PostService;
import React, { useState } from "react";
import FormSection from "../Forms/FormSection";
import RenderButtons from "../Buttons/RenderButtons";
import createButtons from "../Buttons/createButtons";
import { statuses } from "../../constants/statuses";
import { flags } from "../../constants/flags";
import { hasUncaughtExceptionCaptureCallback } from "process";
import { useIsAdmin } from "../../store";


function WrapField(props) {
    let [loading, setLoading] = useState(false);

    let {
        inputPart,
        status,
        initialRenderStatus,
        user,
        fieldName,
        handleUserRequest,
        additionalProperty,
        save,
        onChange,
        langSwitch,
    } = props;

    if (Object.keys(additionalProperty).length !== 0) {
        fieldName = [fieldName, additionalProperty.fieldName];
    }

    let changeStatus = (newStatus) => {
        if (Array.isArray(fieldName)) {
            fieldName.forEach((item, index) => {
                onChange(item, newStatus, 'status')
            })
        } else {
            onChange(fieldName, newStatus, 'status');
        }
    }

    let wrapActionWithLoading = async function (func) {
        setLoading(true);

        let result;

        if (func.constructor.name == 'AsyncFunction') {
            result = await func();
        } else {
            result = func();
        }

        setLoading(false);

        return result;
    }

    let wrapAllActionWithLoading = (obj) => {
        let newOb = {};

        Object.entries(obj).map(([index, value]) => {
            newOb[index] = () => wrapActionWithLoading(value);
        })

        return newOb;
    }

    let buttonActions = {
        withDraw: async () => {
            return await handleUserRequest(fieldName, flags.WITHDRAW)
        },
        apply: () => {
            return handleUserRequest(fieldName, flags.ACCEPT);
        },
        edit: () => {
            return changeStatus(statuses.STATUS_EDIT);
        },
        forceAccept: () => {
            return handleUserRequest(fieldName, flags.FORCE, props.language);
        },
        userEdit: () => {
            return changeStatus(statuses.STATUS_USER_EDIT)
        },
        managerEdit: () => {
            return changeStatus(statuses.STATUS_MANAGER_EDIT)
        },
        cancel: () => {
            return changeStatus(initialRenderStatus)
        },
        reject: () => {
            return handleUserRequest(fieldName, flags.REJECT);
        },
        save: async () => {
            return await save(fieldName, props.language);
        }
    }

    let isAdmin = useIsAdmin();

    return <FormSection {...props} langSwitch={langSwitch}>
        {inputPart}
        <RenderButtons buttons={createButtons(wrapAllActionWithLoading(buttonActions), status, isAdmin)} isLoading={loading} />
    </FormSection>
}

export default WrapField;
import React from "react";
import { Link } from 'react-router-dom';


function TabBlock(props) {
    return <div className="tabs__block">
        <span>{props.label}</span>
        <div className="program-button">
            <Link to={props.href}>{props.button}</Link>
        </div>
    </div>
}

export default TabBlock;
import React from "react";
import {FormattedMessage} from "react-intl";

function SettingsHeader() {
    return <div className="heading">
        <h1>
            <FormattedMessage
            id = "settings.title"
        />
        </h1>
    </div>
}

export default SettingsHeader;
import React from "react";
import {Link} from 'react-router-dom';
import {FormattedMessage} from "react-intl";


function InstitutionHeader() {
    return <>
        <div className="heading">
            <h1><FormattedMessage id="institutions.title"/></h1>
            <Link to="/institutions/create" className="heading__button">
                <FormattedMessage id="institutions.create"/>
            </Link>
        </div>
        <div className="heading__subtitle">
            <p>
                <FormattedMessage id="institutions.subtitle"/>
            </p>
        </div>
    </>
}

export default InstitutionHeader;
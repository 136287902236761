import FormSplit from "../Forms/FormSplit";
import DefaultSelect from "../Fields/DefaultSelect";
import { SafeHTML } from "../SafeHtml/SafeHtml";
import FormGroup from "../Forms/FormGroup";
import SimpleDivComponent from "../Fields/SimpleDivComponent";
import ChatSupportLink from "../Chat/ChatSupportLink";
import ShowMedia from "../Gallery/ShowMedia";
import ShowFiles from "../Gallery/ShowFiles";
import ComplexRadio from "../Fields/ComplexRadio";
import MultiLevelSelect from "../Fields/MultiLevelSelect";
import DefaultRadio from "../Fields/DefaultRadio";
import { CustomRange } from "./DefaultRangeField";
import { FormattedMessage } from "react-intl";

// Отображение разницы при запросе на изменение сущности


function DiffFieldRenderer(props) {
    let { direction, initialValue, difference, value, className, options, diffColor, type, additionalProperty, onChange } = props;
    let initialInput;
    let differenceInput;
    let Component = direction === 'horizontal' ? FormSplit : SimpleDivComponent;

    switch (type) {
        case 'select':
            initialInput = <DefaultSelect {...props} options={options} value={initialValue} isDisabled={true} />
            differenceInput =
                <DefaultSelect {...props} options={options} value={value} diff={difference} isDisabled={true}
                    diffColor={diffColor} />
            break;
        case 'media':
            initialInput = <ShowMedia{...props} model={initialValue} />
            differenceInput = <ShowMedia {...props} model={value} showDeletions={true} disableSort={true} />
            break;

        case 'files':
            initialInput = <ShowFiles{...props} model={initialValue} />
            differenceInput = <ShowFiles {...props} model={value} showDeletions={true} disableSort={true} />
            break;

        case 'complex':
            initialInput = <ComplexRadio {...props}
                value={[initialValue, additionalProperty.initial_value]}
                options={options}
                disabled={true}
            />

            differenceInput = <ComplexRadio {...props}
                value={[value, additionalProperty.value]}
                options={options}
                disabled={true}
            />
            break

        case 'programs':
            initialInput = <MultiLevelSelect {...props}
                value={initialValue}
                options={options}
                isDisabled={true}
            />
            differenceInput = <MultiLevelSelect {...props}
                value={parseInt(value)}
                options={options}
                isDisabled={true}
            />
            break

        case 'radio':
            initialInput = <DefaultRadio
                value={initialValue}
                position={props.position}
                options={options}
                defaultChecked={initialValue}
                disabled={true}
            />
            differenceInput = <DefaultRadio
                value={value}
                position={props.position}
                options={options}
                defaultChecked={value}
                difference={difference}
                disabled={true}
            />
            break

        case 'range':
            initialInput = <CustomRange
                values={initialValue}
                options={options}
                disabled={true}
            />

            differenceInput = <CustomRange
                values={value}
                options={options}
                disabled={true}
            />
            break
        default:
            initialInput = <SafeHTML className={className}>{initialValue}</SafeHTML>
            differenceInput = <SafeHTML className={className}>{difference}</SafeHTML>
            break;
    }
    return <Component>
        {/* не знаю можно ли так оставлять 
        *
        * ВЫВОДИТ :текущее значение и измененное
        * 
        */}
        <div>
            <FormGroup title={<FormattedMessage id="institutions.current.data" />} >
                {initialInput}
            </FormGroup>

            <FormGroup
                title={<FormattedMessage id="institutions.changed.data" />}
                support={<ChatSupportLink />}
            >
                {differenceInput}
            </FormGroup>
        </div >
    </Component >
}

export default DiffFieldRenderer;
import SingleSelect from "./SingleSelect";
import React from "react";

function ComplexRadio(props) {
    let {
        value,
        fieldName,
        onChange,
        additionalProperty,
        options,
        language,
        disabled,
    } = props;

    let className = disabled ? 'complex-input complex-input--disabled' : 'complex-input'

    let handleChange = (newValue) => { onChange(fieldName, newValue.target.value) }

    return <div className={className}>
        <input
            // name={fieldName}
            type="text"
            value={value}
            onChange={handleChange}
            disabled={disabled}
        />

        <SingleSelect
            options={options}
            additionalProperty={additionalProperty}
            value={false ? additionalProperty.initial_value || "" : additionalProperty && additionalProperty.value || ""}
            onChange={onChange}
            language={language}
            isDisabled={disabled}
        />
    </div>
}

/**
 * <ComplexRadioGood />
 * [{id, name}]
 * по-моему в данный момент легче переписать только этот компонент, 
 * а остальные потом, т.к. надо сдать лк в сроки
 */

function ComplexRadioGood(props) {
    let {
        value, // must be array: count - 2
        // additionalProperty, // ОН НЕ ДОЛЖЕН НИЧЕГО ЗНАТЬ КРОМЕ 2 ЗНАЧЕНИЙ и ТОГО КАК ИХ ИЗМЕНЯТЬ
        options,
        onChange,
        disabled,
        language,
    } = props;

    let localValue = Array.isArray(value) && value.length >= 2 ? value : [null, null];

    let className = disabled ? 'complex-input complex-input--disabled' : 'complex-input';

    let localOnChange = (index, newValue) => {
        if (onChange) {
            let newValueArr = [...localValue];
            newValueArr[index] = newValue;
            onChange(newValueArr);
        }
    }

    return <div className={className}>
        <input
            type="text"
            value={localValue[0]}
            onChange={e => localOnChange(0, e.target.value)}
            disabled={disabled ? "disabled" : ""}
        />

        <SingleSelect
            options={options}
            value={localValue[1]}
            onChange={value => localOnChange(1, value)}
            language={language}
            isDisabled={disabled}
        />
    </div>
}

export default ComplexRadioGood;
import React, { useState, useEffect } from 'react';
import Side from "./pages/Side";
import "./sass/app.scss";
import {
    BrowserRouter as Router, Switch, Route, Redirect,
} from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary'

// Страницы! -----------------------------------------------------------
import Institute from "./pages/Institute"
import Institution from "./pages/Institutions";
import InstitutionCreate from "./pages/InstitutionCreate";
import Overview from "./pages/Overview";
import PaidServices from "./pages/PaidServices";
import Finance from "./pages/Finance";
import Settings from "./pages/Settings";
import Login from "./pages/Login";
import Register from './pages/Register';
import ConfirmEmail from './pages/ConfirmEmail/ConfirmEmail';
// ---------------------------------------------------------------------

import {
    useRecoilState,
    useRecoilValue,
} from 'recoil';

import { userState, userTokenState, languageState } from "./store";
import { getUserToken } from "./helpers";
import { LanguageContextProvider } from "./context/LanguageContext";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import ErrorFallback from "./components/ErrorBoundary/ErrorFallBack";

function App(props) {
    
    let [token, setToken] = useRecoilState(userTokenState);
    let user = useRecoilValue(userState);
    let [loaded, setLoaded] = useState(false);

    let globalLanguage = useRecoilValue(languageState);

    useEffect(() => {
        let tokenFromStorage = getUserToken();
        if (tokenFromStorage) {
            setToken(tokenFromStorage);
        } else {
            setLoaded(true)
        }
    }, []);

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        if (user.loaded) {
            setLoaded(true);
        }
    }, [user.loaded])

    if (!loaded) {
        return "<div>Loading</div>"
    }

    return <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
            //пока так
            window.location.reload()

        }}>
        <Router>
            <LanguageContextProvider>
                {/* хз правильно ли сделал. Было user.loaded === true */}
                { (user.model !== null) && <Side />}
                <Switch >
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/confirm-email" component={ConfirmEmail} />
                    {/* <ProtectedRoute path="/overview" component={Overview} /> */}
                    <ProtectedRoute path="/institutions" exact component={Institution} />
                    <ProtectedRoute path="/institutions/create" exact component={InstitutionCreate} />
                    <ProtectedRoute path="/institutions/:id" component={Institute} />
                    {/* <ProtectedRoute path="/paid-services" component={PaidServices} /> */}
                    {/* <ProtectedRoute path="/finances" component={Finance} /> */}
                    <ProtectedRoute path="/settings" component={Settings} />
                    <Route path="*">
                        <Redirect from='*' to='/institutions' />
                    </Route>
                </Switch>
            </LanguageContextProvider>
        </Router>
    </ErrorBoundary>
}

export default App;

import React, {useState} from "react";
import PostService from "../../services/PostService";
import api from "../../api";
import { useTranslations } from "../../context/LanguageContext";

function SimpleFileUpload(props) {
    let { onChange, school_id, image, style, disabled, status } = props
    let t = useTranslations();

    let withFile = async (file) => {
        if (file !== undefined) {
            let service = new PostService();
            let media = new FormData();
            media.append('files', file);
            return await service.uploadPostImage(school_id, media);
        }
    }

    const localOnChange = async (e) => {
        let file = e.target.files[0];
        let response = await withFile(file);
        onChange({id :response.id, path: response.path});
    };

    let handleClick = () => {
        document.getElementById("post-image").click()
    }

    return <>
            <div className="file-upload">
                {image.path ?
                    <div className="file-upload__preview" style={style}>
                        <img src={api.basePath + image.path} alt="preview"/>
                    </div> : <></>
                }
                <input
                    type="file"
                    name="file"
                    id="post-image"
                    onChange={localOnChange}
                />
                <label htmlFor="file" onClick={disabled ? undefined : handleClick  }>{t("school.select.file")}</label>
            </div>

        </>
}

export default SimpleFileUpload;
import React, {useContext, useEffect, useState} from "react";
import TabBlock from "../Block";
import {Link, useRouteMatch} from "react-router-dom";
import PostService from "../../../services/PostService";
import {LanguageContext} from "../../../context/LanguageContext";
import DefaultTable from "../../Table/DefaultTable";
import {FormattedMessage} from "react-intl";
import { useTranslations } from "../../../context/LanguageContext";


function Posts(props) {
    let {path, url} = useRouteMatch();
    let [posts, setPosts] = useState({data : []});
    let school_id = props.school_id;
    let category = props.componentProps.category
    let langContext = useContext(LanguageContext);
    let t = useTranslations();
    //let pageSize = 5;
    //let pageCount = Math.ceil(articles.length / pageSize);

    useEffect(()=>{(async () => {
        let postService = new PostService();
        let postData = {
            language: langContext.language,
            category: category
        }
        let response = await postService.getPostsList(school_id, postData)
        setPosts(response);
    })();
    }, [langContext])

    function PostStatusCell(item) {
        let mapStatusToClass = {
            120: 'cell__label cell__label--warning',
            10: 'cell__label cell__label--success',
        }
        let mapStatusToLabel = {
            10: t("status.active"),
            120: t("status.moderator")
        }
        let className = mapStatusToClass[item.status] || '';
        let label = mapStatusToLabel[item.status] || '';

        return <div className={className}>
            {label}
        </div>
    }

    function buttonName(item) {
        let name = {
            Article : <FormattedMessage id="article.create"/>,
            News : <FormattedMessage id="news.create"/>,
            Share : <FormattedMessage id="action.create"/>
        }
        return name[item] || "";
    }

    function FillProgramName(item) {
        return <Link className="table-link"
                    to={location => `${location.pathname}/${item.id}`}>
            {item.name}
        </Link>
    }

    let label;
    if (posts && posts.data && posts.data.length) { 
        // при изменении названия перевода проверяйте пожалуйста какой пропс приходит и добавляйте его так "tabs.info.{props}" : "text"
        label = t("tabs.info."+props.componentProps.category) + " " + posts.data.length;
    } else {
        label = "0 " + t("tabs.info."+props.componentProps.category);
    }

    const columns = [
        {
            header: () => {
                return <>
                    <label className="flex-table__label">
                        <FormattedMessage id="table.name"/>
                    </label>
                </>
            },
            value: FillProgramName
        },
        {
            header: () => {
                return <>
                    <label className="flex-table__label">
                        <FormattedMessage id="table.status"/>
                    </label>
                </>
            },
            value: PostStatusCell
        },

    ];

    return <>
        {/* выводит хедер статей */}
        <TabBlock label={label} button={buttonName(category)} href={`${url}/create`}/>

        <div className="table-container">
            <DefaultTable
                model={posts.data || []}
                columns={columns}
                size="small"
            /> 
        </div>
        {/*<TablePagination*/}
        {/*    data={articles}*/}
        {/*    marginPagesDisplayed={1}*/}
        {/*    pageRangeDisplayed={1}*/}
        {/*    pageSize={pageSize}*/}
        {/*    columns={columns}*/}
        {/*    pageCount={pageCount}*/}
        {/*    size="small"*/}
        {/*/>*/}
    </>
}

export default Posts;


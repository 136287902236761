import React, {useState} from "react";

/**
 * Оборачиваем контрол
 * props: {changed}
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function FormGroup(props) {
    let classes = ["form-group"];
    let [state, setState] = useState("disabled")

    if (props.changed) {
        classes.push("form-section--changed")
    }

    let {required, title, subtitle, support} = props;

    return <div className={classes.join(' ')}>
        {
            title ? <div className="form-group__head">
                <label className="form-group__label">
                    {title} {required ? '*' : null}
                </label>
                <div className="form-group__subtitle">{subtitle}</div>
                <div className="form-group__support">
                    {support}
                </div>
            </div> : null
        }
        {props.children}
    </div>
}
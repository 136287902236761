import api from "./api";

import {
    atom,
    selector,
    useRecoilValue,
} from 'recoil';

export const languageState = atom({
    key: 'language',
    default: 'ru',
})

export const userTokenState = atom({
    key: 'userTokenState',
    default: null,
});

export const userState = selector({
    key: 'userState', // unique ID (with respect to other atoms/selectors)
    get: async ({ get }) => {
        const token = get(userTokenState);

        if (token) {
            let info = await api.get('/api/user/info/');

            return {
                loaded: true,
                model: info
            };
        }

        return {
            loaded: false,
            model: null,
        };
    },
});

export const schoolModelState = atom({
    key: 'schoolModelState',
    default: null,
});

// надо использовать селектор
export const localIsAdminState = atom({
    key: 'localIsAdmin',
    default: true,
});

// надо использовать селектор
export const isAdminState = selector({
    key: 'isAdmin',
    get: async ({ get }) => {
        const localIsAdmin = get(localIsAdminState);
        const user = get(userState);

        if (user.model && user.model.is_admin && localIsAdmin) {
            return true;
        }

        return false;
    },
});

export function useIsAdmin() {
    let _isAdmin = useRecoilValue(isAdminState);
    return _isAdmin;
}
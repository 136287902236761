import React from "react";
import { useTranslations } from "../../../context/LanguageContext";
import BreadCrumb from "../../BreadCrumb/BreadCrumb";
import PostManager from "./PostManager";


function CreatePost(props) {
    const goBack = props.componentProps.onClick
    let t = useTranslations()
    return <>
        {/* кнопка возвращения к категории */}
        <BreadCrumb label={t("tabs.back."+props.componentProps.category)} {...props} onClick={goBack} />
        {/* боди категории */}
        <PostManager {...props} category={props.componentProps.category}/>
    </>
}

export default CreatePost;
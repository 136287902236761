import React from "react";

function DefaultTable(props) {
    let {columns, model} = props;
    let mapSizeToClass = {
        default : {
            th: "flex-table__thead-th",
            td: "flex-table__body-td"
        },

        medium : {
            th: "flex-table__thead-th-medium",
            td: "flex-table__body-td-medium"
        },

        small : {
            th: "flex-table__thead-th-small",
            td: "flex-table__body-td-small"
        }
    }

    let className = mapSizeToClass[props.size] || mapSizeToClass.default


    let renderHeader = (colDef) => {
        if (typeof colDef.header === 'function') {
            return colDef.header();
        } else if (typeof colDef.header === 'string') {
            return colDef.header;
        }
    }

    let renderCell = (colDef, item) => {
        if (typeof colDef.value === 'function') {
            return colDef.value(item);
        } else if (typeof colDef.value === 'string') {
            return item[colDef.value];
        }
    }



    return <table className="flex-table">
        <thead className="flex-table__thead">
        {/* Хедер элементов */}
        <tr className="flex-table__thead-tr">
            {
                columns.map(
                    (column, index) =>
                        <th
                            className={className.th}
                            key={index}
                        >
                        {renderHeader(column)}
                    </th>
                )
            }
        </tr>
        </thead>
        <tbody className="flex-table__body">
        {
            model.map(
                //строка элементов
                item => 
                <tr
                    className="flex-table__body-tr"
                    key={item.id}>
                    {
                        columns.map(
                            // 1 элемент
                            (column, index) =>
                            <td
                                className={className.td}
                                key={index}>
                                {renderCell(column, item)}
                            </td>
                        )

                    }
                </tr>
            )
        }
        </tbody>
    </table>
}

export default DefaultTable;
import React  from "react";
import DropZone from "../DropZone/DropZone";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import { useTranslations } from "../../context/LanguageContext";
import { languageState } from "../../store";
import { getRecoilExternalLoadable } from '../../RecoilExternalStatePortal';
/**
 * Компонент выводит список Account School Media
 */
function ShowMedia(props) {
    let {model, markAsDeleted, showDropZone, onSortEnd} = props;
    let t = useTranslations();

    function getApiHost(){
        let lang = getRecoilExternalLoadable(languageState);
    
        const map = {
            'ru': process.env.REACT_APP_API_HOST_RU,
            'en': process.env.REACT_APP_API_HOST_EN,
        };
    
        return lang ? map[lang.contents] : process.env.REACT_APP_API_HOST_RU;
    }

    let {
        showDeletions = false,
        disableSort = true,
        managed = false,
    } = props;

    function truncate(str) {
        return str.length > 40 ? str.substring(0, 39) + "..." : str;
    }

    // SortableElement
    const SortableListItem = SortableElement(({item}) => {
        let classNames = ['image-wrap'];
        let itemClassName = ['gallery__item']
        let mainLabel = false;

        if (showDeletions && item.mark_delete) {
            classNames.push('image-wrap--deleted');
        }

        if (item.mark_added) {
            classNames.push('image-wrap--added');
        }

        if (item.mark_main) {
            itemClassName.push('gallery__item--main')
            mainLabel = true
        }

        return <div className={itemClassName.join(' ')} key={item.name}>
            <div className="button-wrap">
                <div className={classNames.join(' ')}>
                    {managed ? <svg x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
                        <g>
                            <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                                <path
                                    d="M4672,4491.3c-174.3-278.8-512.6-818-752.4-1197.3l-432.6-693h510.5h510.5V1596.4V591.8H3503.3H2498.7l-4.1,508.4l-6.1,506.4L1295.3,858.3C637.2,446.2,100,105.9,100,99.7c0-6.1,537.2-342.4,1195.3-748.3l1193.2-738.1l6.1,496.2l4.1,498.2h1004.6h1004.6v-1004.6v-1004.6h-510.5h-510.5l750.4-1189.1c414.1-654,756.5-1189.1,762.7-1189.1s344.4,535.1,752.4,1189.1l742.2,1189.1h-502.3h-500.3v1004.6v1004.6h1004.6h1004.6l4.1-498.2l6.1-496.2l1193.2,738.1C9362.8-242.6,9900,93.6,9900,99.7c0,6.2-237.8,159.9-526.9,340.3c-291.1,182.5-830.3,520.8-1199.4,752.4l-672.5,420.3v-510.5V591.8H6496.6H5492.1v1004.6V2601l498.2,4.1l496.2,6.1l-738.1,1193.2c-405.9,658.1-742.2,1195.3-748.3,1195.3S4846.2,4772.2,4672,4491.3z"/>
                            </g>
                        </g>
                    </svg> : null}
                    <img
                        className="gallery-image"
                        src={getApiHost() + item.path}
                        alt=""
                        data-id={item.id}
                    />
                </div>
                {
                    managed && <button onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        markAsDeleted(item);
                    }}>
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 7L4 4M4 4L1 7M4 4L7 1M4 4L1 1" stroke="white"
                                strokeWidth="2"/>
                        </svg>
                    </button>
                }
            </div>
            <span>
                {truncate(item.name)}
            </span>
            {mainLabel ? <p>{t("tabs.photo.main")}</p> : <p></p>}
        </div>
    });

    const SortableMedia = SortableContainer(({items}) => {
        return (
            <div className="gallery">
                {
                    items.map(
                        (item, index) => {
                            let sortItem;
                            if (item.mark_delete) {
                                sortItem = <SortableListItem 
                                    key={`item-${item.id}`} 
                                    index={index} 
                                    item={item} 
                                    disabled={true}
                                />
                            } else {
                                sortItem = <SortableListItem 
                                    key={`item-${item.id}`} 
                                    index={index} item={item}
                                    disabled={disableSort}
                                    distance={1}
                                />
                            }
                            return sortItem
                        })
                }
            </div>
        );
    });

    return <>
        <SortableMedia distance={20} axis={"xy"} items={model} onSortEnd={onSortEnd}/>
        {showDropZone ? <DropZone {...props}/> : null}
    </>
}

export default ShowMedia;

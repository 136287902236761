import React from "react";
import {useRecoilValue} from "recoil";
import {userState} from "../../../store";
import {FormattedMessage} from "react-intl";
import {useHistory} from "react-router-dom";
import {redirects} from "../../../constants/redirects";


function UserPanel() {
    let user = useRecoilValue(userState);
    let history = useHistory();

    let logoutClick = () => {
        localStorage.removeItem("token");
        window.location.reload();
        history.push({
            pathname: '/login',
            state: {referer: redirects.LOGOUT}
        })
    }
    if (user.loaded) {
        return <div className="user-panel">
            <div className="user-panel__avatar">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M21.2286 19.6541C22.4604 18.1732 23.3172 16.4177 23.7264 14.536C24.1356 12.6543 24.0852 10.7018 23.5794 8.84364C23.0737 6.98551 22.1275 5.27643 20.8209 3.86096C19.5142 2.4455 17.8857 1.36529 16.0729 0.711731C14.2601 0.0581692 12.3164 -0.149525 10.4063 0.10622C8.49614 0.361964 6.67576 1.07362 5.09911 2.18099C3.52247 3.28836 2.23595 4.75887 1.34839 6.46811C0.460819 8.17735 -0.00168342 10.075 4.60413e-06 12.0007C0.000652359 14.8 0.98792 17.5096 2.78857 19.6541L2.77141 19.6687C2.83146 19.7407 2.90008 19.8024 2.96183 19.8735C3.03903 19.9618 3.12223 20.0449 3.20201 20.1307C3.44218 20.3912 3.68921 20.6415 3.94825 20.8763C4.02717 20.9483 4.10865 21.0151 4.18842 21.0837C4.46291 21.3202 4.74511 21.5448 5.0376 21.7539C5.07534 21.7796 5.10965 21.813 5.1474 21.8396V21.8293C7.15635 23.2419 9.55292 24 12.0094 24C14.466 24 16.8625 23.2419 18.8715 21.8293V21.8396C18.9092 21.813 18.9427 21.7796 18.9813 21.7539C19.2729 21.5439 19.556 21.3202 19.8305 21.0837C19.9102 21.0151 19.9917 20.9474 20.0706 20.8763C20.3297 20.6406 20.5767 20.3912 20.8169 20.1307C20.8966 20.0449 20.979 19.9618 21.057 19.8735C21.1179 19.8024 21.1874 19.7407 21.2475 19.6678L21.2286 19.6541ZM12.0086 5.14424C12.772 5.14424 13.5183 5.37044 14.153 5.79422C14.7878 6.218 15.2825 6.82034 15.5747 7.52507C15.8668 8.2298 15.9432 9.00526 15.7943 9.75339C15.6454 10.5015 15.2778 11.1887 14.7379 11.7281C14.1981 12.2675 13.5104 12.6348 12.7616 12.7836C12.0129 12.9324 11.2368 12.856 10.5315 12.5641C9.82616 12.2722 9.22332 11.7779 8.79919 11.1437C8.37506 10.5094 8.14868 9.76377 8.14868 9.00098C8.14868 7.97811 8.55535 6.99713 9.27922 6.27386C10.0031 5.55058 10.9849 5.14424 12.0086 5.14424ZM5.15254 19.6541C5.16742 18.5288 5.62512 17.4546 6.42662 16.6638C7.22811 15.8731 8.30896 15.4294 9.43532 15.4289H14.5818C15.7082 15.4294 16.7891 15.8731 17.5905 16.6638C18.392 17.4546 18.8497 18.5288 18.8646 19.6541C16.9834 21.3479 14.5409 22.2853 12.0086 22.2853C9.47626 22.2853 7.03374 21.3479 5.15254 19.6541Z"
                        fill="#AB2F52"/>
                </svg>
            </div>
            <div className="user-panel__congrats">
                <p>
                    <FormattedMessage
                        id="side.greeting"
                        values={{username: user.model.fullname}}
                    />
                </p>
                <button onClick={logoutClick}>
                    <FormattedMessage
                        id="side.logout"
                    />
                </button>
            </div>
        </div>
    }

    return <></>;
}


export default UserPanel;
import React, { useContext, useEffect, useState } from 'react'
import CircleChart from "../../components/CircleChart/CircleChart";
import DefaultTable from "../../components/Table/DefaultTable";
import Tooltip from "../../components/Tooltip/Tooltip";
import { Link, useLocation } from "react-router-dom";
import InstitutionHeader from "../../components/PageHeaders/InstitutionHeader";
import SchoolService from "../../services/SchoolService";
import { LanguageContext } from "../../context/LanguageContext";
import { FormattedMessage } from "react-intl";
import { useRecoilState } from "recoil";
import { useIsAdmin } from '../../store';
import { useTranslations } from '../../context/LanguageContext';
// import { schoolModelState } from "../../store";
// import { setSchoolModel } from "../../helpers";


function FillName(item) {
    let { language } = useContext(LanguageContext);
    let location = useLocation();
    // let [school, setSchool] = useRecoilState(schoolModelState);

    let setNewModel = (model) => {
        // setSchoolModel(model);
        // setSchool(model);
    }


    if (item.translations) {
        return <Link className="table-link" to={{
            pathname: location.pathname + '/' + item.id + '/basic' || "#",
        }}
            onClick={() => setNewModel(item)}
        >
            {item.translations[language] ? item.translations[language].name : <FormattedMessage id="no.school.name"/>}
        </Link>
    }

    return <Link className="table-link" to={{
        pathname: '/' + item.id || "#",
    }}>
        {item.name}
    </Link>


}

function FillCell(item) {
    let languageContext = useContext(LanguageContext);
    let language = languageContext.language || '';
    return <CircleChart percentage={item.fill[language]} size="big" />
}

export function StatusCell(item) {
    let mapStatusToClass = {
        25: 'cell__label cell__label--warning',
        20: 'cell__label cell__label--declined',
        15: 'cell__label',
        40: 'cell__label cell__label--success',
        50: 'cell__placement cell__placement--dash'
    }

    let mapStatusToLabel = {
        15: <FormattedMessage id = "institutions.change.no"/>,
        25: <FormattedMessage id = "institutions.change.yes"/>
    }

    let className = mapStatusToClass[item.status] || '';
    let label = mapStatusToLabel[item.status] || "";

    return <div className={className}>
        {label}
    </div>;
}

function PublicationCell(item) {
    let mapPublicationToClass = {
        0: 'cell__publication cell__publication--success',
        1: 'cell__publication cell__publication--declined',
    }
    let mapPublishToLabel = {
        0: <FormattedMessage id="institutions.table.publication_status.true" />,
        1: <FormattedMessage id="institutions.table.publication_status.false" />
    }
    let className = mapPublicationToClass[item.unpublish] || '';
    let label = mapPublishToLabel[item.unpublish] || '';
    return <div className="cell__publication">
        <span className={className} />
        {label}
    </div>
}


export function PlacementCell(item) {
    let mapPlacementToClass = {
        10: 'cell__placement cell__placement--dash',
        20: 'cell__pay',
        30: 'cell__placement',
    }
    let mapSvgToItem = {
        0: null,
        1: <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 0C2.6916 0 0 2.6916 0 6C0 9.3084 2.6916 12 6 12C9.3084 12 12 9.3084 12 6C12 2.6916 9.3084 0 6 0ZM7.9758 8.8242L5.4 6.2484V2.4H6.6V5.7516L8.8242 7.9758L7.9758 8.8242Z"
                fill="#C4C4C4" />
        </svg>
    }

    let mapAction = {
        0: null,
        1: {}
    }

    let className = mapPlacementToClass[item.placement] || '';
    let svg = mapSvgToItem[item.placementSvg]
    let action = mapAction[item.placementAction]
    return <button
        className={className}>
        {svg}
        {item.placementText}
    </button>

}

function Institution(moderator) {
    const [model, setModel] = useState([]);
    let t = useTranslations();
    // let {language} = useContext(LanguageContext)

    let getSchoolsData = async () => {
        let schoolService = new SchoolService();
        let _model = await schoolService.getSchoolList();
        setModel(_model)
    }

    useEffect(() => {
        (async () => {
            await getSchoolsData();
        })();
    }, [])

    const columns = [
        {
            header: () => {
                return <>
                    <label className="flex-table__label">
                        <FormattedMessage id="institutions.table.name" />
                    </label>
                </>
            },
            value: FillName
        },
        {
            header: () => {
                return <>
                    <label className="flex-table__label">
                        <FormattedMessage id="institutions.table.filling" />
                    </label>
                </>
            },
            value: FillCell,
        },
        {
            header: () => {
                return <>
                    <label className="flex-table__label">
                        <FormattedMessage id="institutions.table.status" />
                    </label>
                    <Tooltip
                        text={t("institutions.tip.status")}
                    />
                </>
            },
            value: StatusCell
        },
        {
            header: () => {
                return <>
                    <label className="flex-table__label">
                        <FormattedMessage id="institutions.table.publication" />
                    </label>
                    <Tooltip
                        text={t("institutions.tip.publication")}/>
                </>
            },
            value: PublicationCell
        },
        {
            header: () => {
                return <>
                    <label className="flex-table__label flex-table__label--last">
                        <FormattedMessage id="institutions.table.end_аccommodation" />
                    </label>
                    <Tooltip />
                </>
            },
            value: PlacementCell
        },
    ];

    let EmptyNotify = () => {
        return <div className="empty">
            <FormattedMessage id="institutions.add_Institutions"/>
        </div>
    }

    return <div className="main-container">
        <InstitutionHeader />
        <div className="w-container">
            {model.length > 0 ?
                <DefaultTable
                    model={model || []}
                    columns={columns}
                /> : <EmptyNotify />
            }
        </div>
    </div>
}

export default Institution;
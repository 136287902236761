import React, {useEffect, useState} from "react";
import MediaService from "../../services/MediaService";
import {useRecoilValue} from "recoil";
import {userState} from "../../store";
import Gallery from "./Gallery";
import {arrayMove} from "react-sortable-hoc";
import {statuses} from "../../constants/statuses";
import {value} from "lodash/seq";
import {flags} from "../../constants/flags";


/**
 * Главный компонент в галерее
 */
export default function GalleryManager(props) {
    let school_id = props.school_id;
    let service = props.params.service;
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(true);
    let user = useRecoilValue(userState);

    let [model, setModel] = useState([]);
    let [initialModel, setInitialModel] = useState([]);
    let [status, setStatus] = useState(null);
    let [statusFromApi, setStatusFromApi] = useState();

    async function loadData() {
        let response = await service.getData(school_id);
        setModel(response.data);
        setInitialModel(response.initialData)
        setStatusFromApi(response.initialRenderStatus)
        if (status === null) {
            setStatus(response.status)
        }
    }

    let withDraw = async () => {
        await service.withdrawRequests(school_id);
        await loadData();

        changeStatus(statuses.STATUS_INITIAL)
    }

    let reject = async () => {
        let flag = flags.REJECT;
        await service.withdrawRequests(school_id, {flag: flag});
        await loadData();
        changeStatus(statuses.STATUS_INITIAL)
    }

    let apply = async () => {
        await service.applyAllUrs(school_id);
        await loadData();
        changeStatus(statuses.STATUS_INITIAL)
    }

    useEffect(()=>{(async () => {
        await loadData()
    })();
    }, []);

    let changeStatus = (value) => {
        setStatus(value)
    }

    let buttonActions = {
        withDraw,
        apply,
        userEdit: () => {
            changeStatus(statuses.STATUS_USER_EDIT)
        },
        managerEdit: () => {
            changeStatus(statuses.STATUS_MANAGER_EDIT)
        },
        cancel: () => {
            changeStatus(statusFromApi)
        },
        reject,
        save: () => {
            changeStatus(statusFromApi)
        }
    }

    const onSortEnd = async ({oldIndex, newIndex,}) => {
        setModel(arrayMove(model, oldIndex, newIndex));
        let newOrderNum = (arrayMove(model, oldIndex, newIndex));
        if (newIndex === 0) {
            await createChangeMainPhotoRequest(newOrderNum[0]);
        }
        await saveAndSendMediaOrder(newOrderNum)
        await loadData();
    };

    async function saveAndSendMediaOrder(newOrderMediaModel) {
        let newOrder = [];
        newOrderMediaModel.forEach(function (value, i) {
            let data = {
                id: value.id,
                index: i,
            };
            newOrder.push(data);
        });
        await service.createNewOrderRequest(school_id, newOrder)
    }

    async function createChangeMainPhotoRequest(item) {
        await service.changeMainPhotoRequest(school_id, item.id);
    }

    // let getAllMarkDeleted = () => {
    //     let allMarkDeleteMedias = [];
    //     model.forEach(function (media, index) {
    //         if (media.mark_delete) {
    //             allMarkDeleteMedias.push(media.id);
    //         }
    //     });
    //     return allMarkDeleteMedias;
    // }

    //Помечяем к удалению и сразу меняем порядок, чтобы поставилась в конец
    let markAsDeleted = async (item) => {
        await service.markDeleted(school_id, item.id);
        await loadData()
    }

    let withFile = async (file) => {
        if (file !== undefined) {
            let media = new FormData();
            media.append('files', file);
            await service.uploadFiles(school_id, media);
            await loadData();
        }
    }

    let withUrl = async (file_path) => {
        if (file_path !== undefined) {
            await service.uploadFilesByUrl(school_id, {
                path: file_path,
            })
            await loadData();
        }
    }

    return <Gallery
        initialValue={initialModel}
        value={model}
        status={status}
        title={props.params.title}
        user={user}
        withUrl={withUrl}
        withFile={withFile}
        errors={errors}
        markAsDeleted={markAsDeleted}
        onSortEnd={onSortEnd}
        buttonActions={buttonActions}
        type={props.params.type}
        loading={loading}
    />
}
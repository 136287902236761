import api from "../api";

class SchoolProgramService {

    upgradeField(model) {
        return {...model, initialRenderStatus: model['status']};
    }

    getBaseUrl(school_id) {
        return `/api/school/${school_id}/ep/`;
    }

    async getSchoolProgramsList(school_id) {
        return await api.get(this.getBaseUrl(school_id) + 'list/')
    }

    async loadProgramData(school_id, ep_id) {
        let data = await api.get(this.getBaseUrl(school_id) + `${ep_id}/`)
        for (let i in data) {
            if (data.hasOwnProperty(i)) {
                data[i] = this.upgradeField(data[i]);
            }
        }
        return data;
    }

    async createProgram(school_id, model) {
        return await api.post(`/api/school/${school_id}/ep/create/`, model);
    }

    async loadDurationTypes() {
        return await api.get('/api/school/duration-types/')
    }

    async loadCurrencyTypes() {
        return await api.get('/api/dict/currency/')
    }


    async loadProgramCategoryList() {
        return await api.get('/api/school/ep/category-list/');
    }

    async updateEpUr(school_id, ep_id, model) {
        let fieldData = await api.post(this.getBaseUrl(school_id) + `${ep_id}/update/`, model);
        return this.upgradeField(fieldData);
    }

    async manageEpUr(school_id, ep_id, model) {
        return  await api.post(this.getBaseUrl(school_id) + `${ep_id}/manage/`, model);
        // return this.upgradeField(fieldData);

    }
}

export default SchoolProgramService;
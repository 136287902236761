import React, { useEffect, useState, useContext, useRef } from 'react';
import FormFooter from "../../Forms/Footer/FormFooter";
import SchoolService from "../../../services/SchoolService";
import SimpleField from "../../AccountFields/SimpleField";
import SelectField from "../../AccountFields/SelectField";
import CkeditorField from "../../AccountFields/CkeditorField";
import RenderField from "../../AccountFields/RenderField";
import UserRequestService from "../../../services/UserRequestService";
import { FormattedMessage } from "react-intl";
import AddressField from "../../AccountFields/AddressField";
import CustomModal from "../../../components/Modal/CustomModal";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { schoolModelState } from "../../../store";
import { LanguageContext, useTranslations } from "../../../context/LanguageContext";
// import AddressSelect from '../../Map/AdressSelect';

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function useStateCf() {
    let [model, setModel] = useState(null);

    let cf = (field, value, innerField = 'value') => {
        let newModel = { ...model };
        newModel[field][innerField] = value;

        setModel(newModel);
    }
    return [model, setModel, cf];
}


function useSchool(id) {

    let [loaded, setLoaded] = useState(false);
    let [model, setModel, changeField] = useStateCf({});

    useEffect(() => {
        (async () => {
            let schoolService = new SchoolService();
            let _model = await schoolService.getSchool(id);
            setModel(_model);
            setLoaded(true);
        })();
    }, []);

    return [loaded, model, setModel, changeField];
}

function Basic(props) {
    let { school_id } = props;
    let [id, setId] = useState(school_id);
    let [isLoading, setIsLoading] = useState(false);
    let [loaded, model, setModel, changeField] = useSchool(id);
    const history = useHistory();
    let languageContext = useContext(LanguageContext);
    let language = languageContext.language;

    // Загрузить школу из глобального стейта
    const school = useRecoilValue(schoolModelState);

    // Показывать модалку удаления школы
    const [showDelModal, setShowDelModal] = useState(false);

    const [loading, setLoading] = useState(false);
    let t = useTranslations();

    const handleDeleteSchool = async () => {
        // setErrors({});
        setLoading(true);

        const schoolService = new SchoolService();
        const response = await schoolService.removeSchool(school_id);

        setLoading(false);
        if (response.success) {
            setShowDelModal(false);
            history.push("/institutions");
        } else {
            console.log(response);
            console.log("Не удалось удалить школу");
        }
    }

    if (!loaded) {
        return <div>Loading...</div>
    }

    async function updateSchool(fieldName, lang) {
        let value = model[fieldName].value;
        let userRequestService = new UserRequestService();

        let modelObj = {
            field: fieldName,
            school_id: id,
            language: lang,
            value
        }

        setIsLoading(true)

        let response = await userRequestService.updateUr(modelObj);
        let newModel = { ...model };
        newModel[fieldName] = response;

        

        setModel(newModel);
    }

    async function handleUserRequest(fieldName, flag, lang) {
        let value = model[fieldName].value;
        let userRequestService = new UserRequestService();
        let modelObj = {
            field: fieldName,
            school_id: id,
            value
        }
        if (typeof lang !== 'undefined') {
            modelObj.language = lang;
        }
        if (typeof flag !== 'undefined') {
            modelObj.flag = flag;
        }

        setIsLoading(true);

        let response = await userRequestService.handleUr(modelObj);
        let newModel = { ...model };
        newModel[fieldName] = response;

        await sleep(1000);

        setModel(newModel);
        setIsLoading(false);
    }

    let fields = [
        {
            component: SimpleField,
            name: 'translations-{lang}-name',
            title: <FormattedMessage id="school.field.name" />,
            required: true,

        },
        {
            component: CkeditorField,
            tip: t("institutions.description_school"),
            name: 'translations-{lang}-description',
            title: <FormattedMessage id="school.field.description" />,
            required: true,
        },
        {
            component: SimpleField,
            // ТУТ ДОЛЖЕН БЫТЬ ДРУГОЙ НЕЙМ
            name: 'translations-{lang}-partnership_info',
            title: <FormattedMessage id="school.field.partner" />,
            tip: t("school.field.partner"),
        },
        {
            component: SelectField,
            title: <FormattedMessage id="school.field.subjects" />,
            // ТУТ ДОЛЖЕН БЫТЬ ДРУГОЙ НЕЙМ (я фолтку всех неймов приходящих кидал, а какой надо ставить не понимаю)
            name: 'subjects',
            tip: t("institutions.subjects_school"),
            required: true,
        },
        {
            component: SimpleField,
            name: 'translations-{lang}-url',
            title: <FormattedMessage id="school.field.site" />,
            tip: t("institutions.web-site_school"),
        },
        // здесь должна быть карта
        {
            component: AddressField,
            name: 'translations-{lang}-address',
            title: <FormattedMessage id="school.field.address" />,
            required: true,
            tip: t("institutions.address_school")
        }
    ];

    return <>
        {
            fields.map(
                field => {
                    return <RenderField key={field.name}
                        model={model}
                        field={field}
                        save={updateSchool}
                        handleUserRequest={handleUserRequest}
                        isLoading={isLoading}
                        onChange={changeField} />

                })}

        <div className='tabs__button' onClick={() => setShowDelModal(true)}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13 10V15M3 5H19L17.42 19.22C17.3658 19.7094 17.1331 20.1616 16.7663 20.49C16.3994 20.8184 15.9244 21 15.432 21H6.568C6.07564 21 5.60056 20.8184 5.23375 20.49C4.86693 20.1616 4.63416 19.7094 4.58 19.22L3 5ZM6.345 2.147C6.50675 1.80397 6.76271 1.514 7.083 1.31091C7.4033 1.10782 7.77474 0.999996 8.154 1H13.846C14.2254 0.999806 14.5971 1.10755 14.9176 1.31064C15.2381 1.51374 15.4942 1.80381 15.656 2.147L17 5H5L6.345 2.147V2.147ZM1 5H21H1ZM9 10V15V10Z"
                    stroke="#AB2F52" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

            <span className="tabs__button__text"> <FormattedMessage id="institutions.delete_school" /> </span>
        </div>

        {
            school ?
                <CustomModal
                    close={() => setShowDelModal(false)}
                    isOpen={showDelModal}
                    className={"tabs__modal"}
                    loading={loading}
                >
                    <h2><FormattedMessage id="institutions.school_removal" /></h2>
                    <div>
                        <p><FormattedMessage id="institutions.delete_school.ready" /></p>
                        <h3>{school.translations && school.translations[language] && school.translations[language].name}</h3>
                    </div>

                    <div className={"tabs__modal__buttons"}>
                        <div className="program-button tabs__modal__button">
                            <a onClick={handleDeleteSchool}><FormattedMessage id="general.yes" /></a>
                        </div>
                        <div className="program-button tabs__modal__button">
                            <div onClick={() => setShowDelModal(false)}><FormattedMessage id="general.no" /></div>
                        </div>
                    </div>
                </CustomModal>
                :
                null
        }
    </>
}

export default Basic;
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import {RecoilExternalStatePortal} from './RecoilExternalStatePortal';
import {
    RecoilRoot,
    // atom,
    // selector,
    // useRecoilState,
    // useRecoilValue,
    // MutableSnapshot
} from 'recoil';

/*  recoilRoot чтобы убрать варнинг надо это прописать, но оно не хочет
*   initializeState={({ set }: MutableSnapshot): void =>
        set(recoilState, initialRecoilStateValue)
    }
*/

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot
                /* help */
        >
            <Suspense fallback={<div>loading...</div>}>
                <App/>
                <RecoilExternalStatePortal/>
            </Suspense>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

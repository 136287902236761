import React, {useEffect, useState} from "react";
import {statuses} from "../../constants/statuses";
import DiffFieldRenderer from "./DiffFieldRenderer";
import MultiLevelSelect from "../Fields/MultiLevelSelect";
import SchoolProgramService from "../../services/SchoolProgramService";
import WrapField from "./WrapField";
import { FormattedMessage } from "react-intl";
import { useIsAdmin } from "../../store";
import { useTranslations } from "../../context/LanguageContext";

function ComplexSelectField(props) {
    let {
        status,
        value,
        onChange,
        difference,
        initialValue,
        author,
        user,
        language
    } = props;

    let [categories, setCategories] = useState([]);


    useEffect(()=>{(async () => {
        let spService = new SchoolProgramService();
        let response = await spService.loadProgramCategoryList();
        setCategories(response);
    })();
    }, []);

    let localOnChange = (newValue) => {
        onChange('category_id', newValue.id, 'value');
    }

    let inputPart = '?';
    let subtitle = '';
    let inputTitle = '';
    let className = "diff-text diff-text--user"
    let t = useTranslations();

    let isAdmin = useIsAdmin();

    let render = {
        [statuses.STATUS_MANAGER_MODERATION]: () => {
            if (isAdmin) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            } else {
                subtitle = <FormattedMessage id = "general.setToModerator"/>
            }

            inputPart = <DiffFieldRenderer
                className={className}
                direction={'horizontal'}
                initialValue={initialValue}
                value={value  || ""}
                difference={difference}
                language={language}
                options={categories}
                type="programs"
            />
        },
        [statuses.STATUS_USER_MODERATION]: () => {
            if (isAdmin) {
                subtitle = t("general.setToUser")
                className = "diff-text diff-text--admin"
            } else {
                subtitle = t("general.ModeratorSuggestsChanges")
                className = "diff-text diff-text--admin"
            }

            inputPart = <DiffFieldRenderer
                className={className}
                direction={'horizontal'}
                initialValue={initialValue}
                difference={difference}/>
        },
        [statuses.STATUS_USER_EDIT]: () => {
            subtitle = <FormattedMessage id = "general.setToModerator"/>
            inputTitle = <FormattedMessage id ="general.change"/>
            inputPart = <MultiLevelSelect
                value={parseInt(value)  || ""}
                options={categories}
                onChange={localOnChange}
                language={language}
                isDisabled={false}
            />
        },
        [statuses.STATUS_MANAGER_EDIT]: () => {
            subtitle = t("general.setToUser")
            if (author.id !== user.model.id) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            }
            inputTitle = <FormattedMessage id ="general.change"/>
            inputPart = <MultiLevelSelect
                value={parseInt(value)  || ""}
                options={categories}
                onChange={localOnChange}
                language={language}
                isDisabled={false}
            />
        },
        [statuses.STATUS_EDIT]: () => {
            inputPart = <MultiLevelSelect
                value={value || ""}
                options={categories}
                onChange={localOnChange}
                language={language}
                isDisabled={false}
            />
        },
        [statuses.STATUS_INITIAL]: () => {
            inputPart = <MultiLevelSelect
                value={value}
                options={categories}
                onChange={onChange}
                language={language}
                isDisabled={true}
            />
        }
    }

    if (render[status]) {
        render[status]();
    } else {
        inputPart = 'Status Not Handled'
    }

    return <WrapField {...props}
                      inputPart={inputPart}
                      subtitle={subtitle}
                      inputTitle={inputTitle}
                      status={status}
                      langSwitch={true}
    />


}

export default ComplexSelectField;
import api from "../api";


class SchoolService {
    // Выводит школы
    async getSchoolList() {
        return await api.get(`/api/school/list/`)
    }

    upgradeField(field) {
        return { ...field, initialRenderStatus: field['status'] };
    }

    async getSchool(id) {
        let newSchool = await api.get(`/api/school/?id=${id}`);

        for (let i in newSchool) {
            if (newSchool.hasOwnProperty(i)) {
                newSchool[i] = this.upgradeField(newSchool[i]);
            }
        }

        return newSchool;
    }

    // Возвращает школу для отображения
    async get(id) {
        return await api.get(`/api/school/get/?id=${id}`);
    }

    async searchSubject(name, language) {
        return await api.get(`/api/school/search-subject/`, {name, language});
    }

    async createNewSchool(model) {
        return await api.post('/api/school/create/', model);
    }

    async removeSchool(id) {
        return await api.post(`/api/school/${id}/delete/`);
    }
}

export default SchoolService;
import React, { useEffect, useState } from 'react';
import { statuses } from "../../constants/statuses";
import DiffFieldRenderer from "./DiffFieldRenderer";
import SchoolProgramService from "../../services/SchoolProgramService";
import ComplexRadio from "../Fields/ComplexRadio";
import WrapField from "./WrapField";
import { FormattedMessage } from "react-intl";
import { useIsAdmin } from '../../store';
import { useTranslations } from '../../context/LanguageContext';

export function uniqueName() {
    return '_' + Math.random().toString(36).substr(2, 9);
}

export default function ComplexField(props) {
    let {
        status,
        value,
        onChange, // field, value
        difference,
        fieldName,
        initialValue,
        author,
        user,
        additionalProperty,
        language,
    } = props;

    let [types, setTypes] = useState([]);

    // это переделать на уровень выше 
    useEffect(() => {
        (async () => {
            let spService = new SchoolProgramService();
            if (additionalProperty.fieldName === "duration_type_id") {
                let durationTypes = await spService.loadDurationTypes();
                let makeOptions = (options) => {
                    let arr = [];
                    for (let id in options) {
                        arr.push({ id: options[id].id, name: options[id].name || options[id]["translations"][language].name })
                    }
                    return arr;
                }
                setTypes(makeOptions(durationTypes));
            } else {
                let currencyTypes = await spService.loadCurrencyTypes();
                setTypes(currencyTypes);
            }
        })();
    }, [language]);

    // Хелпер для изменения нескольких полей в исходной модели
    let changeFields = (obj) => {

        Object.entries(obj).map(([propName, value]) => {
            onChange(propName, value);
        })
    };

    /**
     * Если дочерний компонент принимает значение представляющее собой массив из двух, Например
     * <ComplexRadio value={[22, 31]} onChange={newArr => changeFields(...)} />
     * @param {*} newValueArr 
     */
    let changeTwoModelFields = (newValueArr) => {

        changeFields({ [fieldName]: newValueArr[0], [additionalProperty.fieldName]: newValueArr[1] });
    }

    let initialValueOfTwo = [initialValue, additionalProperty.initial_value];
    let valueOfTwo = [value, additionalProperty.value];

    let input = "";
    let subtitle = "";
    let inputTitle = "";
    let className = "diff-text diff-text--user"
    let t = useTranslations();

    let fieldStatus = status < additionalProperty.status ? additionalProperty.status : status

    let isAdmin = useIsAdmin();

    let render = {
        [statuses.STATUS_MANAGER_MODERATION]: () => { // status: 30
            if (isAdmin) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            } else {
                subtitle = <FormattedMessage id="general.setToModerator" />
            }

            input = <DiffFieldRenderer {...props}
                className={className}
                direction={'vertical'}
                initialValue={initialValue}
                difference={difference}
                options={types}
                additionalProperty={additionalProperty}
                type={"complex"}
            />
        },
        [statuses.STATUS_USER_MODERATION]: () => { // status: 20
            if (isAdmin) {
                subtitle = t("general.setToUser")
                className = "diff-text diff-text--admin"
            } else {
                subtitle = t("general.ModeratorSuggestsChanges")
                className = "diff-text diff-text--admin"
            }

            input = <DiffFieldRenderer {...props}
                className={className}
                direction={'vertical'}
                initialValue={initialValue}
                difference={difference}
                options={types}
                additionalProperty={additionalProperty}
                type={"complex"}
            />
        },
        [statuses.STATUS_USER_EDIT]: () => { // status: 25
            subtitle = <FormattedMessage id="general.setToModerator" />
            inputTitle = <FormattedMessage id ="general.change"/>
            input = <ComplexRadio {...props}
                value={valueOfTwo}
                onChange={changeTwoModelFields}
                options={types}
                disabled={false}
            />
        },
        [statuses.STATUS_MANAGER_EDIT]: () => { // status: 35
            subtitle = t("general.setToUser")
            if (author.id !== user.model.id) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            }
            inputTitle = <FormattedMessage id ="general.change"/>
            input = <ComplexRadio {...props}
                value={value}
                fieldName={fieldName}
                additionalProperty={additionalProperty}
                onChange={changeTwoModelFields}
                options={types}
            />
        },
        [statuses.STATUS_EDIT]: () => { // status: 15
            input = <div>

                <ComplexRadio {...props}
                    value={valueOfTwo}
                    onChange={changeTwoModelFields}
                    options={types}
                />
            </div>
        },
        [statuses.STATUS_INITIAL]: () => { // status: 10
            input = <ComplexRadio {...props}
                value={valueOfTwo}
                options={types}
                disabled={true}
            />
        }
    }

    if (render[fieldStatus]) {
        render[fieldStatus]();
    } else {
        input = 'Status Not Handled'
    }

    return <div>
        <WrapField {...props}
            inputPart={input}
            subtitle={subtitle}
            inputTitle={inputTitle}
            status={fieldStatus}
        />
    </div>

}
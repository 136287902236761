import React from "react";
import BreadCrumb from "../../BreadCrumb/BreadCrumb";
import PostManager from "./PostManager";
// import {categories} from "../../../constants/categories";
import {useHistory} from "react-router-dom";
import { useTranslations } from "../../../context/LanguageContext";


function SinglePost(props) {
    console.log(props)
    let t = useTranslations();
    let history = useHistory();
    return <>
        <BreadCrumb label={t("tabs.back."+props.componentProps.category)} onClick={() => history.goBack()}/>
        <PostManager {...props} category={props.componentProps.category} />
    </>
}

export default SinglePost;
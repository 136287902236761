import React from "react";
import { Link } from "react-router-dom";

function Logo() {
    return <div className="app-logo"><Link to="/institutions">
        <svg width="121" height="57" viewBox="0 0 121 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <g>
                    <g>
                        <path
                            d="M121 32.0313C121 32.7808 120.853 33.5232 120.568 34.2158C120.282 34.9085 119.863 35.5379 119.335 36.0682C118.807 36.5984 118.179 37.0192 117.489 37.3063C116.799 37.5935 116.059 37.7414 115.311 37.7418L5.68858 44.6643C4.17964 44.6637 2.73274 44.062 1.66599 42.9916C0.599251 41.9212 -1.44627e-07 40.4698 0 38.9563V12.6306C-1.44627e-07 11.1171 0.599251 9.66567 1.66599 8.59529C2.73274 7.5249 4.17964 6.92323 5.68858 6.92257L115.309 0C116.817 -1.4512e-07 118.264 0.60077 119.33 1.67022C120.397 2.73967 120.997 4.19024 120.998 5.703V32.0313H121Z"
                            fill="#AB2F52"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            d="M8.76831 49.1702L14.2202 48.8254V50.4273L10.5723 50.6597V52.1417L13.6894 51.9443V53.5462L10.5723 53.7436V55.2881L14.2202 55.0582V56.6601L8.76831 57.005V49.1702Z"
                            fill="#AB2F52"/>
                    </g>
                    <g>
                        <path
                            d="M26.7659 53.3613C26.776 53.7335 26.7011 54.1031 26.5469 54.4418C26.3928 54.7806 26.1634 55.0794 25.8763 55.3156C25.6054 55.5353 25.2974 55.7046 24.9669 55.8154C24.6356 55.9347 24.2891 56.0062 23.9378 56.0278L20.9727 56.2153V48.393L23.9378 48.2056C24.3108 48.1784 24.6859 48.2045 25.0516 48.2831C25.3757 48.3514 25.682 48.4867 25.9511 48.6804C26.2088 48.8711 26.4145 49.1238 26.5491 49.4152C26.7022 49.7609 26.7763 50.1366 26.7659 50.5148V53.3563V53.3613ZM24.9619 50.9746C24.9683 50.7794 24.93 50.5853 24.8498 50.4073C24.7816 50.2685 24.6827 50.1471 24.5607 50.0524C24.4399 49.9643 24.3026 49.9014 24.1571 49.8675C24.0119 49.833 23.8625 49.8195 23.7135 49.8275L22.7742 49.8875V54.5134L23.7135 54.4559C23.865 54.4446 24.0142 54.4127 24.1571 54.3609C24.3045 54.3074 24.4412 54.2279 24.5607 54.126C24.6835 54.0141 24.7819 53.878 24.8498 53.7261C24.9286 53.5407 24.9668 53.3404 24.9619 53.1389V50.9796V50.9746Z"
                            fill="#AB2F52"/>
                    </g>
                    <g>
                        <path
                            d="M39.4389 53.6962C39.2675 54.0212 39.0328 54.3084 38.7487 54.5409C38.4567 54.7787 38.1252 54.9631 37.7695 55.0857C37.4075 55.2152 37.0293 55.2935 36.6457 55.3181C36.2683 55.3419 35.8894 55.3142 35.5195 55.2356C35.1707 55.1632 34.8385 55.0259 34.5402 54.8308C34.2353 54.6331 33.9872 54.3589 33.8207 54.0354C33.6542 53.7118 33.5749 53.3502 33.5909 52.9864V47.5983L35.3924 47.4833V52.5316C35.3859 52.726 35.4242 52.9193 35.5045 53.0964C35.5726 53.2381 35.6744 53.3609 35.801 53.4538C35.9206 53.5415 36.056 53.6051 36.1997 53.6412C36.3462 53.6778 36.4975 53.6913 36.6482 53.6812C36.7991 53.6714 36.9477 53.6394 37.0892 53.5862C37.2375 53.5333 37.3745 53.4528 37.4929 53.3488C37.6159 53.24 37.7144 53.1063 37.7819 52.9564C37.8632 52.7734 37.9023 52.5744 37.8966 52.3741V47.3259L39.6981 47.2134V52.5566C39.7068 52.9521 39.6178 53.3436 39.4389 53.6962Z"
                            fill="#AB2F52"/>
                    </g>
                    <g>
                        <path
                            d="M51.2794 48.6029C51.0516 48.4125 50.7816 48.2797 50.492 48.2156C50.2366 48.1614 49.9752 48.1413 49.7146 48.1556C49.5596 48.1664 49.4069 48.1992 49.2611 48.2531C49.1118 48.3058 48.9733 48.3854 48.8524 48.488C48.7266 48.5974 48.6247 48.7319 48.5534 48.8829C48.4687 49.0649 48.4278 49.2643 48.4338 49.4651V51.6819C48.4273 51.8779 48.4683 52.0726 48.5534 52.2492C48.6248 52.3903 48.7291 52.512 48.8574 52.604C48.9829 52.6905 49.1231 52.7532 49.2711 52.789C49.4196 52.8235 49.5724 52.8361 49.7245 52.8265C49.9796 52.8094 50.2311 52.7573 50.4721 52.6715C50.7717 52.5559 51.0478 52.3865 51.2868 52.1717L52.1365 53.5287C51.8096 53.8196 51.4309 54.046 51.0202 54.196C50.5929 54.3551 50.1446 54.4503 49.6897 54.4784C49.3231 54.5005 48.9553 54.4736 48.5958 54.3984C48.2422 54.3308 47.9042 54.1977 47.5991 54.0061C47.3086 53.821 47.0654 53.5702 46.889 53.2738C46.7 52.9381 46.606 52.557 46.6174 52.1717V49.2277C46.6083 48.8321 46.7001 48.4407 46.884 48.0906C47.0572 47.7696 47.2947 47.4879 47.5817 47.2634C47.8722 47.0346 48.199 46.8563 48.5485 46.7361C48.9013 46.6121 49.2693 46.5364 49.6423 46.5112C50.0978 46.4838 50.5548 46.52 51.0003 46.6186C51.4178 46.7019 51.8102 46.8816 52.1465 47.1435L51.2794 48.6029Z"
                            fill="#AB2F52"/>
                    </g>
                    <g>
                        <path
                            d="M63.0205 52.0217L60.5038 52.1817L60.0404 53.7561L58.1018 53.8786L60.8576 45.8814L62.7165 45.7639L65.425 53.4238L63.4989 53.5462L63.0205 52.0217ZM60.9598 50.6747L62.5694 50.5723L62.41 50.0499L61.7871 47.9282H61.7397L61.1168 50.1274L60.9598 50.6747Z"
                            fill="#AB2F52"/>
                    </g>
                    <g>
                        <path
                            d="M74.3078 46.6436V52.849L72.5063 52.9614V46.7586L70.5129 46.8836V45.2591L76.2788 44.8942V46.5187L74.3078 46.6436Z"
                            fill="#AB2F52"/>
                    </g>
                    <g>
                        <path d="M84.6658 44.3644V52.1967L82.8643 52.3091V44.4794L84.6658 44.3644Z" fill="#AB2F52"/>
                    </g>
                    <g>
                        <path
                            d="M97.8695 48.8928C97.8772 49.2871 97.7918 49.6776 97.6203 50.0324C97.4597 50.356 97.232 50.6415 96.9525 50.8697C96.663 51.1058 96.334 51.2886 95.9808 51.4095C95.6042 51.5392 95.212 51.6174 94.8146 51.6419C94.4254 51.6687 94.0344 51.6392 93.6535 51.5544C93.3061 51.4798 92.9762 51.3389 92.6817 51.1396C92.4001 50.9481 92.1692 50.6908 92.009 50.3898C91.8341 50.0469 91.7484 49.6652 91.7598 49.2802V46.3262C91.7508 45.9316 91.8444 45.5415 92.0314 45.1941C92.2084 44.8756 92.4493 44.5973 92.739 44.3769C93.0343 44.1508 93.3653 43.9759 93.7183 43.8596C94.0736 43.7397 94.443 43.6666 94.8171 43.6422C95.1866 43.6201 95.5574 43.6504 95.9185 43.7322C96.2723 43.8071 96.6098 43.9451 96.9151 44.1395C97.2232 44.3375 97.4744 44.6128 97.644 44.938C97.8135 45.2632 97.8955 45.6271 97.8819 45.9939V48.8928H97.8695ZM96.068 46.4587C96.0742 46.2635 96.0359 46.0694 95.9558 45.8914C95.8882 45.7523 95.7892 45.6308 95.6668 45.5365C95.5471 45.448 95.4106 45.385 95.2656 45.3516C95.1206 45.3161 94.971 45.3034 94.8221 45.3141C94.6695 45.3227 94.5191 45.3547 94.3761 45.4091C94.229 45.4633 94.0924 45.5428 93.9724 45.644C93.8471 45.7533 93.7461 45.8879 93.6759 46.0388C93.5966 46.2224 93.5583 46.4212 93.5638 46.6211V48.8379C93.5578 49.033 93.5961 49.227 93.6759 49.4052C93.7449 49.5456 93.8466 49.6674 93.9724 49.76C94.0938 49.8473 94.2309 49.9101 94.3761 49.945C94.5218 49.9815 94.6721 49.9959 94.8221 49.9875C94.9738 49.9765 95.1232 49.9437 95.2656 49.89C95.4127 49.8362 95.5487 49.7557 95.6668 49.6526C95.7893 49.5434 95.8878 49.4098 95.9558 49.2602C96.0352 49.0767 96.0735 48.8779 96.068 48.6779V46.4612V46.4587Z"
                            fill="#AB2F52"/>
                    </g>
                    <g>
                        <path
                            d="M106.551 46.3088V50.8072L104.747 50.9196V43.0949L106.366 42.9924L107.669 44.8093L109.381 47.3084H109.416V42.81L111.218 42.695V50.5273L109.605 50.6297L107.993 48.3455L106.608 46.3188H106.551V46.3088Z"
                            fill="#AB2F52"/>
                    </g>
                </g>
                <path
                    d="M14.8481 31.7114C16.0775 32.3708 17.466 32.6729 18.8573 32.5836C21.1447 32.4286 22.4827 31.1116 22.4827 29.3347C22.4827 27.6928 21.5583 26.8181 19.2211 26.0659C16.3955 25.2461 14.6488 23.8816 14.6488 21.4025C14.6488 18.666 16.8913 16.4817 20.2552 16.2518C22.0292 16.1318 23.3125 16.4592 24.0849 16.8491L23.467 18.7409C22.4418 18.2552 21.3084 18.0451 20.1779 18.1311C17.8133 18.2936 16.9138 19.7881 16.9113 20.9851C16.9113 22.6271 17.9628 23.3618 20.3523 24.1365C23.2826 25.0837 24.7726 26.4132 24.7701 28.9948C24.7701 31.7039 22.7768 34.1855 18.7003 34.4654C17.0309 34.5804 15.2119 34.2155 14.28 33.6482L14.8481 31.7114Z"
                    fill="white"/>
                <path
                    d="M43.5104 24.8013C43.3834 22.3597 43.2314 19.4282 43.2613 17.2365H43.184C42.591 19.3357 41.8709 21.5724 40.9938 24.0566L37.929 32.786L36.2322 32.901L33.4365 24.7288C32.6167 22.3097 31.924 20.0904 31.4431 18.0387H31.3908C31.3384 20.2304 31.2064 23.1844 31.0519 25.8259L30.5785 33.3983L28.4456 33.5432L29.6665 15.8994L32.5195 15.7045L35.4697 23.9966C36.1873 26.1108 36.7778 27.9952 37.2139 29.792H37.2911C37.7297 27.9902 38.3476 26.0209 39.12 23.7517L42.2098 15.0472L45.0628 14.8523L46.1317 32.3461L43.9465 32.4961L43.5104 24.8013Z"
                    fill="white"/>
                <path
                    d="M53.2005 26.3283L51.399 31.9763L49.0867 32.1337L54.9846 14.17L57.6831 13.9851L63.581 31.1491L61.1914 31.3115L59.345 25.9009L53.2005 26.3283ZM58.8816 24.169L57.1872 19.2308C56.8035 18.1111 56.5469 17.0865 56.2902 16.0869H56.2379C55.9887 17.1465 55.6972 18.2336 55.3608 19.3357L53.6615 24.5339L58.8816 24.169Z"
                    fill="white"/>
                <path
                    d="M67.219 13.5427C68.6301 13.2119 70.0661 12.9987 71.5122 12.9055C73.7224 12.7555 75.342 13.1554 76.3686 14.0326C77.3179 14.8023 77.8835 16.0319 77.8835 17.6038C77.8835 19.1758 77.4176 20.4753 76.543 21.4475C75.3594 22.8045 73.4308 23.5892 71.2481 23.7367C70.6493 23.798 70.0453 23.7871 69.4491 23.7042V30.7392L67.2065 30.8916L67.219 13.5427ZM69.4615 21.8573C70.0738 21.9543 70.6959 21.9727 71.3129 21.9123C74.0114 21.7274 75.6584 20.2879 75.6584 17.8637C75.6584 15.5445 74.0413 14.5349 71.5745 14.7023C70.8618 14.732 70.1548 14.8418 69.4665 15.0297L69.4615 21.8573Z"
                    fill="white"/>
                <path
                    d="M81.6559 27.1505C82.8853 27.8098 84.2737 28.1118 85.665 28.0227C87.9524 27.8677 89.2905 26.5507 89.2905 24.7738C89.2905 23.1319 88.366 22.2572 86.0288 21.505C83.2032 20.6827 81.454 19.3157 81.4565 16.8416C81.4565 14.1051 83.6991 11.9208 87.0629 11.6909C88.837 11.5709 90.1202 11.8983 90.8926 12.2882L90.2697 14.1825C89.2445 13.6969 88.1112 13.4868 86.9806 13.5727C84.616 13.7352 83.7165 15.2297 83.714 16.4267C83.714 18.0687 84.7655 18.8034 87.1551 19.5781C90.0853 20.5253 91.5754 21.8548 91.5729 24.4364C91.5729 27.1455 89.5795 29.6271 85.5031 29.907C83.8311 30.022 82.0147 29.6571 81.0828 29.0898L81.6559 27.1505Z"
                    fill="white"/>
                <path
                    d="M104.689 20.1004L97.9616 20.5603V26.8931L105.467 26.3807V28.2826L95.7266 28.9473V11.386L105.083 10.7462V12.6556L97.9641 13.1429V18.6859L104.692 18.2261V20.1029L104.689 20.1004Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="121" height="57" fill="white"/>
                </clipPath>
            </defs>
        </svg></Link>
    </div>
    


}

export default Logo;
import { post } from "http";
import api from "../api";


class UserService {
    async getUserInfo() {
        return await api.get('/api/user/info/')
    }

    async updateUserInfo(model) {
        return await api.post(`/api/user/update/`, model);
    }

    async setNewPassword(model) {
        return await api.post('/api/user/update-password/', model);
    }

    async confirmEmail(token) {
        return await api.post('/api/confirm/'+token+'/')
    }
}

export default UserService;
import React, { useState} from "react";
import SettingsHeader from "../../components/PageHeaders/SettingsHeader";
import PersonalDataForm from "../../components/Forms/PersonalDataForm";
import PasswordResetForm from "../../components/Forms/PasswordResetForm";
import Notification from "../../components/Notification/Notification";


function Settings() {
    let [showNotify, setShowNotify] = useState(false);
    let [text, setText] = useState();
    let [status, setStatus] = useState();
    let showSuccessToast = () => {
        return showNotify ? <Notification status={status} text={text} isShow={showNotify}/> : null
    }




    return <div className="main-container">
        {showSuccessToast()}
        <SettingsHeader/>
        <PersonalDataForm setToast={setShowNotify} setText={setText} setStatus={setStatus}/>
        <PasswordResetForm setToast={setShowNotify} setText={setText} setStatus={setStatus}/>
    </div>
}

export default Settings;
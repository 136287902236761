import React from "react";
import { FormattedMessage } from "react-intl";

function ChatSupportLink() {
    return <div className="form-group-support">
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12 1.43858C12 1.05704 11.842 0.691135 11.5607 0.421349C11.2794 0.151564 10.8978 0 10.5 0H1.5C1.10218 0 0.720644 0.151564 0.43934 0.421349C0.158035 0.691135 0 1.05704 0 1.43858V7.19288C0 7.57442 0.158035 7.94032 0.43934 8.21011C0.720644 8.4799 1.10218 8.63146 1.5 8.63146H8.6895C8.8884 8.6315 9.07913 8.70731 9.21975 8.84221L11.3595 10.8943C11.4119 10.9447 11.4787 10.9791 11.5515 10.993C11.6242 11.007 11.6997 10.9999 11.7683 10.9727C11.8368 10.9455 11.8954 10.8995 11.9367 10.8403C11.978 10.7812 12 10.7116 12 10.6404V1.43858ZM3.75 4.31573C3.75 4.5065 3.67098 4.68945 3.53033 4.82434C3.38968 4.95924 3.19891 5.03502 3 5.03502C2.80109 5.03502 2.61032 4.95924 2.46967 4.82434C2.32902 4.68945 2.25 4.5065 2.25 4.31573C2.25 4.12496 2.32902 3.94201 2.46967 3.80712C2.61032 3.67222 2.80109 3.59644 3 3.59644C3.19891 3.59644 3.38968 3.67222 3.53033 3.80712C3.67098 3.94201 3.75 4.12496 3.75 4.31573ZM6.75 4.31573C6.75 4.5065 6.67098 4.68945 6.53033 4.82434C6.38968 4.95924 6.19891 5.03502 6 5.03502C5.80109 5.03502 5.61032 4.95924 5.46967 4.82434C5.32902 4.68945 5.25 4.5065 5.25 4.31573C5.25 4.12496 5.32902 3.94201 5.46967 3.80712C5.61032 3.67222 5.80109 3.59644 6 3.59644C6.19891 3.59644 6.38968 3.67222 6.53033 3.80712C6.67098 3.94201 6.75 4.12496 6.75 4.31573ZM9 5.03502C9.19891 5.03502 9.38968 4.95924 9.53033 4.82434C9.67098 4.68945 9.75 4.5065 9.75 4.31573C9.75 4.12496 9.67098 3.94201 9.53033 3.80712C9.38968 3.67222 9.19891 3.59644 9 3.59644C8.80109 3.59644 8.61032 3.67222 8.46967 3.80712C8.32902 3.94201 8.25 4.12496 8.25 4.31573C8.25 4.5065 8.32902 4.68945 8.46967 4.82434C8.61032 4.95924 8.80109 5.03502 9 5.03502Z"
                  fill="#AB2F52"/>
        </svg>
        <p><FormattedMessage id = "institutions.chat"/></p>
    </div>
}

export default ChatSupportLink;
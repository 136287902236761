import React, {useEffect, useRef} from "react";
import TRANSACTION_DATA from "../../test-data/transaction-data.json";
import { FormattedMessage } from "react-intl";


function Notification(props) {
    let {status, text, isShow} = props;
    let toastRef = useRef(null);

    let executeScroll = () => toastRef.current.scrollIntoView({block: "center", behavior: "smooth"});

    useEffect(() => {
        if (toastRef) {
            executeScroll()
        }
    }, [toastRef])

    let mapStatusToClass = {
        attention: {
            text: <FormattedMessage id = "notification.attention"/>,
            className: "notification notification--attention",
            svg: <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389956 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89472 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 0 9 0ZM9.9 12.6C9.9 12.8387 9.80518 13.0676 9.6364 13.2364C9.46762 13.4052 9.2387 13.5 9 13.5C8.76131 13.5 8.53239 13.4052 8.36361 13.2364C8.19482 13.0676 8.1 12.8387 8.1 12.6V8.1C8.1 7.8613 8.19482 7.63238 8.36361 7.4636C8.53239 7.29482 8.76131 7.2 9 7.2C9.2387 7.2 9.46762 7.29482 9.6364 7.4636C9.80518 7.63238 9.9 7.8613 9.9 8.1V12.6ZM9 6.3C8.822 6.3 8.64799 6.24721 8.49999 6.14832C8.35198 6.04943 8.23663 5.90887 8.16851 5.74441C8.10039 5.57996 8.08257 5.399 8.1173 5.22442C8.15202 5.04983 8.23774 4.88947 8.36361 4.7636C8.48947 4.63774 8.64984 4.55202 8.82442 4.51729C8.999 4.48256 9.17997 4.50039 9.34442 4.56851C9.50887 4.63663 9.64943 4.75198 9.74833 4.89999C9.84722 5.04799 9.9 5.222 9.9 5.4C9.9 5.63869 9.80518 5.86761 9.6364 6.03639C9.46762 6.20518 9.2387 6.3 9 6.3Z"
                    fill="#888888"/>
            </svg>

        },
        warning: {
            text: <FormattedMessage id = "notification.warning"/>,
            className: "notification notification--warning",
            svg: <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.744 13.8363L10.8692 1.11072C10.6872 0.774647 10.4184 0.494105 10.0911 0.298635C9.76379 0.103165 9.39009 0 9.00936 0C8.62862 0 8.25493 0.103165 7.92763 0.298635C7.60032 0.494105 7.33152 0.774647 7.14951 1.11072L0.2677 13.8363C0.0875763 14.1612 -0.00466126 14.5279 0.000181311 14.8998C0.00502388 15.2717 0.106777 15.6359 0.295299 15.9559C0.483821 16.276 0.752526 16.5408 1.07463 16.724C1.39673 16.9072 1.76099 17.0023 2.13108 17H15.8806C16.2476 17.0003 16.6085 16.9047 16.9277 16.7226C17.2469 16.5406 17.5136 16.2782 17.7015 15.9613C17.8894 15.6444 17.9921 15.2839 17.9996 14.9151C18.007 14.5463 17.9189 14.1819 17.744 13.8576V13.8363ZM7.95415 4.75674C7.95415 4.47454 8.06569 4.20391 8.26425 4.00437C8.4628 3.80482 8.73209 3.69272 9.01289 3.69272C9.29368 3.69272 9.56298 3.80482 9.76153 4.00437C9.96008 4.20391 10.0716 4.47454 10.0716 4.75674V9.64409C10.0716 9.92629 9.96008 10.1969 9.76153 10.3965C9.56298 10.596 9.29368 10.7081 9.01289 10.7081C8.73209 10.7081 8.4628 10.596 8.26425 10.3965C8.06569 10.1969 7.95415 9.92629 7.95415 9.64409V4.75674ZM9.04112 14.3399C8.80101 14.3399 8.56629 14.2684 8.36665 14.1343C8.167 14.0002 8.0114 13.8097 7.91951 13.5868C7.82763 13.3638 7.80358 13.1185 7.85043 12.8818C7.89727 12.6452 8.0129 12.4278 8.18268 12.2571C8.35246 12.0865 8.56878 11.9703 8.80428 11.9232C9.03977 11.8762 9.28387 11.9003 9.50571 11.9927C9.72754 12.085 9.91715 12.2414 10.0505 12.442C10.1839 12.6427 10.2551 12.8786 10.2551 13.1199C10.2551 13.4434 10.1272 13.7538 9.89956 13.9826C9.67189 14.2114 9.3631 14.3399 9.04112 14.3399Z"
                    fill="#F2994A"/>
            </svg>


        },
        error: {
            text: <FormattedMessage id = "notification.error"/>,
            className: "notification notification--error",
            svg: <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9 18C10.78 18 12.5201 17.4722 14.0001 16.4832C15.4802 15.4943 16.6337 14.0887 17.3149 12.4442C17.9961 10.7996 18.1743 8.99002 17.8271 7.24419C17.4798 5.49836 16.6226 3.89472 15.364 2.63604C14.1053 1.37737 12.5016 0.520205 10.7558 0.172938C9.00998 -0.174329 7.20038 0.00390147 5.55584 0.68509C3.91131 1.36628 2.5057 2.51983 1.51677 3.99987C0.52784 5.47992 -9.4242e-07 7.21997 -7.86805e-07 9C-6.8348e-07 10.1819 0.232791 11.3522 0.685083 12.4442C1.13737 13.5361 1.80031 14.5282 2.63604 15.364C3.47176 16.1997 4.46392 16.8626 5.55585 17.3149C6.64778 17.7672 7.8181 18 9 18ZM8.1 5.4C8.1 5.16131 8.19482 4.93239 8.3636 4.76361C8.53238 4.59483 8.7613 4.5 9 4.5C9.23869 4.5 9.46761 4.59483 9.63639 4.76361C9.80517 4.93239 9.9 5.16131 9.9 5.4L9.9 9.9C9.9 10.1387 9.80518 10.3676 9.63639 10.5364C9.46761 10.7052 9.23869 10.8 9 10.8C8.7613 10.8 8.53238 10.7052 8.3636 10.5364C8.19482 10.3676 8.1 10.1387 8.1 9.9L8.1 5.4ZM9 11.7C9.178 11.7 9.35201 11.7528 9.50001 11.8517C9.64801 11.9506 9.76337 12.0911 9.83149 12.2556C9.89961 12.42 9.91743 12.601 9.8827 12.7756C9.84798 12.9502 9.76226 13.1105 9.63639 13.2364C9.51053 13.3623 9.35016 13.448 9.17558 13.4827C9.001 13.5174 8.82003 13.4996 8.65558 13.4315C8.49113 13.3634 8.35057 13.248 8.25167 13.1C8.15278 12.952 8.1 12.778 8.1 12.6C8.1 12.3613 8.19482 12.1324 8.3636 11.9636C8.53238 11.7948 8.7613 11.7 9 11.7Z"
                    fill="#AB2F52"/>
            </svg>

        },
        success: {
            text: <FormattedMessage id = "notification.succes"/>,
            className: "notification notification--success",
            svg: <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM12.2629 6C12.0698 6.0005 11.8846 6.07261 11.7475 6.20072C11.7405 6.2071 11.7349 6.21389 11.7289 6.22104L8.32508 10.3075L6.27282 8.37463C6.20553 8.30658 6.12438 8.252 6.03421 8.21414C5.94405 8.17629 5.84671 8.15593 5.74802 8.15429C5.64932 8.15265 5.55129 8.16976 5.45976 8.20459C5.36823 8.23943 5.28509 8.29128 5.21529 8.35705C5.14549 8.42282 5.09047 8.50116 5.0535 8.58741C5.01653 8.67365 4.99837 8.76603 5.00011 8.85902C5.00186 8.95202 5.02346 9.04374 5.06363 9.1287C5.10381 9.21366 5.16173 9.29013 5.23395 9.35353L7.82623 11.7971C7.8961 11.863 7.97936 11.9149 8.071 11.9497C8.16265 11.9845 8.26082 12.0016 8.35963 11.9999C8.45844 11.9982 8.55587 11.9777 8.64608 11.9397C8.7363 11.9016 8.81746 11.8469 8.8847 11.7786L12.7971 7.17039C12.9303 7.03864 13.0032 6.86277 12.9999 6.68082C12.9966 6.49886 12.9174 6.32544 12.7795 6.19804C12.6416 6.07064 12.4561 5.9995 12.2629 6Z"
                    fill="#27AE60"/>
            </svg>

        }


    }

    let containerClass = isShow ? "notification-container open" : "notification-container"
    let state = mapStatusToClass[status] || mapStatusToClass.attention

    return <div className={containerClass} ref={toastRef}>
        <div className={state.className}>
            <div className="notification__label">
                {state.svg}
                <p>{state.text}</p>
            </div>
            <div className="notification__status">
                {text}
            </div>
        </div>
    </div>

}


export default Notification;
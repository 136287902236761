import React, { useContext, useEffect, useState } from "react";
import { IntlProvider, intl, useIntl } from 'react-intl';

import Russian from '../lang/ru.json'
import English from '../lang/en.json';
import { exit } from "process";
import { useRecoilState } from "recoil";
import { languageState } from "../store";

let emptyFunc = () => { };

// Тут будем определять инициализационный язык при старте приложения (может в куки запомнить потом, или по айпи определять)
function defineInitialLanguage() {
    return 'ru';
}

const initSystemLanguage = defineInitialLanguage();

export const LanguageContext = React.createContext({
    language: initSystemLanguage,
    setLanguage: emptyFunc,

    fieldsLang: initSystemLanguage,
    setFieldsLang: emptyFunc,
});

export const LanguageContextProvider = (props) => {

    let [globalLanguage, setGlobalLanguage] = useRecoilState(languageState);

    let [language, setLanguage] = useState(globalLanguage);
    let [fieldsLanguage, setFieldsLanguage] = useState(globalLanguage)

    let mapMessageToLang = {
        "ru": Russian,
        "en": English
    }

    let localSetLanguage = (newLang) => {
        setLanguage(newLang);
        setGlobalLanguage(newLang);
    }

    let newLangContext = {
        // Глобальный язык приложения
        language: language,
        setLanguage: localSetLanguage,

        // Глобальный переклюк всех полей на странице
        fieldsLanguage: fieldsLanguage,
        setFieldsLanguage: setFieldsLanguage,
    }

    useEffect(() => {
        setLanguage(globalLanguage);
    }, [globalLanguage]);

    const messages = mapMessageToLang[newLangContext.language];

    return (
        <LanguageContext.Provider value={newLangContext}>
            <IntlProvider messages={messages} locale={newLangContext.language}>
                {props.children}
            </IntlProvider>
        </LanguageContext.Provider>
    )
}

/**
 * Если мы меняем язык страницы, но меняется также язык всех полей
 * @returns 
 */
export function useLanguage() {
    let ctx = useContext(LanguageContext);
    return [
        ctx.language,
        (newLanguage) => {
            ctx.setLanguage(newLanguage);

            // переключаем также и язык
            ctx.setFieldsLanguage(newLanguage);
        }
    ]
}

/**
 * Хук для fieldsLanguage
 * @returns 
 */
export function useFieldsLanguage() {
    let ctx = useContext(LanguageContext);

    return [
        ctx.fieldsLanguage,
        ctx.setFieldsLanguage,
    ]
}

/**
 * Helper hook for translation simple string
 * @returns 
 */
export function useTranslations() {
    let intl = useIntl();
    return (messageId, params = {}) => intl.formatMessage({ id: messageId }, params);
}
import {statuses} from "../../constants/statuses";
import DefaultSelect from "../Fields/DefaultSelect";
import React from "react";
import DiffFieldRenderer from "./DiffFieldRenderer";
import WrapField from "./WrapField";
import { FormattedMessage } from "react-intl";
import { useIsAdmin } from "../../store";
import { useTranslations } from "../../context/LanguageContext";

function SelectField(props) {
    let {status, user, author} = props;
    let inputPart = '?';
    let subtitle = '';
    let inputTitle = '';
    let className = "diff-text diff-text--user";
    let diffColor = '';
    let t = useTranslations();

    let isAdmin = useIsAdmin();

    let render = {
        [statuses.STATUS_MANAGER_MODERATION]: () => {
            if (isAdmin) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            } else {
                subtitle = <FormattedMessage id = "general.setToModerator"/>
            }
            diffColor = '#F5E1A6';

            inputPart = <DiffFieldRenderer {...props}
                                            className={className}
                                            direction={'horizontal'}
                                            diffColor={diffColor}
                                            type="select"
            />
        },
        [statuses.STATUS_USER_MODERATION]: () => {
            if (isAdmin) {
                subtitle = t("general.setToUser")
                className = "diff-text diff-text--admin"
            } else {
                subtitle = t("general.ModeratorSuggestsChanges")
                className = "diff-text diff-text--admin"
            }
            diffColor = "#E2D8FF";
            inputPart = <DiffFieldRenderer {...props}
                                            className={className}
                                            direction={'horizontal'}
                                            diffColor={diffColor}
                                            type="select"
            />
        },
        [statuses.STATUS_USER_EDIT]: () => {
            subtitle = <FormattedMessage id = "general.setToModerator"/>
            inputTitle = <FormattedMessage id ="general.change"/>
            inputPart = <DefaultSelect {...props} isDisabled={false}/>
        },
        [statuses.STATUS_MANAGER_EDIT]: () => {
            subtitle = t("general.setToUser")
            if (author.id !== user.model.id) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            }
            inputTitle = <FormattedMessage id ="general.change"/>
            inputPart = <DefaultSelect {...props} isDisabled={false}/> 
        },
        [statuses.STATUS_EDIT]: () => {
            inputPart = <DefaultSelect {...props} isDisabled={false}/>
        },
        [statuses.STATUS_INITIAL]: () => {
            inputPart =
                <DefaultSelect {...props} isDisabled={true}/>
        }
    }

    if (render[status]) {
        render[status]();
    } else {
        inputPart = 'Status Not Handled'
    }

    return <WrapField {...props}
                        inputPart={inputPart}
                        subtitle={subtitle}
                        inputTitle={inputTitle}
                        status={status}
    />
}

export default SelectField;
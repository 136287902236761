import React, {useState, useEffect, useRef, useContext} from 'react';
import {LanguageContext} from "../../context/LanguageContext";

//автокомпликт из бикьюби

function AutocompleteBqb(props) {
    let {
        query: initialQuery,
        refs,
        onChange,
        onClick,
        fieldName,
        changed,
        language,
        changeMarkerPosition,
        placeholder = ''
    } = props;


    let [query, setQuery] = useState('');
    let [options, setOptions] = useState([]);
    let [ddShow, setDdShow] = useState(false);
    let [buttonShow, setButtonShow] = useState(false);
    let [oldQuery, setOldQuery] = useState('');
    let tmRef = useRef(null);

    let root = useRef();


    useEffect(() => {
        if (oldQuery !== initialQuery) {
            setQuery(initialQuery);
            setOldQuery(initialQuery);
        }
        if (changed) {
            setButtonShow(true);
        }

    }, [initialQuery, changed]);


    function locFetchData(query) {
        let service = refs.current.getSearchService();
        if (query) {
            service.geocode({
                q: query
            }, (result) => {
                let newOptions = [];
                result.items.forEach((item) => {
                    newOptions.push({text : item.address.label, data: item})
                })
                setOptions(newOptions);
                setDdShow(true);
            }, alert);
        }
    }

    let localOnclick = () => {
        setButtonShow(false)
        setDdShow(false)
        onClick(fieldName, language)
    }

    function locChange(e) {
        setButtonShow(false);
        let newValue = e.target.value;
        setQuery(newValue);
        clearTimeout(tmRef.current);
        tmRef.current = setTimeout(() => {
            locFetchData(newValue)
        }, 500);
    }

    function selectVariant(suggestion) {
        let newQuery = suggestion.text;
        setQuery(newQuery);
        setOldQuery(newQuery);
        if (onChange) {
            onChange(suggestion.text);
        }
        changeMarkerPosition(suggestion.data.position.lat, suggestion.data.position.lng)
        setTimeout(() => setDdShow(false), 300);
        setButtonShow(true)
    }

    let containerStyles = {
        position: 'relative',
    };

    let focus = () => {
        locFetchData(query);
    };

    let inputStyles = {
        width: '100%',
        borderBottom: '5px solid #AB2F52',
        marginBottom: 0
    };

    let blur = () => {
        setQuery(oldQuery);
        setTimeout(() => setDdShow(false), 300)
    };

    let dropDownStyles = {
        background: 'white',
        cursor: 'pointer',
        padding: '10px',
        borderBottom: '1px solid #DEDEDE',
        color: 'black'
    };

    let buttonStyles = {
        background: '#AB2F52',
        borderRadius: '3px',
        textTransform: 'uppercase',
        padding: '13px 16px',
        color: '#FFFFFF',
        marginLeft: 'auto',
        display: 'flex',
        border: 'none'
    }

    return (
        <div
            ref={root}
            className="autocomplete"
            onFocus={focus}
            onBlur={blur}
        >
            <div className="container" style={containerStyles}>
                <input
                    placeholder={placeholder}
                    id={'addr_autocomplete'}
                    onChange={locChange}
                    value={query}
                    style={inputStyles}
                    type="text"
                    className="form-control autocomplete__input"
                />
                <div style={{display: ddShow ? 'block' : 'none', maxHeight: '100px'}}
                    className="autocomplete__dropdown">
                    {options.map((item, index) => {
                        return (
                            <div
                                className="autocomplete__dropdown-item"
                                onMouseDown={() => selectVariant(item)}
                                key={item.text + index}>{item.text}</div>
                        )
                    })}
                </div>
                <div style={{display: buttonShow ? 'block' : 'none'}} className="autocomplete__dropdown">
                    <div
                        className="autocomplete__dropdown-item"
                        style={dropDownStyles}>
                        <button
                            className="autocomplete__dropdown-button"
                            onClick={localOnclick}
                            style={buttonStyles}>Добавить адрес
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AutocompleteBqb;
export const statuses = {
    STATUS_INITIAL: 10,
    STATUS_EDIT: 15,
    STATUS_USER_MODERATION: 20,
    STATUS_USER_EDIT: 25,
    STATUS_MANAGER_MODERATION: 30,
    STATUS_MANAGER_EDIT: 35,
    STATUS_ACCEPTED: 40,
    STATUS_USER_REJECTED: 50,
    STATUS_MANAGER_REJECTED: 60,
    STATUS_USER_CANCELED: 70,
    STATUS_MANAGER_CANCELED: 80,
    STATUS_MEDIA_MODERATION: 100,
    STATUS_PROGRAM_CHANGE: 110,
    STATUS_POST_MODERATION : 120,
    // STATUS_DELETE: 130,
    STATUS_UNDEFINED : 0
}
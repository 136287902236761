import {statuses} from "../../constants/statuses";
import React from "react";
import DiffFieldRenderer from "../AccountFields/DiffFieldRenderer";
import WrapGallery from "./WrapGallery";
import ShowMedia from "./ShowMedia";
import ShowFiles from "./ShowFiles";
import {FormattedMessage} from "react-intl";
import { useIsAdmin } from "../../store";

// Вывод галереи в зависимости от статуса запроса

function Gallery(props) {
    let {
        status,
        initialValue,
        value,
        user,
        type,
    } = props;

    let galleryPart = '?';
    let subtitle = '';
    let inputTitle = '';
    let className = "diff-text diff-text--user"

    let isAdmin = useIsAdmin();

    let render = {
        [statuses.STATUS_MEDIA_MODERATION]: () => {
            if (isAdmin) {
                subtitle = <FormattedMessage id="tabs.media.tip"/>
            } else {
                subtitle = <FormattedMessage id="tabs.media.tip"/>
                inputTitle = <FormattedMessage id = "general.setToModerator"/>
            }

            galleryPart = <DiffFieldRenderer {...props}
                                                className={className}
                                                direction={'vertical'}
                                                type={type}
            />
        },
        [statuses.STATUS_USER_EDIT]: () => {
            subtitle = <FormattedMessage id="tabs.media.tip"/>
            inputTitle = <FormattedMessage id ="general.change"/>

            if (type === 'media') {
                galleryPart = <ShowMedia{...props} model={value} managed={true} showDropZone={true} showDeletions={true}
                                        disableSort={false}/>
            } else {
                galleryPart = <ShowFiles{...props} model={value} managed={true} showDropZone={true} showDeletions={true}/>
            }
        },

        [statuses.STATUS_EDIT]: () => {
            subtitle = <FormattedMessage id="tabs.media.tip"/>

            if (type === 'media') {
                galleryPart = <ShowMedia{...props} model={value} managed={true} showDropZone={true} showDeletions={true}
                                        disableSort={false}/>
            } else {
                galleryPart = <ShowFiles{...props} model={value} managed={true} showDropZone={true} showDeletions={true}/>
            }
        },

        [statuses.STATUS_MANAGER_EDIT]: () => {
            subtitle = <FormattedMessage id="tabs.media.tip"/>
            inputTitle = <FormattedMessage id ="general.change"/>
            if (type === 'media') {
                galleryPart =
                    <ShowMedia{...props} model={value} managed={true} showDropZone={true} showDeletions={true}
                                disableSort={false}/>
            } else {
                galleryPart =
                    <ShowFiles{...props} model={value} managed={true} showDropZone={true} showDeletions={true}/>
            }

        },
        [statuses.STATUS_INITIAL]: () => {
            if (type === 'media') {
                galleryPart = <ShowMedia{...props} model={initialValue}/>
            } else {
                galleryPart = <ShowFiles{...props} model={initialValue}/>
            }

        }
    }

    if (render[status]) {
        render[status]();
    } else {
        galleryPart = 'Status Not Handled'
    }

    return <WrapGallery {...props} inputPart={galleryPart} subtitle={subtitle} inputTitle={inputTitle}/>
}

export default Gallery;
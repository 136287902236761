import React from 'react';
import qs from 'querystring';
import { userTokenState, languageState } from "./store";
import { getRecoilExternalLoadable } from './RecoilExternalStatePortal';
import base64 from 'base-64';

function getApiHost(){
    let lang = getRecoilExternalLoadable(languageState);

    const map = {
        'ru': process.env.REACT_APP_API_HOST_RU,
        'en': process.env.REACT_APP_API_HOST_EN,
    };

    return lang ? map[lang.contents] : process.env.REACT_APP_API_HOST_RU;
    // return 'http://127.0.0.1:8000';
}

// информация необходимая для базовой аутентификации на тестовом сервере
const BASIC_LOGIN = 'test';
const BASIC_PASSWORD = 'test';
const BASIC_AUTH_HEADER_VALUE = 'Basic ' + base64.encode(BASIC_LOGIN + ":" + BASIC_PASSWORD);

class Api {
    constructor() {
        this.token = null;
    }

    get(url, query = {}) {
        return new Promise(r => {
            let token = getRecoilExternalLoadable(userTokenState);

            if (!token) {
                token = '';
            } else {
                token = token.contents;
            }

            let encodedStr = qs.encode(query);
            encodedStr = encodedStr ? "?" + encodedStr : '';
            fetch(getApiHost() + url + encodedStr, {
                    mode: 'cors',
                    headers: {
                        // Tut ndata oba tokena, odin bazovaya drugaya dlya prilojeniya, chuvak
                        'Auth': 'token ' + token,
                        'Authorization': BASIC_AUTH_HEADER_VALUE,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',

                    },
                })
                .then(data => data.json())
                .then(data => r(data));
        });
    }

    post(url, payload_data = {}, query = {}) {
        return new Promise(r => {
            let token = getRecoilExternalLoadable(userTokenState);

            if (!token) {
                token = '';
            } else {
                token = token.contents;
            }

            fetch(getApiHost() + url + '?' + qs.encode(query), {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'Auth': 'token ' + token,
                        'Authorization': BASIC_AUTH_HEADER_VALUE,
                        'Content-Type': 'application/json',
                    },
                    redirect: 'follow',
                    body: JSON.stringify(payload_data),
                })
                .then(data => data.json())
                .then(data => r(data)).catch((error) => {
                    alert('An error occured: ' + error.message);
                    console.error(error);
                });
        });
    }

    upload(url, file, query = {}) {
        return new Promise(r => {
            let token = getRecoilExternalLoadable(userTokenState);

            if (!token) {
                token = '';
            } else {
                token = token.contents;
            }

            fetch(getApiHost() + url + '?' + qs.encode(query), {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'Auth': 'token ' + token,
                        'Authorization': BASIC_AUTH_HEADER_VALUE,
                        // 'Authorization': 'token ' + token,
                        // 'Content-Type': "multipart/form-data",
                    },
                    redirect: 'follow',
                    body: file,
                })
                .then(data => data.json())
                .then(data => r(data));
        });
    }


}

let api = new Api();
export const ApiContext = React.createContext(api);

export default api;
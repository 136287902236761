import React, { useEffect, useState } from "react";
import { FaSpinner } from 'react-icons/fa';
import { cloneDeep } from "lodash";

function RenderButtons(props) {
    const [buttons, setButtons] = useState({ ...props.buttons });
    const [loadStates, setLoadStates] = useState({});

    useEffect(() => {
        setButtons(props.buttons);
    }, [props.buttons])


    let mapTypeToClass = {
        "red": "btns__item btns__item--first",
        "white": "btns__item"
    }

    let setLoading = (index, state) => {
        let newLoadStates = cloneDeep(loadStates);
        newLoadStates[index] = state;
        setLoadStates(newLoadStates);
    }

    let localOnClick = (item, index) => {

        setLoading(index, true);
        let result = item.action();

        if (result instanceof Promise) {
            result.then(() => {
                setLoading(index, false);
            })
        } else {
            setLoading(index, false);
        }
    }

    // 06.06.2022 17:44


    return <div className="btns">
        {
            buttons.length > 0 ? buttons.map(
                (item, index) => {
                    // let isLoading = loadStates[index] || false;

                    let isLoading = props.isLoading;

                    let className = mapTypeToClass[item.color] || 'btns__item';
                    return <button
                        disabled={isLoading}
                        className={className}
                        key={index}
                        onClick={() => {
                            localOnClick(item, index)
                        }}>
                        {
                            isLoading ? <FaSpinner icon="spinner" className="spinner" /> : null
                        }
                        {item.label}
                    </button>
                }) : null

        }
    </div>
}

export default RenderButtons;
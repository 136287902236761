import React, { useContext, useEffect, useState } from "react";
import { LanguageContext, useFieldsLanguage } from "../../context/LanguageContext";

// Компонент для изменения языка на полях школы

function HeaderSchoolLangSwitcher(props) {
    let [fieldsLanguage, setFieldsLanguage] = useFieldsLanguage();

    let switchPageLang = (newLang) => {
        setFieldsLanguage(newLang);
    }

    return <div className="lang-switcher">
        <button className={fieldsLanguage === 'ru' ? 'lang-switcher__item active' : 'lang-switcher__item'}
            onClick={() => switchPageLang("ru")}
        >Ru</button>
        <button className={fieldsLanguage === 'en' ? 'lang-switcher__item active' : 'lang-switcher__item'}
            onClick={() => switchPageLang("en")}
        >En</button>
    </div>
}

export default HeaderSchoolLangSwitcher;
import React from "react";
import {Link} from "react-router-dom";

const DATA = [
    {
        id: 1,
        user: 'Модератор',
        status: 10,
        url: '/institutions/warwick/programs/a-level',
        timestamp: '28.08.2020, 11:00'
    },
    {
        id: 2,
        user: 'Алиса К.',
        status: 20,
        url: '/institutions/warwick/programs/b-level',
        timestamp: '25.08.2020, 15:16'
    },
    {
        id: 3,
        user: 'Алиса К.',
        status: 30,
        url: '/institutions/warwick/media',
        timestamp: '28.08.2020, 11:00'
    },
    {
        id: 5,
        user: 'Модератор',
        status: 40,
        url: '/institutions/warwick/articles/where-to-go-for-summer-practice',
        timestamp: '28.08.2020, 11:00'
    },
    {
        id: 6,
        user: 'Модератор',
        status: 50,
        url: '/institutions/warwick/basic',
        timestamp: '28.08.2020, 11:00'
    },
    {
        user: 'Алиса К.',
        status: 30,
        url: '/institutions/warwick/media',
        timestamp: '28.08.2020, 11:00'
    },


]

function Changes(props) {
    let {model} = props;

    let mapStatusToName = {
        10: 'внес изменения в программу',
        20: 'создала программу',
        30: 'внесла изменения в раздел',
        40: 'создал статью',
        50: 'предложила изменения в разделе'
    }

    let mapLinkToName = {
        '/institutions/warwick/media': "«Фото и видео»",
        '/institutions/warwick/programs/a-level': "«Подготовка к A-level»",
        '/institutions/warwick/programs/b-level': "«B-level»",
        '/institutions/warwick/basic': "«Основное»",
        '/institutions/warwick/articles/where-to-go-for-summer-practice': " «Куда поступить на летнюю практику?»"
    }

    return model.map((item, index) => (
        <li className="status-item" key={index}>
            <div className="status-item__label">
                <p>
                    {item.user} {mapStatusToName[item.status] || ''}
                    <Link to={item.url}> {mapLinkToName[item.url] || ''} </Link>
                </p>
                <div className="status-item__timestamp">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6 0C2.6916 0 0 2.6916 0 6C0 9.3084 2.6916 12 6 12C9.3084 12 12 9.3084 12 6C12 2.6916 9.3084 0 6 0ZM7.9758 8.8242L5.4 6.2484V2.4H6.6V5.7516L8.8242 7.9758L7.9758 8.8242Z"
                            fill="#C4C4C4"/>
                    </svg>
                    {item.timestamp}
                </div>
            </div>
        </li>
    ))
        ;
}


function HistoryChanges() {

    return <ul className="status">
        <Changes model={DATA}/>
    </ul>

}

export default HistoryChanges;
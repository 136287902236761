import { lang } from "@here/maps-api-for-javascript";
import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {LanguageContext} from "../../context/LanguageContext";
import InstituteHeader from "../PageHeaders/InstituteHeader";
import HeaderSchoolLangSwitcher from "./HeaderSchoolLangSwitcher";

// Удобнее сделать 1 компонент вместто двух BaseLangSwitcher && SchoolLangSwitcher

// этот устанавливает язык в заведения -> основное -> (выбранное поле)

function BaseLangSwitcher(props) {
    let pageLang = props;
    pageLang = pageLang.language;
    let {setLanguage, language} = props;

    useEffect (() => {
        setLanguage(pageLang);
    }, [pageLang]);

    return <div className="lang-switcher">
        <button className={language === 'ru' ? 'lang-switcher__item active' : 'lang-switcher__item'}
        onClick={(() => setLanguage('ru'))}
        >Ru</button>
        <button className={language === 'en' ? 'lang-switcher__item active' : 'lang-switcher__item'}
            onClick={(() => setLanguage('en'))}
        >En</button>
    </div>
}

export default BaseLangSwitcher;
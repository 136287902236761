import React, {useContext, useEffect, useState} from 'react';
import FormSection from "./FormSection";
import SchoolProgramService from "../../services/SchoolProgramService";
import {LanguageContext} from "../../context/LanguageContext";
import {useHistory} from "react-router-dom";
import MultiLevelSelect from "../Fields/MultiLevelSelect";
import {FormattedMessage} from "react-intl";

function NewProgramForm(props) {
    let {school_id} = props;
    let [categories, setCategories] = useState([]);
    let [value, setValue] = useState();
    let languageContext = useContext(LanguageContext);
    let [cLang, setCLang] = useState(languageContext.language);
    let [errors, setErrors] = useState({});
    let history = useHistory();

    useEffect(() => {
        setCLang(languageContext.language)

    }, [languageContext.language]);

    useEffect(()=>{(async () => {
        let spService = new SchoolProgramService();
        let response = await spService.loadProgramCategoryList();
        setCategories(response);
    })();
    }, []);


    let showError = (field) => {
        return errors[field] ? <div className="form-error">{errors[field]}</div> : null;
    }

    let createNewProgram = async (e) => {
        e.preventDefault();
        let spService = new SchoolProgramService();
        let model = {
            category_id: value
        }
        let response = await spService.createProgram(school_id, model);
        if (response.errors) {
            setErrors(response.errors);
        } else {
            history.goBack()
        }
    }

    let handleChange = (selected) => {
        setValue(selected.id);
    }

    let input = <MultiLevelSelect
        value={value}
        options={categories}
        onChange={handleChange}
        language={cLang}
        isDisabled={false}
    />

    let language = languageContext.language;
    let tip_FormSection;
    if (language == "ru") {
        tip_FormSection = "Выберите название программы из предложенных вариантов"; 
    } else if (language == "en") {
        tip_FormSection = "Choose the name of the program from the suggested options";
    }

    return <>
        <div className="back-button">
            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 1L2 5L6 9" stroke="#C4C4C4" strokeWidth="2"/>
            </svg>
            <button onClick={() => history.goBack()}>
                <FormattedMessage id="breadcrumb.programs"/>
            </button>
        </div>
        <FormSection
            setLanguage={setCLang}
            language={cLang}
            langSwitch={false}
            required={true}
            title={<FormattedMessage id="program.field.add"/>}
            tip= {tip_FormSection}
        >
            {input}
            {showError("ep")}
            <div className="btns">
                <button
                    className="btns__item btns__item--first"
                    onClick={createNewProgram}
                >{<FormattedMessage id="button.save"/>}
                </button>
            </div>
        </FormSection>
    </>
}

export default NewProgramForm;
import React, {useContext, useEffect, useState} from "react";
import {LanguageContext, useTranslations} from "../../context/LanguageContext";
import {useRecoilValue} from "recoil";
import {userState} from "../../store";
import FormSection from "./FormSection";
import SchoolService from "../../services/SchoolService";
import {useHistory} from "react-router-dom";
import { FormattedMessage } from "react-intl";


{/* Создание новой школы (только имя) */
}

function NewSchoolForm(props) {
    let [name, setName] = useState();
    let languageContext = useContext(LanguageContext);
    let [cLang, setCLang] = useState(languageContext.language);
    const [errors, setErrors] = useState({});
    let user = useRecoilValue(userState);
    let history = useHistory();
    let t = useTranslations();

    useEffect(() => {
        setCLang(languageContext.language)

    }, [languageContext.language]);


    let showError = (field) => {
        return errors[field] ? <div className="form-error">{errors[field]}</div> : null;
    }

    let createNewSchool = async (e) => {
        e.preventDefault();
        setErrors({});

        let schoolService = new SchoolService();
        let model = {
            language: cLang,
            // user_id: user.model.id,
            name
        }
        let response = await schoolService.createNewSchool(model);
        if (response.errors) {
            setErrors(response.errors);
        }
        if (response.id) {
            let url = response.id + '/basic';
            history.push(url)
        } else {
            setErrors({name: t("institutions.create-error")})
        }
    }

    return <FormSection {...props}
                        setLanguage={setCLang}
                        language={cLang}
    >
        <input type="text"
            name="school-name"
            value={name}
            onChange={e => setName(e.target.value)}/>
            {showError("name")}
        <div className="btns">
            <button
                className="btns__item btns__item--first"
                onClick={createNewSchool}
            >{<FormattedMessage id="button.save"/>}
            </button>
        </div>
    </FormSection>
}

export default NewSchoolForm;
import { useState } from "react";
import InstitutionHeader from "../PageHeaders/InstitutionHeader";
import Notification from "../Notification/Notification";
import { FormattedMessage } from "react-intl";

// компонент отвечает за скрепку и вывод скрепки в школе 
// Он копирует ссылку школы при нажатии на скрепку

// думаю накрутил тут лишней логики, а еще хотелось 
// бы добавить что-то типа всплывающего окна типа ссылка скопирована

export default function IconClip({link}) {
    let copy = [];
    copy.push(link);
    function copyToLink (event) {
        const elem = event.target;
        navigator.clipboard.writeText(copy);
    }

    return <div className="heading__link__copy"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={copyToLink}>
        <path
            d="M4.09979 6.41999C5.02933 5.49054 6.6505 5.49054 7.58004 6.41999L8.16009 6.99998L9.32017 5.84001L8.74013 5.26002C7.96646 4.48561 6.93601 4.05821 5.83991 4.05821C4.74382 4.05821 3.71336 4.48561 2.9397 5.26002L1.19875 6.99998C0.431071 7.77005 0 8.81301 0 9.90031C0 10.9876 0.431071 12.0306 1.19875 12.8006C1.57933 13.1817 2.03146 13.4839 2.52917 13.6897C3.02688 13.8955 3.56037 14.001 4.09896 14C4.63771 14.0011 5.17136 13.8957 5.66923 13.6899C6.16709 13.4841 6.61935 13.1819 7 12.8006L7.58004 12.2207L6.41996 11.0607L5.83991 11.6407C5.37758 12.1009 4.75176 12.3593 4.09938 12.3593C3.44699 12.3593 2.82117 12.1009 2.35884 11.6407C1.89817 11.1786 1.63949 10.5528 1.63949 9.90031C1.63949 9.24786 1.89817 8.62202 2.35884 8.15995L4.09979 6.41999Z"
            fill="#AB2F52" />
        <path
            d="M7 1.19931L6.41996 1.77929L7.58004 2.93926L8.16009 2.35928C8.62242 1.89905 9.24824 1.64068 9.90062 1.64068C10.553 1.64068 11.1788 1.89905 11.6412 2.35928C12.1018 2.82135 12.3605 3.44719 12.3605 4.09964C12.3605 4.75209 12.1018 5.37793 11.6412 5.84001L9.90021 7.57996C8.97067 8.50941 7.3495 8.50941 6.41996 7.57996L5.83991 6.99998L4.67983 8.15995L5.25987 8.73993C6.03354 9.51434 7.06399 9.94174 8.16009 9.94174C9.25618 9.94174 10.2866 9.51434 11.0603 8.73993L12.8012 6.99998C13.5689 6.2299 14 5.18694 14 4.09964C14 3.01234 13.5689 1.96938 12.8012 1.19931C12.0313 0.431304 10.9882 0 9.90062 0C8.81308 0 7.76994 0.431304 7 1.19931Z"
            fill="#AB2F52" />
    </svg>
    </div>
}
import React, {useContext, useEffect, useState} from "react";
import UserService from "../../services/UserService";
import DefaultRadio from "../Fields/DefaultRadio";
import {FormattedMessage} from "react-intl";
import {LanguageContext} from "../../context/LanguageContext";


function PersonalDataForm(props) {
    let {setToast, setText, setStatus} = props;
    const [model, setModel] = useState({
        phone: "",
        fullName: "",
        email: ""
    });
    const [errors, setErrors] = useState({});

    let langContext = useContext(LanguageContext);


    useEffect(()=>{(async () => {
        let userService = new UserService();
        let _model = await userService.getUserInfo();
        setModel(_model)
    })();
    }, [])

    let mapLabelByLang = {
        'ru': {
            m: "Мужской",
            w: "Женский"
        },
        'en': {
            m: "Male",
            w: "Female"
        },
    };

    let showError = (field) => {
        return errors[field] ? <div className="form-error">{errors[field].join(', ')}</div> : null;
    }

    let generateToast = (state) => {
        if (state === "error") {
            setText(<FormattedMessage id = "notification.failed_to_update"/>)
            setStatus("error")
        } else {
            setText(<FormattedMessage id = "notification.succes_to_update"/>)
            setStatus("success")
        }
    }

    let onSubmit = async (e) => {
        e.preventDefault();
        setErrors({});

        let userService = new UserService();
        let response = await userService.updateUserInfo(model);

        if (response.errors) {
            generateToast('error')
            setErrors(response.errors);
        } else {
            generateToast('success')
            setModel(response.model);
        }
        setToast(true);

        setTimeout(() => {
            setToast(false);
        }, 3000)
    }

    let labels = mapLabelByLang[langContext.language];

    let gender_options = [
        {
            value: 'm',
            label: labels.m,
        },
        {
            value: 'w',
            label: labels.w,
        },
    ]

    function handleChange(e) {
        const value = e.target.value;
        setModel({
            ...model,
            [e.target.name]: value
        });
    }

    function radioChange(e) {
        const value = e.target.value;
        setModel({
            ...model,
            ['gender']: value
        })
    }


    return <>
        <div className="form-section">
            <div className="content-block">
                <div className="form-section__title">
                    <FormattedMessage id="settings.personal_form.title"/>
                </div>
            </div>
            <form onSubmit={onSubmit}>
                <div className="input-container input-container--base">
                    <div className="input">
                        <div className="input__title">
                            <p>
                                <FormattedMessage id="settings.personal_form.gender"/>
                            </p>
                        </div>
                        <DefaultRadio
                            value={model.gender || ""}
                            position="row"
                            options={gender_options}
                            onChange={radioChange}
                        />
                        {showError('gender')}
                    </div>
                </div>
                <div className="input-container input-container--base">
                    <div className="input">
                        <div className="input__title">
                            <p>
                                <FormattedMessage id="settings.personal_form.name"/>
                            </p>
                        </div>
                        <div className="input input--base">
                            <input type="text"
                                className="input__area input__area--base"
                                name="fullname"
                                value={model.fullname || ""}
                                onChange={handleChange}/>
                            {showError('fullname')}
                        </div>
                    </div>
                </div>

                <div className="input-container input-container--base">
                    <div className="input">
                        <div className="input__title">
                            <p>
                                <FormattedMessage id="settings.personal_form.phone"/>
                            </p>
                        </div>
                        <div className="input input--base">
                            <input type="text"
                                className="input__area input__area--base"
                                name="phone"
                                placeholder="+7"
                                value={model.phone || ""}
                                onChange={handleChange}/>
                            {showError('phone')}
                        </div>
                    </div>
                </div>

                <div className="input-container input-container--base">
                    <div className="input">
                        <div className="input__title">
                            <p>
                                <FormattedMessage id="settings.personal_form.email"/>
                            </p>
                        </div>
                        <div className="input input--base">
                            <input type="text"
                                className="input__area input__area--base"
                                name="email"
                                value={model.email || ""}
                                onChange={handleChange}/>
                            {showError('email')}
                        </div>
                    </div>
                </div>
                <div className="button-save button-save--small">
                    <button type="submit">
                        <FormattedMessage id="settings.personal_form.button"/>
                    </button>
                </div>
            </form>
        </div>
    </>

}

export default PersonalDataForm;
import {statuses} from "../../constants/statuses";
import Ckeditor from "../Ckeditor/Ckeditor";
import {SafeHTML} from "../SafeHtml/SafeHtml";
import React from "react";
import DiffFieldRenderer from "./DiffFieldRenderer";
import WrapField from "./WrapField";
import { FormattedMessage } from "react-intl";
import { useIsAdmin } from "../../store";
import { useTranslations } from "../../context/LanguageContext";

function CkeditorField(props) {
    let {
        status,
        value,
        onChange,
        fieldName,
        difference,
        author,
        user,
    } = props;

    let inputPart = '?';
    let subtitle = '';
    let inputTitle = '';
    let className = "diff-text diff-text--user"
    let t = useTranslations();

    let isAdmin = useIsAdmin();

    let render = {
        [statuses.STATUS_MANAGER_MODERATION]: () => {
            if (isAdmin) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            } else {
                subtitle = <FormattedMessage id = "general.setToModerator"/>
            }

            inputPart = <DiffFieldRenderer
                direction={'vertical'}
                className={className}
                initialValue={value}
                difference={difference}/>
        },
        [statuses.STATUS_USER_MODERATION]: () => {
            if (isAdmin) {
                subtitle = t("general.setToUser")
                className = "diff-text diff-text--admin"
            } else {
                subtitle = t("general.ModeratorSuggestsChanges")
                className = "diff-text diff-text--admin"
            }

            inputPart = <DiffFieldRenderer
                direction={'vertical'}
                className={className}
                initialValue={value}
                difference={difference}/>
        },
        [statuses.STATUS_USER_EDIT]: () => {
            subtitle = <FormattedMessage id = "general.setToModerator"/>
            inputTitle = <FormattedMessage id ="general.change"/>
            inputPart = <Ckeditor value={value} onChange={value => onChange(fieldName, value)}/>
        },
        [statuses.STATUS_MANAGER_EDIT]: () => {
            subtitle = t("general.setToUser")
            if (author.id !== user.model.id) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            }
            inputTitle = <FormattedMessage id ="general.change"/>
            inputPart = <Ckeditor value={value} onChange={value => onChange(fieldName, value)}/>
        },
        [statuses.STATUS_EDIT]: () => {
            inputPart = <Ckeditor value={value} onChange={value => onChange(fieldName, value)}/>
        },
        [statuses.STATUS_INITIAL]: () => {
            inputPart = <SafeHTML className="diff-text">{value}</SafeHTML>
        }
    }

    

    if (render[status]) {
        render[status]();
    } else {
        inputPart = 'Status not find'
    }

    return <WrapField {...props}
                    inputPart={inputPart}
                    subtitle={subtitle}
                    inputTitle={inputTitle}
                    status={status}
                    langSwitch={false}
    />


}
export default CkeditorField;
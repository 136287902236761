import React, { useEffect, useState } from "react";
import Select from "react-select";

const customStyles = {
    control: () => ({
        border: '1px solid #AB2F52',
        display: 'flex',
        width: '50%',
        marginLeft: 'auto'
    }),

    indicatorsContainer: () => {
        return {
            borderLeft: '1px solid #AB2F52',
        };
    },

    option: (styles, { isDisabled, isFocused, isSelected }) => {
        const color = "#AB2F52";
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? color
                    : isFocused
                        ? 'rgba(171,47,82,0.2)'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'white' : 'black',
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? color
                        : '#888888'
                    : undefined,
            },
        };
    }
}

function SingleSelectGood(props) {
    let { options, value, onChange, isDisabled, language } = props;
    let localValue = useState(null);

    localValue = options.find(item => item.id == value);

    let localOnChange = (item) => {
        onChange(item.id);
    }

    let localCustomStyles = {
        ...customStyles,
        singleValue: (provided, object) => {
            // let objectId = object.data.id;
            return {
                ...provided,
                color: '#AB2F52',
            }
        }
    }

    return <Select
        getOptionValue={item => item.id}
        getOptionLabel={item => item.name}
        defaultValue={value}
        isDisabled={isDisabled}
        value={localValue}
        className="radio-option"
        classNamePrefix="select"
        onChange={localOnChange}
        options={options}
        styles={localCustomStyles}

    />
}

export default SingleSelectGood;
import React, {useContext, useState} from 'react';
import {Route, Switch, NavLink, Link, useHistory} from "react-router-dom";
import Basic from "../../components/Tab/Basic/Basic";
import Programs from "../../components/Tab/Programs/Programs";
import ProgramManager from "../../components/Tab/Programs/ProgramManager";
import Media from "../../components/Tab/Media/Media";
import Posts from "../../components/Tab/Posts/Posts";
import CreatePost from "../../components/Tab/Posts/CreatePost";
import HistoryChanges from "../../components/Tab/History/History";
// import Brochures from "../../components/Tab/Brochures/Brochures";
import NewProgramForm from "../../components/Forms/NewProgramForm";
import SinglePost from "../../components/Tab/Posts/SinglePost";
import {categories} from "../../constants/categories";
import {FormattedMessage} from "react-intl";
// import Modal from "react-modal";
// import CustomModal from "../../components/Modal/CustomModal";
import {useRecoilValue} from "recoil";
import {schoolModelState} from "../../store";
import {LanguageContext} from "../../context/LanguageContext";

function Tab(props) {
    const {url} = props.match;
    let school_id = props.match.params.id;
    const school=useRecoilValue(schoolModelState);
    const history=useHistory();
    let languageContext = useContext(LanguageContext);
    let language = languageContext.language;

    let routes = [
        {
            path: '/basic',
            component: Basic,
        },
        {
            path: '/programs',
            component: Programs,
        },
        {
            path: '/programs/create',
            component: NewProgramForm,
        },
        {
            path: '/programs/:program_id',
            component: ProgramManager,
        },

        // {
        //     path: '/brochures',
        //     component: Brochures,
        // },
        {
            path: '/media',
            component: Media,
        },
        {
            path: '/articles',
            component: Posts,
            props: {category: categories.ARTICLE},
        },
        {
            path: '/articles/create',
            component: CreatePost,
            props: {category: categories.ARTICLE, onClick: () => history.push("/institutions/"+school.id+"/articles")},
            
            // сюда надо будет добавить онклик для хистори пуш скорее всего
        },
        {
            path: '/articles/:article_id',
            component: SinglePost,
            props: {category: categories.ARTICLE},
        },
        {
            path: '/news',
            component: Posts,
            props: {category: categories.NEWS}
        },
        {
            path: '/news/create',
            component: CreatePost,
            props: {category: categories.NEWS, onClick: () => history.push("/institutions/"+school.id+"/news")},
        },
        {
            path: '/news/:article_id',
            component: SinglePost,
            props: {category: categories.NEWS},
        },
        {
            path: '/shares',
            component: Posts,
            props: {category: categories.SHARE}
        },
        {
            path: '/shares/create',
            component: CreatePost,
            props: {category: categories.SHARE,  onClick: () => history.push("/institutions/"+school.id+"/shares")},
        },
        {
            path: '/shares/:article_id',
            component: SinglePost,
            props: {category: categories.SHARE},
        },
        {
            path: '/history',
            component: HistoryChanges,
        },
    ];

    return (
        <div className="wrapper">
            <div className="content">
                <div className="tabs">
                    <nav className="tabs__items">
                        <NavLink to={`${url}/basic`} className="tabs__item" exact
                                activeClassName="tabs__item--active">
                            <FormattedMessage id="tabs.main"/>
                        </NavLink>
                        <NavLink to={`${url}/programs`} className="tabs__item"
                                activeClassName='tabs__item--active'>
                            <FormattedMessage id="tabs.programs"/>
                        </NavLink>
                        <NavLink to={`${url}/media`} className="tabs__item" activeClassName='tabs__item--active'>
                            <FormattedMessage id="tabs.media"/>
                        </NavLink>
                        {/*<NavLink to={`${url}/brochures`} className="tabs__item"*/}
                        {/*         activeClassName='tabs__item--active'>*/}
                        {/*    <FormattedMessage id="tabs.brochure"/>*/}
                        {/*</NavLink>*/}
                        <NavLink to={`${url}/articles`} className="tabs__item"
                                activeClassName='tabs__item--active'>
                            <FormattedMessage id="tabs.article"/>
                        </NavLink>
                        <NavLink to={`${url}/news`} className="tabs__item"
                                activeClassName='tabs__item--active'>
                            <FormattedMessage id="tabs.news"/>
                        </NavLink>
                        <NavLink to={`${url}/shares`} className="tabs__item"
                                activeClassName='tabs__item--active'>
                            <FormattedMessage id="tabs.action"/>
                        </NavLink>
                        {/* <NavLink to={`${url}/accommodation`} className="tabs__item"
                                activeClassName='tabs__item--active'>
                            <FormattedMessage id="tabs.accommodation"/>
                        </NavLink> */}
                        <NavLink to={`${url}/history`} className="tabs__item" activeClassName='tabs__item--active'>
                            <FormattedMessage id="tabs.history"/>
                        </NavLink>
                    </nav>
                    <div className="tabs__body">
                        <Switch>
                            {
                                routes.map(route => {
                                    let path = `${url}${route.path}`;
                                    let Component = route.component;
                                    let componentProps = route.props;

                                    return <Route
                                        key={route.path}
                                        path={path}
                                        exact
                                        render={
                                            routeProps => {
                                                return <Component {...routeProps} componentProps={componentProps}
                                                                school_id={school_id} />
                                            }
                                        }
                                    />
                                })
                            }

                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Tab;
import { useEffect, useState } from "react";
import SchoolProgramService from "../../../services/SchoolProgramService";
import CkeditorField from "../../AccountFields/CkeditorField";
import ComplexField from "../../AccountFields/ComplexField";
import DefaultRangeField from "../../AccountFields/DefaultRangeField";
import RadioField from "../../AccountFields/RadioField";
import FormFooter from "../../Forms/Footer/FormFooter";
import RenderField from "../../AccountFields/RenderField";
import ComplexSelectField from "../../AccountFields/ComplexSelectField";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useTranslations } from "../../../context/LanguageContext";

function useStateCf() {
    let [model, setModel] = useState(null);

    let cf = (field, value, innerField = 'value') => {
        let newModel = { ...model };
        newModel[field][innerField] = value;

        setModel(newModel);
    }
    return [model, setModel, cf];
}


function LoadProgram(school_id, program_id) {

    let [loaded, setLoaded] = useState(false);
    let [model, setModel, changeField] = useStateCf({});

    useEffect(() => {
        (async () => {
            let spService = new SchoolProgramService();
            let _model = await spService.loadProgramData(school_id, program_id);
            setModel(_model);
            setLoaded(true);
        })();
    }, []);

    return [loaded, model, setModel, changeField];
}

function ProgramManager(props) {
    let { school_id } = props;
    let program_id = props.match.params.program_id;
    let [loaded, model, setModel, changeField] = LoadProgram(school_id, program_id);
    let history = useHistory();
    let t = useTranslations();

    if (!loaded) {
        return <div>Loading...</div>
    }
    

    function getMultipleFieldData(fieldNames, lang, flag) {
        let data = [];
        fieldNames.forEach((item) => {
            data.push({
                field: item,
                value: model[item].value,
                language: lang || '',
                flag: flag || ''
            });
        })
        return data;
    }

    function getSingleFieldData(fieldName, lang, flag) {
        let data = [];
        data.push(
            {
                field: fieldName,
                value: model[fieldName].value,
                language: lang || '',
                flag: flag || ''
            }
        )
        return data;
    }

    function updateModelMulti(response, fieldNames) {
        let newModel = { ...model };
        for (const [key, value] of Object.entries(response)) {
            newModel[key] = value;
        }

        if (Array.isArray(fieldNames)) {
            if (_.size(response) === 1) {
                let fieldNameFromResponse = Object.keys(response);
                let status = newModel[fieldNameFromResponse].status;
                fieldNames.foreach((item, index) => {
                    if (newModel[item].status !== status) {
                        newModel[item].status = status;
                    }
                })
            }
        }

        setModel(newModel)
    }

    async function sendEpRequest(fieldNames, lang) {
        let data;
        if (Array.isArray(fieldNames)) {
            data = getMultipleFieldData(fieldNames, lang);
        } else {
            data = getSingleFieldData(fieldNames, lang);
        }
        let spService = new SchoolProgramService();
        let response = await spService.updateEpUr(school_id, program_id, data);
        updateModelMulti(response, fieldNames);
    }

    async function manageEpRequest(fieldNames, flag, lang) {
        let newFieldValues;
        if (Array.isArray(fieldNames)) {
            newFieldValues = getMultipleFieldData(fieldNames, lang, flag)
        } else {
            newFieldValues = getSingleFieldData(fieldNames, lang, flag)
        }

        let spService = new SchoolProgramService();
        let response = await spService.manageEpUr(school_id, program_id, newFieldValues);
        updateModelMulti(response, fieldNames);
    }

    

    let fields = [
        {
            component: ComplexSelectField,
            tip: t("programs.tip.name"),
            name: 'category_id',
            title: t("table.name"),
        },
        {
            component: CkeditorField,
            tip: t("programs.tip.description"),
            name: 'translations-{lang}-description',
            title: t("table.description"),
        },
        {
            component: ComplexField,
            tip: t("programs.tip.duration"),
            name: 'duration',
            addName: 'duration_type_id',
            title: t("table.programs.duration"),
        },
        {
            component: ComplexField,
            tip: t("programs.tip.price"),
            name: 'price',
            addName: 'price_type_id',
            title: t("table.programs.price"),
        },
        {
            component: DefaultRangeField,
            tip: t("programs.tip.age"),
            name: 'age_from',
            addName: 'age_to',
            title: t("table.programs.age"),
        },
        {
            component: RadioField,
            tip: t("programs.tip.accommodation"),
            name: 'price_accommodation',
            title: t("table.programs.accommodation"),
            options: [
                {
                    value: 1,
                    label: t("table.programs.accommodation.yes"),
                },
                {
                    value: 0,
                    label: t("table.programs.accommodation.no"),
                },
            ]
        },
        {
            component: RadioField,
            tip: t("programs.tip.working_time"),
            name: 'annual',
            title: t("table.programs.working_time"),
            options: [
                {
                    value: 1,
                    label: t("table.programs.working_time.year"),
                },
                {
                    value: 0,
                    label: t("table.programs.working_time.temporaryly"),
                },
            ]
        },
    ];

    return <>
        <div className="back-button" onClick={() => history.goBack()}>
            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 1L2 5L6 9" stroke="#C4C4C4" strokeWidth="2" />
            </svg>
            <button>{t("breadcrumb.programs")}</button>
        </div>
        {
            fields.map(
                field => {
                    return <RenderField key={field.name}
                        model={model}
                        field={field}
                        save={sendEpRequest}
                        handleUserRequest={manageEpRequest}
                        onChange={changeField}
                    />

                }
            )
        }
        <FormFooter state="delete" />
    </>
}

export default ProgramManager;
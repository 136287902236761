import { useLanguage } from "../../context/LanguageContext";

function SideLangSwitcher(props) {

    let [language, setLanguage] = useLanguage()

    return <div className="lang-switcher">
        <select className="lang-switcher__button" value={language} onChange={event => setLanguage(event.target.value)}>
            <option className="lang-switcher__button" value="ru" >In Russian</option>
            <option className="lang-switcher__button" value="en" >In English</option>
        </select>
    </div>
}

export default SideLangSwitcher;
import React from 'react'

/**
 * Can receive size prop as string: "small"|"big"
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function CircleChart(props) {

    let size = props.size || 'small';
    let className = size === 'small' ? "single-chart single-chart--small" : "single-chart";

    return <div className={className}>
        <svg viewBox="0 0 36 36" className="circular-chart base">
            <path className="circle-bg"
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path className="circle"
                  strokeDasharray={props.percentage + ',100'}
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <text x="18" y="20.35"
                  className="percentage">{props.percentage + '%'}</text>
        </svg>
    </div>

}

export default CircleChart;
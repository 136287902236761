import React, {useEffect, useState} from "react";
import { FormattedMessage } from "react-intl";
import LoginForm from "../../components/Forms/LoginForm";
import Notification from "../../components/Notification/Notification";
import {redirects} from "../../constants/redirects";


function Login(props) {
    let [showNotify, setShowNotify] = useState(false);
    let redirected = props.location.state;
    let showSuccessToast = () => {
        return showNotify ? <Notification status="error"
                                        text={<FormattedMessage id = "login.error"/>}
                                        isShow={showNotify}/> : null;
    }

    useEffect(() => {
    if (redirected) {
        if (redirected.referrer === redirects.UNAUTHENTICATED){
            setShowNotify(true)
            setTimeout(() => {
                setShowNotify(false);
            }, 3000)
        }

        }
    }, [redirected])

    return <div className="main-container">
        {showSuccessToast()}
        <LoginForm/>
    </div>
}

export default Login;
import React, { Component, useContext, useEffect, useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';

import AsyncSelect from 'react-select/async';
import SchoolService from "../../services/SchoolService";


const customStyles = {
    control: () => ({
        padding: '4px 0',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#888888',
        paddingRight: '4px',
        paddingLeft: '11px',
    }),
    indicatorsContainer: (provided, state) => {

        return { display: 'none' };
    }
}



function DefaultSelect(props) {
    let { options, value, difference, onChange, isDisabled, diffColor, language } = props;
    let [localValue, setLocalValue] = useState([]);

    function convertToArray(arr) {
        return arr.map(function (subject) {
            if (subject)
                return { id: subject.id, name: subject['translations'][language].name }
        })
    }

    function idToObject(ids) {
        return convertToArray(ids.map(index => options[index]));
    }


    difference = difference || { added: [], removed: [] }

    useEffect(() => {
        let arrValue = idToObject(value);

        if (difference.removed) {
            arrValue = [...arrValue, ...idToObject(difference.removed)]
        }
        setLocalValue(arrValue)
    }, [language, value])

    let localOnChange = (newValues) => {
        let newValue = newValues[Object.keys(newValues)[Object.keys(newValues).length - 1]];
        if(newValue != undefined) {
            Object.assign(options, { [newValue.id]: { id: newValue.id, translations: { [language]: { name: newValue.name } } } } );
        }
        // почему options такой?
        onChange('subjects', newValues.map(item => item.id), 'value');
    }

    let loadOptions = async (inputName) => {
        let schoolService = new SchoolService();
        let response = await schoolService.searchSubject(inputName, language);
        if (response.name === "No subjects found") {
            // Empty result, return an empty array instead of a string
            return [];
        }
        return response;
    }

    let localCustomStyles = {
        ...customStyles,
        multiValueLabel: (provided, object) => {
            let objectId = object.data.id;
            let removed = difference.removed.indexOf(objectId) !== -1
            let added = difference.added.indexOf(objectId) !== -1
            return {
                ...provided,
                color: removed ? '#888888' : '',
                background:
                    removed ? diffColor
                        : added ? diffColor : '',
                textDecoration: removed ? 'line-through' : '',
            }
        },

        multiValueRemove: (provided, object) => {
            let objectId = object.data.id;
            let removed = difference.removed.indexOf(objectId) !== -1
            let added = difference.added.indexOf(objectId) !== -1
            return {
                color: removed ? '#888888' : '',
                background:
                    removed ? diffColor
                        : added ? diffColor : '',
            }
        },
    };

    return <AsyncSelect
        getOptionValue={item => item.id}
        getOptionLabel={item => item.name}
        noOptionsMessage={() => "Предмет не найден"}
        isDisabled={isDisabled}
        value={localValue}
        className="react-select-container"
        classNamePrefix="react-select"
        styles={localCustomStyles}
        isMulti
        onChange={localOnChange}
        loadOptions={loadOptions}
    />
}

export default DefaultSelect;



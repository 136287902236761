import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { useHistory } from "react-router-dom";
import { cloneDeep } from "lodash";
import PostService from "../../../services/PostService";
import PostForm from "../../Forms/PostForm";
import { statuses } from "../../../constants/statuses";
import { useRecoilValue } from "recoil";
import { useIsAdmin, userState } from "../../../store";
import { flags } from "../../../constants/flags";
import { FormattedMessage } from "react-intl";

let service = new PostService();

function PostManager(props) {
    let { title, school_id, category } = props
    let post_id = props.match.params.article_id
    let user = useRecoilValue(userState);
    let [model, setModel] = useState({});
    let [status, setStatus] = useState(null);
    let [statusFromApi, setStatusFromApi] = useState();
    let languageContext = useContext(LanguageContext);
    let history = useHistory();

    useEffect(() => {
        (async () => {
            let response = await service.getPost(school_id, post_id);
            setModel(response);
            setStatusFromApi(response.initialRenderStatus)
            if (status === null) {
                setStatus(response.status)
            }
        })();
    }, []);

    let changeField = (field, newValue) => {
        let newModel = cloneDeep(model);
        newModel[field] = newValue;
        setModel(newModel);
    }

    let isDisabled = () => {
        switch (status) {
            case statuses.STATUS_POST_MODERATION:
                return true
            case statuses.STATUS_INITIAL:
                return true
            default:
                return false;
        }
    }

    let [errors, setErrors] = useState({});

    let create = async (e) => {
        e.preventDefault();
        setErrors({});

        let newModel = cloneDeep(model);
        newModel['category'] = category;
        newModel['language'] = languageContext.language;

        let response = await service.createNewPostRequest(school_id, newModel);

        if (response.errors) {
            setErrors(response.errors);
        } else {
            history.push(`/institutions/${school_id}/articles`);
        }
    }

    let apply = async () => {
        let response = await service.applyRequest(school_id, post_id, model);
        setModel(response);
        history.goBack()
    }

    let update = async () => {
        let newModel = {
            id: model.id,
            name: model.name,
            description: model.description,
            announcement_description: model.announcement_description,
            language: languageContext.language,
            category: category,
            image: {
                id: model.image.id,
                path: model.image.path,
            },
        }
        let response = await service.updateRequest(school_id, post_id, newModel);
        setModel(response)
        setStatus(response.status)
    }

    let reject = async () => {
        let newModel = {
            category: category,
            flag: flags.REJECT
        }
        let response = await service.withdrawRequest(school_id, post_id, newModel);
        setModel(response)
        history.push(`/institutions/${school_id}/articles`);
        // здесь должно быть что-то другое
        setStatus(response.status)
    }

    let withDraw = async () => {
        let response = await service.withdrawRequest(school_id, post_id, { category: category });
        setModel(response)
        setStatus(response.status)
        history.push(`/institutions/${school_id}/articles`);
    }

    let changeStatus = (value) => {
        setStatus(value)
    }

    let buttonActions = {
        create,
        reject,
        apply,
        update,
        withDraw,
        edit: () => {
            return changeStatus(statuses.STATUS_EDIT);
        },
        userEdit: () => {
            return changeStatus(statuses.STATUS_USER_EDIT)
        },
        managerEdit: () => {
            return changeStatus(statuses.STATUS_MANAGER_EDIT)
        },
        cancel: () => {
            changeStatus(statusFromApi)
        },
        default: (e) => {
            e.preventDefault();
        }
    }

    let isAdmin = useIsAdmin();

    // описание кнопок
    let buttons = [
        {
            label: <FormattedMessage id="button.change" />,
            action: buttonActions.managerEdit,
            color: "red",
            show: status === statuses.STATUS_INITIAL && isAdmin,
        },
        {
            label: <FormattedMessage id="button.change" />,
            action: buttonActions.userEdit,
            color: "red",
            show: status === statuses.STATUS_INITIAL && !isAdmin,
        },
        {
            label: <FormattedMessage id="button.withdraw"/>,
            action: buttonActions.withDraw,
            color: "white",
            show: status === model.isDeleteRequested && !isAdmin,
        },

        {
            label: <FormattedMessage id="button.save"/>,
            action: buttonActions.update,
            color: "red",
            show: status === statuses.STATUS_USER_EDIT,
        },
        {
            label: <FormattedMessage id="button.cancel"/>,
            action: buttonActions.cancel,
            color: "white",
            show: status === statuses.STATUS_USER_EDIT,
        },

        {
            label: <FormattedMessage id="button.save"/>,
            action: buttonActions.update,
            color: "red",
            show: status === statuses.STATUS_MANAGER_EDIT,
        },
        {
            label: <FormattedMessage id="button.cancel"/>,
            action: buttonActions.cancel,
            color: "red",
            show: status === statuses.STATUS_MANAGER_EDIT,
        },

        {
            label: <FormattedMessage id="button.apply"/>,
            action: buttonActions.apply,
            color: "red",
            show: status === statuses.STATUS_POST_MODERATION && isAdmin,
        },
        {
            label: <FormattedMessage id="button.reject"/>,
            action: buttonActions.reject,
            color: "white",
            show: status === statuses.STATUS_POST_MODERATION && isAdmin,
        },
        {
            label: <FormattedMessage id="button.change"/>,
            action: buttonActions.userEdit,
            color: "red",
            show: status === statuses.STATUS_POST_MODERATION && !isAdmin,
        },
        {
            label: <FormattedMessage id="button.withdraw"/>,
            action: buttonActions.withDraw,
            color: "white",
            show: status === statuses.STATUS_POST_MODERATION && !isAdmin,
        },
    ];

    return <PostForm
        title={title}
        onChange={changeField}
        diff={model.diff || []}
        buttonsActions={buttonActions}
        status={status ? status : statuses.STATUS_UNDEFINED}
        user={user}
        model={model}
        isDisabled={isDisabled()}
        errors={errors}
        buttons={buttons}
        school_id={school_id}
    />
}

export default PostManager;
import React, {useContext} from "react";
import NewSchoolForm from "../../components/Forms/NewSchoolForm";
import { FormattedMessage } from 'react-intl';
import { injectIntl, intlShape } from 'react-intl';
import { LanguageContext } from '../../context/LanguageContext';
import { lang } from "@here/maps-api-for-javascript";

// Страница создания новой школы

export default function InstitutionCreate() {

    let languageContext = useContext(LanguageContext);
    let language = languageContext.language;

    let tip_newSchool;
    if (language == "ru") {
        tip_newSchool = "Заполните название вашего учреждения, на нужном языке"; 
    } else if (language == "en") {
        tip_newSchool = "Fill in the name of your institution, in the desired language";
    }

    return <div className="main-container">
        <div className="heading">
            <h1><FormattedMessage id="institutions.create"/></h1>
        </div>
        <div className="heading__subtitle">
            <p><FormattedMessage id="institutions.create.subtitle"/></p>
        </div>
        <NewSchoolForm  required={ true }
                        title={ <FormattedMessage id="school.field.name" /> }
                        tip= {tip_newSchool}
                        />
    </div>
}


import { useState, useEffect } from "react";
import Logo from "../../components/Sidebar/Logo/Logo";
import SideLangSwitcher from "../../components/LangSwitcher/SideLangSwitcher";
import { FormattedMessage } from "react-intl";
import { useTranslations } from "../../context/LanguageContext";
import { Link } from 'react-router-dom';
import Notification from "../Notification/Notification";
import api from "../../api";

const RegisterForm = () => {
    let [showNotify, setShowNotify] = useState(false);
    let [showNotifyError, setShowNotifyError] = useState(false);
    let [model, setModel] = useState({});
    const [errors, setErrors] = useState({});
    let t = useTranslations()

    // ТОСТЫ
    let showSuccessToast = () => {
        return showNotify ? <Notification 
                status="success" 
                text={t("register.succes")}
                isShow={showNotify}
            /> : null;
    }
    let showErrorToast = () => {
        return showNotifyError ? <Notification 
                status="error" 
                text={t("register.error")}
                isShow={showNotifyError}
            /> : null;
    }

    // при нажатии 
    const onSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setShowNotifyError(false)
        setShowNotify(false)

        let data = await api.post("/api/register/", model);

        if (data.errors) {
            setErrors(data.errors);
            setShowNotifyError(true)
            setTimeout(() => {
                setShowNotifyError(false);
            }, 3000)
        } else {
            setShowNotify(true)
            setModel('')
        }
    }
    
    let renderError = (field) => {
        if (errors[field]) {
            return <div class="form-error"> {errors[field].join(", ")} </div>;
        }
    };

    let changeField = (field) => (e) => {
        setModel(
            Object.assign({}, model, {
                [field]: e.target.value,
            })
        );
    };

    return (
        <div className="register-section">
            {showSuccessToast()}
            {showErrorToast()}
            <div className="register-section__languages">
                <SideLangSwitcher />
            </div>
            <div className="smapse-logo">
                <Logo />
            </div>
            <div className="register-card">
                <div className="register-card__label">
                    <FormattedMessage id="register.title"/>
                </div>
                <div className="register-card__subtitle">
                    <FormattedMessage id="login.subtitle" />
                </div>
                <form onSubmit={onSubmit} className="register-form">
                    <input
                        value={model.email || ""}
                        name='email'
                        onChange={changeField("email")}
                        className="register-form__input"
                        placeholder="Email"
                        autocomplete="off"
                    />
                    {renderError('email')}
                    <input
                        value={model.password || ""}
                        type="password"
                        name='password'
                        onChange={changeField("password")}
                        className="register-form__input"
                        placeholder={t("register.input--password")}
                        autocomplete="off"
                    />
                    {renderError('password')}
                    <input
                        value={model.password_confirmation || ""}
                        type="password"
                        name='password_confirmation'
                        onChange={changeField("password_confirmation")}
                        className="register-form__input"
                        placeholder={t("register.input--password2")}
                        autocomplete="off"
                    />
                    {renderError('password_confirmation')}
                    <button className="register-form__submit btn" type="submit">
                        <FormattedMessage id="login.register" />
                    </button>
                </form>
                <div className="register-card__footer">
                    <Link to="/login">
                        <FormattedMessage id="login.enter" />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default RegisterForm;
import {uniqueName} from "../AccountFields/ComplexField";
import React from "react";

function DefaultRadio(props) {
    let {value, onChange, options, name, position, disabled, difference} = props;
    name = name || uniqueName();
    let mapPositionToClass = {
        column: 'radio-wrapper',
        row: 'radio-wrapper radio-wrapper--row'
    }

    let setDiff = (item) => {
        if (difference) {
            return item.value === parseInt(difference) ? "radio-btn__diff-label" : "radio-btn__label";
        }
        return "radio-btn__label"
    }

    let className = mapPositionToClass[position] || 'radio-wrapper'
    return <div className={className}>
        {

            options.map((item, index) => {
                let id = 'name' + item.value;
                return <div className="radio-btn" key={index}>
                    <input
                        type="radio"
                        name={'radio-options__' + name}
                        className='radio-btn__item'
                        id={id}
                        checked={value === item.value}
                        value={item.value}
                        onChange={onChange}
                        disabled={disabled}
                    />
                    <label
                        htmlFor={id}
                        className={setDiff(item)}
                    >
                        {item.label}
                    </label>
                </div>
            })
        }
    </div>
}

export default DefaultRadio;
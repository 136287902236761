import api from "../api";


class UserRequestService {

    upgradeField(field) {
        return {...field, initialRenderStatus: field['status']};
    }

    async updateUr(model) {
        let fieldData = await api.post('/api/user-request/update/', model);
        return this.upgradeField(fieldData);
    }

    async handleUr(model) {
        let fieldData = await api.post('/api/user-request/handle/', model);
        return this.upgradeField(fieldData);
    }

    async getFields() {
        let fieldData = await api.get('/api/user-request/fields/');
        return this.upgradeField(fieldData);
    }

}

export default UserRequestService;
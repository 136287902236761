import React from "react";
import {statuses} from "../../constants/statuses";
import DiffFieldRenderer from "./DiffFieldRenderer";
import DefaultRadio from "../Fields/DefaultRadio";
import WrapField from "./WrapField";
import { FormattedMessage } from "react-intl";
import { useIsAdmin } from "../../store";
import { useTranslations } from "../../context/LanguageContext";

export default function RadioField(props) {
    let {
        status,
        value,
        onChange,
        difference,
        fieldName,
        initialValue,
        author,
        user,
        radioOptions
    } = props;

    let input = "";
    let subtitle = "";
    let inputTitle = "";
    let className = "diff-text diff-text--user"
    let t = useTranslations();

    let isAdmin = useIsAdmin();

    let render = {
        [statuses.STATUS_MANAGER_MODERATION]: () => {
            if (isAdmin) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            } else {
                subtitle = <FormattedMessage id = "general.setToModerator"/>
            }

            input = <DiffFieldRenderer
                className={className}
                direction={'vertical'}
                initialValue={parseInt(initialValue) || 0}
                difference={difference}
                options={radioOptions}
                type='radio'
                value={parseInt(value)}
            />
        },
        [statuses.STATUS_USER_MODERATION]: () => {
            if (isAdmin) {
                subtitle = t("general.setToUser")
                className = "diff-text diff-text--admin"
            } else {
                subtitle = t("general.ModeratorSuggestsChanges")
                className = "diff-text diff-text--admin"
            }

            input = <DiffFieldRenderer
                className={className}
                direction="vertical"
                initialValue={parseInt(initialValue) || 0}
                difference={difference}
                options={radioOptions}
                type='radio'
                value={parseInt(value)}
            />
        },
        [statuses.STATUS_USER_EDIT]: () => {
            subtitle = <FormattedMessage id = "general.setToModerator"/>
            inputTitle = <FormattedMessage id ="general.change"/>
            input = <DefaultRadio
                value={parseInt(value)}
                position={props.position}
                options={radioOptions}
                defaultChecked={parseInt(value)}
                onChange={e => onChange(fieldName, parseInt(e.target.value))}
                disabled={false}
            />
        },
        [statuses.STATUS_MANAGER_EDIT]: () => {
            subtitle = t("general.setToUser")
            if (author.id !== user.model.id) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            }
            inputTitle = <FormattedMessage id ="general.change"/>
            input = <DefaultRadio
                value={value}
                position={props.position}
                options={radioOptions}
                defaultChecked={value}
                onChange={e => onChange(fieldName, parseInt(e.target.value))}
                disabled={false}
            />
        },
        [statuses.STATUS_EDIT]: () => {
            input = <DefaultRadio
                value={value}
                position={props.position}
                options={radioOptions}
                defaultChecked={initialValue}
                onChange={e => onChange(fieldName, parseInt(e.target.value))}
                disabled={false}
            />
        },
        [statuses.STATUS_INITIAL]: () => {
            input = <DefaultRadio
                value={parseInt(initialValue) || 0}
                position={props.position}
                options={radioOptions}
                defaultChecked={parseInt(initialValue) || 0}
                onChange={e => onChange(fieldName, parseInt(e.target.value))}
                disabled={true}
            />
        }
    }

    if (render[status]) {
        render[status]();
    } else {
        input = <DefaultRadio
            value={value}
            position={props.position}
            options={radioOptions}
            defaultChecked={initialValue}
            onChange={e => onChange(fieldName, parseInt(e.target.value))}
        />
    }

    return <WrapField {...props}
                    inputPart={input}
                    subtitle={subtitle}
                    inputTitle={inputTitle}
                    langSwitch={true}
                    status={status}
    />
}
import FormSection from "../Forms/FormSection";
import React from "react";
import RenderButtons from "../Buttons/RenderButtons";
import createGalleryButtons from "../Buttons/createGalleryButtons";
import { useIsAdmin } from "../../store";


function WrapGallery(props) {
    let {
        inputPart,
        status,
        user,
        buttonActions,
        loading
    } = props;

    let isAdmin = useIsAdmin();

    return <FormSection {...props} langSwitch={true}>
        {inputPart}
        <RenderButtons buttons={createGalleryButtons(buttonActions, status, isAdmin)} loading={loading}/>
    </FormSection>
}

export default WrapGallery;
import React from "react";
import DropZone from "../DropZone/DropZone";
import pdf from '../../assets/pdf.jpg'
import doc from '../../assets/doc.png'
import file from '../../assets/file.png'
import xls from '../../assets/xls.png'

/**
 * Компонент выводит список Account School Materials
 */
function ShowFiles(props) {
    let {model, markAsDeleted, showDropZone} = props;

    let {
        showDeletions = false,
        managed = false,
    } = props;

    function getFileExtension(filename) {
        return filename.split('.').pop();
    }

    function truncate(str) {
        return str.length > 40 ? str.substring(0, 39) + "..." : str;
    }

    let mapImageToExtension = {
        xls : xls,
        doc : doc,
        pdf : pdf,
        default: file
    }

    return <>
        <div className="gallery">
            {
                model.map(
                    (item, index) => {
                        let classNames = ['image-wrap'];
                        let src = mapImageToExtension[getFileExtension(item.name)] || mapImageToExtension.default
                        if (showDeletions && item.mark_delete) {
                            classNames.push('image-wrap--deleted');
                        }
                        if (item.mark_added) {
                            classNames.push('image-wrap--added');
                        }

                        return (
                            <div className="gallery__item" key={item.name + index}>
                                <div className="button-wrap">
                                    <div className={classNames.join(' ')}>
                                        <img className="gallery-image"
                                            src={src}
                                            alt={src}/>
                                    </div>
                                    {
                                        managed &&
                                        <button style={{cursor: 'pointer'}} onClick={() => markAsDeleted(item)}>
                                            <svg width="8" height="8" viewBox="0 0 8 8" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 7L4 4M4 4L1 7M4 4L7 1M4 4L1 1" stroke="white"
                                                strokeWidth="2"/>
                                            </svg>
                                        </button>
                                    }
                                </div>

                                <span>
                    {truncate(item.name)}
                </span>
                            </div>
                        );
                    })}
        </div>
        {showDropZone ? <DropZone {...props}/> : null}
    </>
}

export default ShowFiles;

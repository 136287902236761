import api from "../api";

class MediaService {
    // Добавляем инициализицонный статус
    upgradeField(model) {
        return {...model, initialRenderStatus: model['status']};
    }

    getMediaUrl(school_id) {
        return `/api/school/${school_id}/media/`;
    }

    async getData(school_id) {
        let mediaData = await api.get(this.getMediaUrl(school_id));
        return this.upgradeField(mediaData)
    }

    async markDeleted(school_id, media_id) {
        return await api.get(`/api/school/${school_id}/media/${media_id}/mark-deleted/`);
    }

    async uploadFiles(school_id, file) {
        let mediaData = await api.upload(this.getMediaUrl(school_id), file);
        return this.upgradeField(mediaData);
    }

    async uploadFilesByUrl(school_id, mediaObject) {
        let mediaData = await api.post(this.getMediaUrl(school_id), mediaObject);
        return this.upgradeField(mediaData);
    }

    async createNewOrderRequest(school_id, mediaWithNewOrder) {
        return await api.post(`/api/school/${school_id}/media/change-order/`, mediaWithNewOrder);
    }

    async withdrawRequests(school_id, markDeleteMedia) {
        return await api.post(`/api/school/${school_id}/media/withdraw/`, markDeleteMedia)
    }

    async changeMainPhotoRequest(school_id, media_id) {
        return await api.get(`/api/school/${school_id}/media/${media_id}/change-main/`)
    }

    async applyAllUrs(school_id, markDeleteMedia) {
        return await api.post(`/api/school/${school_id}/media/apply/`, markDeleteMedia)
    }
}

export default MediaService;
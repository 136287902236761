import React, {useEffect, useRef, useState} from "react";
import { useTranslations } from "../../context/LanguageContext";

 // Инпут для отпрафки файла по ссылке */

function UploadLink(props) {
    let {hide, onChange, errors, loaded} = props
    let [value, setValue] = useState('');
    let t = useTranslations();

    let showError = () => {
        return errors ? <div className="form-error">{errors}</div> : null;
    }

    let upload = async () => {
        await onChange(value)
        setTimeout(() => {
            if (loaded) {
                if (errors.length === 0) {
                    hide()
                }
            }
        }, 0)
    }

    return <div className="drop-zone__link-wrap upload-link">
        <input type="text" name="path" className="upload-link__input" value={value}
            onChange={e => setValue(e.target.value)}/>
        {showError()}
        <div className="cross-wrap">
            <div className="tip-wrap">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 6.94942 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0ZM8.8 11.2C8.8 11.4122 8.71572 11.6157 8.56569 11.7657C8.41566 11.9157 8.21218 12 8 12C7.78783 12 7.58435 11.9157 7.43432 11.7657C7.28429 11.6157 7.2 11.4122 7.2 11.2V7.2C7.2 6.98782 7.28429 6.78434 7.43432 6.63431C7.58435 6.48428 7.78783 6.4 8 6.4C8.21218 6.4 8.41566 6.48428 8.56569 6.63431C8.71572 6.78434 8.8 6.98782 8.8 7.2V11.2ZM8 5.6C7.84178 5.6 7.68711 5.55308 7.55555 5.46517C7.42399 5.37727 7.32145 5.25233 7.2609 5.10615C7.20035 4.95996 7.18451 4.79911 7.21537 4.64393C7.24624 4.48874 7.32243 4.3462 7.43432 4.23431C7.5462 4.12243 7.68875 4.04624 7.84393 4.01537C7.99911 3.9845 8.15997 4.00035 8.30615 4.06089C8.45233 4.12145 8.57727 4.22398 8.66518 4.35554C8.75308 4.4871 8.8 4.64177 8.8 4.8C8.8 5.01217 8.71572 5.21565 8.56569 5.36568C8.41566 5.51571 8.21218 5.6 8 5.6Z"
                    fill="#AB2F52"/>
                </svg>
                <span className="tip-wrap__text">{t("tabs.media.add--six")}<br/>
                    {t("tabs.media.add--seven")}</span>
            </div>
            <button className="tip-wrap__submit" onClick={upload}>{t("tabs.media.add--eight")}</button>
        </div>
        <svg onClick={hide} className="cross-wrap__image" width="14" height="14" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M13 13L7 7M7 7L1 13M7 7L13 1M7 7L1 1" stroke="#888888"/>
        </svg>
    </div>
}

// /* Дроп зона для прикрепления картинок */

export default function DropZone(props) {
    let t = useTranslations();
    let {
        handleMedia,
        withFile,
        withUrl,
    } = props
    let [showUpload, setShowUpload] = useState(false);

    let inputEl = useRef(null);

    let clickInput = () => {
        if (inputEl.current) {
            inputEl.current.click();
        }
    }

    useEffect(() => {
        if (inputEl.current) {
            let eventListenerHandler = function (e) {
                withFile(e.target.files[0])
                e.target.value = null;
            }
            inputEl.current.removeEventListener('change', eventListenerHandler);
            inputEl.current.addEventListener('change', eventListenerHandler);
        }
    }, [inputEl]);

    // показ и скрытие инпута при вставке картинки по url 
    let show = () => {
        setShowUpload(true)
    }

    let hide = () => {
        setShowUpload(false)
    }

    // let handleDrop = (ev) => {
    //     ev.preventDefault();
    //     if (ev.dataTransfer.items) {
    //         for (let i = 0; i < ev.dataTransfer.items.length; i++) {
    //             if (ev.dataTransfer.items[i].kind === 'file') {
    //                 let file = ev.dataTransfer.items[i].getAsFile();
    //                 if (file) {
    //                     withFile(file)
    //                 }
    //             }
    //         }
    //     } else {
    //         for (let i = 0; i < ev.dataTransfer.files.length; i++) {
    //         }
    //     }
    // }

    // {/* Убираем дефолтное поведение при перетаскивании файла
    //  (дефолтное - открывание его в браузере)*/
    // }

    // function dragOverHandler(ev) {
    //     ev.preventDefault();
    // }

    const [drag, setDrag] = useState(false)

    function dragStartHandler (e) {
        e.preventDefault()
        setDrag(true)
    }

    function dragLeaveHandler (e) {
        e.preventDefault()
        setDrag(false)
    }

    function onDropHandler (e) {
        e.preventDefault()
        let files = [...e.dataTransfer.files]
        files.map(function (item) {
            withFile(item)
        })

        setDrag(false)
    }

    return <>
        <div 
            // onDrop={handleDrop} 
            // onDragOver={dragOverHandler} 
            className="drop-zone"
            onDragStart={e => dragStartHandler(e)}
            onDragLeave={e => dragLeaveHandler(e)}
            onDragOver={e => dragStartHandler(e)}
            onDrop={e => onDropHandler(e)}
        >
            {showUpload ? <UploadLink {...props} onChange={withUrl} hide={hide}/> : null}
            <input id="upload" type="file" name="file" className="drop-zone__input" ref={inputEl}/>
            <div onClick={clickInput}>
                <svg id="closeUpload" width="43" height="43" viewBox="0 0 43 43" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M35.5263 18.2632V13.9474C35.5263 12.8028 35.0716 11.705 34.2623 10.8956C33.4529 10.0863 32.3551 9.63158 31.2105 9.63158H13.9474C12.8028 9.63158 11.705 10.0863 10.8956 10.8956C10.0863 11.705 9.63158 12.8028 9.63158 13.9474V31.2105C9.63158 32.3551 10.0863 33.4529 10.8956 34.2623C11.705 35.0716 12.8028 35.5263 13.9474 35.5263H18.2632M1 1V1.02158M9.63158 1V1.02158M18.2632 1V1.02158M26.8947 1V1.02158M1 9.63158V9.65316M1 18.2632V18.2847M1 26.8947V26.9163M22.5789 22.5789L42 29.0526L33.3684 33.3684L29.0526 42L22.5789 22.5789Z"
                    stroke="#AB2F52" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            { drag 
            ? <div className="upload--files"
                >{t("tabs.media.add--five")}</div> 
            : <div className="upload--files"
            >{t("tabs.media.add--one")}
                <span onClick={clickInput}> {t("tabs.media.add--two")} </span>
                {t("tabs.media.add--three")}
                <span onClick={show}> {t("tabs.media.add--four")} </span>
            </div>}
        </div>


    </>
}
import React, {useState} from "react";
import Select from "react-select";

const customStyles = {
    control: () => ({
        display: 'flex',
        width: '100%',
        padding: '10px 0',
        border: '1px solid #DEDEDE;',
        marginBottom: '10px'
    }),
    indicatorsContainer: () => {
        return {
            display: 'none',
        };
    },
}


function MultiLevelSelect(props) {
    let {value, options, onChange, language, isDisabled} = props;

    let lOptions = options.map(item => {
        return {
            id: item[0],
            lvl: item[1],
            name: language === 'ru' ? item[2] : item[3]
        };
    })

    let selected = lOptions.find((a) => a.id === value)


    let localCustomStyles = {
        ...customStyles,
        option: (provided, object) => {
            switch (object.data.lvl) {
                case 0 :
                    return {
                        paddingLeft: '10px',
                        color: '#ccc',
                        textTransform: 'uppercase',
                        marginBottom: '5px',
                    }
                case 1 :
                    return {
                        paddingLeft: '40px',
                        color: '#ccc',
                        marginBottom: '5px',
                        textTransform: ''
                    }
                default :
                    return {
                        paddingLeft: '80px',
                        color: '#333333'
                    }
            }
        },
    }

    return <Select
        getOptionValue={item => item.id}
        getOptionLabel={item => item.name}
        isOptionDisabled={option => option.lvl !== 2}
        defaultValue={selected}
        className="program-options"
        classNamePrefix="programs"
        value={selected}
        options={lOptions}
        styles={localCustomStyles}
        onChange={onChange}
        isDisabled={isDisabled}
    />

}

export default MultiLevelSelect;
import React  from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

function Loading() {
    return <div className="lds-ring">
        <div/>
        <div/>
        <div/>
        <div/>
    </div>
}


function CustomModal(props) {
    let {close, isOpen, className = '', loading} = props;
    return (
        <Modal
            onRequestClose={close}
            isOpen={isOpen}
            className={className}
            overlayClassName="Overlay"
        >
            {loading ? <Loading/> : null}
            {props.children}
        </Modal>
    );
}

export default CustomModal;


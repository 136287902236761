import React from "react";
import SanitizeHTML from "sanitize-html";

export const SafeHTML = ({ className = "", style, children }) => {
    const unsafe = React.Children.toArray(children)
        .filter((child) => typeof child === "string")
        .join("");
    let escaped = SanitizeHTML(unsafe, {
        allowedTags: [
            "blockquote",
            "p",
            "a",
            "ul",
            "ol",
            "li",
            "b",
            "i",
            "strong",
            "em",
            "strike",
            "del",
            "br",
            "div",
            "sup",
            "sub",
            "table",
            "td",
            "ins"
        ],
        allowedAttributes: {
            a: ["href", "name", "target",],
            table: ["class"],
            tr: ["class"],
            td: ["class"],
            img: ["src"],
        },
        // Lots of these won't come up by default because we don't allow them
        selfClosing: [
            "img",
            "br",
            "hr",
            "area",
            "base",
            "basefont",
            "input",
            "link",
            "meta",
        ],

        // URL schemes we permit
        allowedSchemes: ["http", "https", "ftp", "mailto"],
        allowedSchemesByTag: {},

        // Фильтр, убирающий таг с начальным значением.
        exclusiveFilter: function(frame) {
            return frame.tag === 'td' && frame.attribs.class === "diff-deletedline";
        }
    });
    return (
        <div
            className={className}
            style={style}
            dangerouslySetInnerHTML={{
                __html: escaped,
            }}
        />
    );
};
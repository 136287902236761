import React, {useEffect, useState} from "react";
import {Redirect, Route} from "react-router-dom";
import {getUserToken, setUserToken} from "../../helpers";
import api from "../../api";
import {set} from "lodash/object";
import {useRecoilState} from "recoil";
import {userTokenState} from "../../store";
import {redirects} from "../../constants/redirects";

//Редиректим на логин, если нет токена. Значит не залогинен.

function ProtectedRoute({component: Component, ...restOfProps}) {
    let [routeState, setRouteState] = useState(true);
    let [token, setToken] = useRecoilState(userTokenState);

    useEffect(()=>{
        (async () => {
        setToken(getUserToken());
        let localToken = getUserToken();
        if (token) {
            if (localToken === token) {
                let response = await api.get('/api/user/info/');
                setRouteState(response.hasOwnProperty('id'));
            } else {
                setRouteState(false);
            }
        } else {
            setRouteState(false);
        }
    })();
    }, [Component])


    return (
        <Route
            {...restOfProps}
            render={(props) =>
                routeState ? <Component {...props} /> : <Redirect
                    to={{
                        pathname: "/login",
                        state: {referrer: redirects.UNAUTHENTICATED}
                    }}
                />
            }
        />
    );
}

export default ProtectedRoute;
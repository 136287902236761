import React from "react";
import {statuses} from "../../constants/statuses";
import {FormattedMessage} from "react-intl";

function createButtons(actions, status, is_admin) {
    let buttons = [];

    if (status === statuses.STATUS_INITIAL) {
        buttons.push(
            {
                label: <FormattedMessage id="button.change"/>,
                action: actions.edit,
                color: "red",
            }
        )
    }

    if (status === statuses.STATUS_EDIT) {
        if (is_admin == true) {
            buttons.push(
                {
                    label: <FormattedMessage id="button.save"/>,
                    action: actions.save,
                    color: "red"
                },
                {
                    label: <FormattedMessage id="button.accept"/>,
                    action: actions.forceAccept,
                    color: "white",
                },
                {
                    label: <FormattedMessage id="button.cancel"/>,
                    action: actions.cancel,
                    color: "white",
                },
            )
        } else {
            buttons.push(
                {
                    label: <FormattedMessage id="button.save"/>,
                    action: actions.save,
                    color: "red"
                },
                {
                    label: <FormattedMessage id="button.cancel"/>,
                    action: actions.cancel,
                    color: "white",
                }
            )
        }
    }
    if (status === statuses.STATUS_USER_EDIT) {
        buttons.push(
            {
                label: <FormattedMessage id="button.save"/>,
                action: actions.save,
                color: "red",
            },
            {
                label: <FormattedMessage id="button.cancel"/>,
                action: actions.cancel,
                color: "white",
            },
        )
    }
    if (status === statuses.STATUS_MANAGER_EDIT) {
        buttons.push(
            {
                label: <FormattedMessage id="button.save"/>,
                action: actions.save,
                color: "red"
            },
            {
                label: <FormattedMessage id="button.accept"/>,
                action: actions.forceAccept,
                color: "white",
            },
            {
                label: <FormattedMessage id="button.cancel"/>,
                action: actions.cancel,
                color: "white",
            },
        )
    }

    if (status === statuses.STATUS_MANAGER_MODERATION) {
        if (is_admin == true) {
            buttons.push(
                {
                    label: <FormattedMessage id="button.apply"/>,
                    action: actions.apply,
                    color: "red"
                },
                {
                    label: <FormattedMessage id="button.change"/>,
                    action: actions.managerEdit,
                    color: "white",
                },
                {
                    label: <FormattedMessage id="button.reject"/>,
                    action: actions.reject,
                    color: "white",
                },
            )
        } else {
            buttons.push(
                {
                    label: <FormattedMessage id="button.change"/>,
                    action: actions.userEdit,
                    color: "red"
                },
                {
                    label: <FormattedMessage id="button.withdraw"/>,
                    action: actions.withDraw,
                    color: "white",
                    disabled: true,
                },
            )
        }
    }

    if (status === statuses.STATUS_USER_MODERATION) {
        if (is_admin) {
            buttons.push(
                {
                    label: <FormattedMessage id="button.change"/>,
                    action: actions.managerEdit,
                    color: "red"
                },
                {
                    label: <FormattedMessage id="button.withdraw"/>,
                    action: actions.withDraw,
                    color: "white",
                },
            )
        } else {
            buttons.push(
                {
                    label: <FormattedMessage id="button.apply"/>,
                    action: actions.apply,
                    color: "red"
                },
                {
                    label: <FormattedMessage id="button.change"/>,
                    action: actions.userEdit,
                    color: "white",
                },
                {
                    label: <FormattedMessage id="button.reject"/>,
                    action: actions.reject,
                    color: "white",
                },
            )
        }
    }

    return buttons;

}

export default createButtons;
import React, {useRef} from "react";
import GalleryManager from "../../Gallery/GalleryManager";
import MediaService from "../../../services/MediaService";
import {FormattedMessage} from "react-intl";


function Media(props) {
    let serviceRef = useRef(new MediaService());

    let localParams = {
        title: <FormattedMessage id="tabs.media"/>,
        service: serviceRef.current,
        type: "media",
    }
    return <div className="form-container">
        <GalleryManager {...props} params={localParams}/>
    </div>
}

export default Media;
import React, {useContext, useState} from "react";
import UserService from "../../services/UserService";
import {FormattedMessage} from "react-intl";

function PasswordResetForm(props) {
    let {setToast, setText, setStatus} = props;
    const [values, setValues] = useState({
        old_password: "",
        new_password: "",
        confirm_password: ""
    })
    const [errors, setErrors] = useState({});


    let showError = (field) => {
        return errors[field] ? <div className="form-error">{errors[field].join(', ')}</div> : null;
    }

    function handleChange(e) {
        const value = e.target.value;
        setValues({
            ...values,
            [e.target.name]: value
        });
    }

    let generateToast = (state) => {
        if (state === "error") {
            setText(<FormattedMessage id = "notification.failed_to_update.password"/>)
            setStatus("error")
        } else {
            setText(<FormattedMessage id = "notification.succes_to_update.password"/>)
            setStatus("success")
        }
    }

    let onSubmit = async (e) => {
        e.preventDefault();
        setErrors({});

        let userService = new UserService();
        let data = await userService.setNewPassword(values);

        if (data.errors) {
            generateToast('error')
            setErrors(data.errors);
        } else {
            generateToast('success')
            setValues({
                old_password: "",
                new_password: "",
                confirm_password: ""
            });
        }
        setToast(true);

        setTimeout(() => {
            setToast(false);
        }, 3000)
    }

    return <div className="form-section">
        <div className="content-block">
            <div className="form-container__title">
                <FormattedMessage id="settings.password.title"/>
            </div>
        </div>
        <form onSubmit={onSubmit}>
            <div className="input-container input-container--base">
                <div className="input">
                    <div className="input__title">
                        <p>
                            <FormattedMessage id="settings.password.old"/>
                        </p>
                    </div>
                    <div className="input input--base">
                        <input
                            type="password"
                            className="input__area input__area--base"
                            name="old_password"
                            value={values.old_password}
                            onChange={handleChange}
                        />
                        {showError('old_password')}
                    </div>
                </div>
            </div>
            <div className="input-container input-container--base">
                <div className="input">
                    <div className="input__title">
                        <p>
                            <FormattedMessage id="settings.password.new"/>
                        </p>
                    </div>
                    <div className="input input--base">
                        <input
                            type="password"
                            className="input__area input__area--base"
                            name="new_password"
                            value={values.new_password}
                            onChange={handleChange}
                        />
                        {showError('new_password')}
                    </div>
                </div>
            </div>
            <div className="input-container input-container--base">
                <div className="input">
                    <div className="input__title">
                        <p>
                            <FormattedMessage id="settings.password.repeat"/>
                        </p>
                    </div>
                    <div className="input input--base">
                        <input
                            type="password"
                            className="input__area input__area--base"
                            name="confirm_password"
                            value={values.confirm_password}
                            onChange={handleChange}
                        />
                        {showError('confirm_password')}
                    </div>
                </div>
            </div>
            <div className="button-save button-save--small">
                <button type="submit">
                    <FormattedMessage id="button.change"/>
                </button>
            </div>
        </form>
    </div>
}

export default PasswordResetForm;

export function getUserToken() {
    return window.localStorage.getItem('token');
}

export function setUserToken(token) {
    window.localStorage.setItem('token', token);
}

export function getSchoolModel() {
    let value =  window.localStorage.getItem('schoolModel');
    return JSON.parse(value);
}


// Везде, где встречается это закоменчено, стоит удалить наверное.

// export function setSchoolModel(model) {
//     window.localStorage.setItem('schoolModel', JSON.stringify(model));
// }
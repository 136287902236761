import React, { useEffect, useRef, useState } from "react";
import { statuses } from "../../constants/statuses";
import DiffFieldRenderer from "./DiffFieldRenderer";
import WrapField from "./WrapField";
import { getTrackBackground, Range } from "react-range";
import { FormattedMessage } from "react-intl";
import { useTranslations } from "../../context/LanguageContext";
import { useIsAdmin } from "../../store";

const DEFAULT_OPTIONS = [
    {
        step: 1,
        min: 1,
        max: 100,
    },

]


function SetLabel(props) {
    let t = useTranslations();
    let { showOnBottom = false, prefix = '' } = props;

    let mapValue = {
        1: `${prefix}${props.index}` + t("programs.years"),
        100: t("programs.age.unlimited"),
    }

    let masIndexToClass = {
        1: 'dot-label dot-label--first',
        100: 'dot-label dot-label--last'
    }

    let label = mapValue[props.index] || `${prefix}${props.index}` + t("programs.yearsV2");
    let className = masIndexToClass[props.index] || 'dot-label';

    let localStyles = {
        marginTop: showOnBottom ? 36 : 0,
    }

    return <span style={localStyles} ref={props.labelRef} className={className}>{label}</span>
}


export function CustomRange(props) {
    let { options, values, onChange, disabled } = props;
    let t = useTranslations();

    let lp1 = useRef(null);
    let lp2 = useRef(null);

    let [offsetText, setOffsetText] = useState(false);

    useEffect(() => {
        if (lp1.current && lp2.current) {
            let offset = 5;
            let br1 = lp1.current.getBoundingClientRect();
            let br2 = lp2.current.getBoundingClientRect();

            if (br1.left + br1.width + offset >= br2.left) {
                setOffsetText(true);
            } else {
                setOffsetText(false);
            }

        }
    }, [lp1.current, lp2.current, values]);

    return <>
        {options.map((item, index) => {
            return <Range
                key={index}
                values={values}
                step={item.step}
                min={item.min}
                max={item.max}
                onChange={onChange}
                disabled={disabled}

                renderTrack={({ props, children }) => (
                    <div className="input-range"
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}

                    >
                        <div className="input-range__line"
                            ref={props.ref}
                            style={{
                                background: getTrackBackground({
                                    values,
                                    colors: ["#DEDEDE", "#AB2F52", "#DEDEDE"],
                                    min: item.min,
                                    max: item.max
                                }),
                            }}
                        >
                            {children}
                        </div>
                    </div>
                )}
                renderThumb={({ index, props, isDragged }) => (

                    <div className="input-range__dot-wrapper"
                        {...props}>
                        <span
                            className="input-range__dot-item"
                            style={{
                                backgroundColor: isDragged ? "#DEDEDE" : "#AB2F52"
                            }}
                        >
                            <SetLabel
                                prefix={index === 0 ? t("programs.age.from") : t("programs.age.to")}
                                showOnBottom={index === 1 ? offsetText : 0}
                                labelRef={
                                    newRef => {
                                        let dest = index === 0 ? lp1 : lp2;
                                        dest.current = newRef;
                                    }
                                }
                                index={values[index].toFixed()}
                            />
                        </span>
                    </div>
                )}
            />
        })}
    </>

}

export default function DefaultRangeField(props) {
    let {
        status,
        value,
        onChange,
        difference,
        fieldName,
        initialValue,
        author,
        user,
        additionalProperty
    } = props;

    let localInitialValue = [initialValue || 1, additionalProperty.initial_value || 100]
    let localValues = [parseInt(value) || 1, parseInt(additionalProperty.value) || 100]

    let localFieldNames = [fieldName, additionalProperty.fieldName]

    let localChange = (localValues) => {
        localFieldNames.forEach((field, index) => {
            onChange(field, localValues[index])
        })
    }

    let input = '';
    let subtitle = "";
    let inputTitle = "";
    let className = "diff-text diff-text--user"
    let t = useTranslations();

    let fieldStatus = status < additionalProperty.status ? additionalProperty.status : status

    let isAdmin = useIsAdmin();

    let render = {
        [statuses.STATUS_MANAGER_MODERATION]: () => {
            if (isAdmin) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            } else {
                subtitle = '123'
            }

            input = <DiffFieldRenderer {...props}
                className={className}
                direction={'vertical'}
                initialValue={localInitialValue}
                value={localValues}
                difference={difference}
                additionalProperty={additionalProperty}
                options={DEFAULT_OPTIONS}
                type="range"
            />
        },
        [statuses.STATUS_USER_MODERATION]: () => {
            if (isAdmin) {
                subtitle = t("general.setToUser")
                className = "diff-text diff-text--admin"
            } else {
                subtitle = t("general.ModeratorSuggestsChanges")
                className = "diff-text diff-text--admin"
            }

            input = <DiffFieldRenderer {...props}
                className={className}
                direction={'horizontal'}
                initialValue={localInitialValue}
                value={localValues}
                difference={difference}
                additionalProperty={additionalProperty}
                options={DEFAULT_OPTIONS}
                type="range"
            />
        },
        [statuses.STATUS_USER_EDIT]: () => {
            subtitle = <FormattedMessage id="general.setToModerator" />
            inputTitle = <FormattedMessage id="general.change" />
            input = <CustomRange
                values={localValues}
                options={DEFAULT_OPTIONS}
                onChange={localChange}
                disabled={false}
            />
        },
        [statuses.STATUS_MANAGER_EDIT]: () => {
            subtitle = t("general.setToUser")
            if (author.id !== user.model.id) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            }
            inputTitle = <FormattedMessage id="general.change" />
            input = <CustomRange
                values={localValues}
                options={DEFAULT_OPTIONS}
                onChange={localChange}
                disabled={false}
            />
        },
        [statuses.STATUS_EDIT]: () => {
            input = <CustomRange
                values={localValues}
                options={DEFAULT_OPTIONS}
                onChange={localChange}
                disabled={false}
            />
        },
        [statuses.STATUS_INITIAL]: () => {
            input = <CustomRange
                values={localInitialValue}
                options={DEFAULT_OPTIONS}
                onChange={localChange}
                disabled={true}
            />
        }
    }

    if (render[fieldStatus]) {
        render[fieldStatus]();
    } else {
        input = 'Status Not Handled'
    }

    return <WrapField {...props}
        inputPart={input}
        subtitle={subtitle}
        inputTitle={inputTitle}
        status={fieldStatus}
    />
}


import React from "react";
import InstituteHeader from "../../components/PageHeaders/InstituteHeader";
import Tab from "../Tabs";
import { useEffect } from 'react';

import SchoolService from '../../services/SchoolService';
import { useRecoilState } from 'recoil';
import {schoolModelState} from '../../store';

/**
 * Компонент выводит вкладки школы и загружает текущую школу в глобальное состояние
 */
function Institute(props) {
    let [school, setSchool] = useRecoilState(schoolModelState);

    let id = props.match.params.id;

    useEffect(() => {
        let fetch = async () => {
            if (id) {
                let schoolService = new SchoolService();
                let newSchool = await schoolService.get(id);
                setSchool(newSchool);
            } else {
                setSchool(null);
            }
        }

        fetch();
    }, [id]);

    return <div className="main-container">
        <InstituteHeader />
        <div className="w-container">
            <Tab {...props}  />
        </div>
    </div>
}

export default Institute;
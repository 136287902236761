import React, { useState } from "react";
import Logo from "../../components/Sidebar/Logo/Logo";
import SideLangSwitcher from "../../components/LangSwitcher/SideLangSwitcher";
import api from "../../api";
import { setUserToken } from "../../helpers";
import { useRecoilState } from "recoil";
import { userTokenState } from "../../store";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {Link} from 'react-router-dom';

function LoginForm() {
    let [model, setModel] = useState({});
    let [errors, setErrors] = useState({});
    let [token, setToken] = useRecoilState(userTokenState);
    let history = useHistory();
    
    let renderError = (field) => {
        if (errors[field]) {
            return <div class="form-error"> {errors[field].join(", ")} </div>;
        }
    };

let onSubmit = async (e) => {
    e.preventDefault();

    // reset all errors before request
    setErrors({});

    // try to login
    let data = await api.post("/api/login/", model);

    // render error if exists
    if (data.errors) {
        setErrors(data.errors);
    } else if (data.success && data.token) {
        setUserToken(data.token);
        setToken(data.token);
        history.push(`/institutions`);
    }
};

let changeField = (field) => (e) => {
    setModel(
        Object.assign({}, model, {
            [field]: e.target.value,
        })
    );
};

return (
    <div className="login-section">
        <div className="login-section__languages">
            <SideLangSwitcher />
        </div>{" "}
        <div className="smapse-logo">
            <Logo />
        </div>{" "}
        <div className="login-card">
            <div className="login-card__label">
                <FormattedMessage id="login.title" />
            </div>{" "}
            <div className="login-card__subtitle">
                <FormattedMessage id="login.subtitle" />
            </div>{" "}
            <form onSubmit={onSubmit} className="login-form">
            <input
                value={model.email || ""}
                onChange={changeField("email")}
                className="login-form__input"
                placeholder="Email"
            />{" "}
            {/*{renderError('email')}*/}{" "}
            <input
                value={model.password || ""}
                onChange={changeField("password")}
                type="password"
                className="login-form__input"
                placeholder="Password"
            />{" "}
            {renderError("email")}{" "}
            <button className="login-form__submit btn" type="submit">
                {" "}
                <FormattedMessage id="login.enter" />{" "}
            </button>{" "}
            </form>{" "}
            <div className="login-card__footer">
                <Link to="/register">
                    <FormattedMessage id="login.register" />
                </Link>
            </div>{" "}
        </div>{" "}
    </div>
);
}

export default LoginForm;

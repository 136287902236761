import React, {useEffect, useState, useRef} from "react";
import CkEditorLoadManager from './CkeditorManager';


function Ckeditor(props) {
    let {onChange, value} = props;

    let onChangeRef = useRef(null);
    onChangeRef.current = onChange;

    let [localValue, setLocalValue] = useState()
    let [isReady, setIsReady] = useState(false);
    let textArea = useRef(null);
    let [isInited, setIsInited] = useState(false);
    let ckEditorInstance = useRef(null);


    useEffect(() => {
        setLocalValue(value);
    }, [value])

    useEffect(() => {
        setLocalValue(value);
        if (ckEditorInstance.current && localValue !== value) {
            ckEditorInstance.current.setData(value);
        }

    }, [value])

    useEffect(() => {
        CkEditorLoadManager.addEventHandler(() => {
            setIsReady(true);
        })
    }, [])

    useEffect(() => {
        if (isReady && textArea.current !== null && !isInited) {
            let _ckEditorInstance = window.CKEDITOR.replace(textArea.current);
            _ckEditorInstance.on('change', function () {
                let newHtml = _ckEditorInstance.getData();

                setLocalValue(newHtml);
                if (onChangeRef.current) {
                    onChangeRef.current(newHtml);
                }
            });

            ckEditorInstance.current = _ckEditorInstance;
            ckEditorInstance.current.setData(value);
            setIsInited(true);
        }
    }, [textArea, isReady]);

    return <div className="ckEditor">
        <textarea ref={textArea}/>
    </div>
}

export default Ckeditor;
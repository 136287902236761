import { useContext, useEffect, useState } from "react";
import CircleChart from "../CircleChart/CircleChart";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import { LanguageContext } from "../../context/LanguageContext";
import { useRecoilValue } from "recoil";
import { languageState, schoolModelState, } from "../../store";
import { getSchoolModel } from "../../helpers";
import { useTranslations } from "../../context/LanguageContext";
import HeaderSchoolLangSwitcher from "../LangSwitcher/HeaderSchoolLangSwitcher";
import IconClip from "../Icons/IconClip";
import { getRecoilExternalLoadable } from "../../RecoilExternalStatePortal";

export function InstituteHeader(props) {
    let languageContext = useContext(LanguageContext);
    let language = languageContext.language;
    let history = useHistory();
    let schoolModel = useRecoilValue(schoolModelState);
    let [model, setModel] = useState();
    const [link, setLink] = useState('');
    let t = useTranslations();

    function getApiHost(){
        let lang = getRecoilExternalLoadable(languageState);
    
        const map = {
            'ru': process.env.REACT_APP_API_HOST_RU,
            'en': process.env.REACT_APP_API_HOST_EN,
        };
    
        return lang ? map[lang.contents] : process.env.REACT_APP_API_HOST_RU;
    }

    let schoolif = model && model.translations && model.translations[language];

    useEffect(() => {
        if (schoolModel) {
            setModel(schoolModel);
        } else {
            setModel(getSchoolModel())
        }
        if (schoolif && model.translations[language].url) {
            setLink(schoolif && model.translations[language].url)
        }
    });

    useEffect(() => {
        if (schoolModel) {
            setModel(schoolModel);
        } else {
            setModel(getSchoolModel())
        }
    }, [language])
    

    useEffect(() => {
        if (schoolif && model.translations[language].url) {
            setLink(model.translations[language].url);
        }
    }, [language]);

    return <>
        <BreadCrumb label={t("breadcrumb.institution")}
            onClick={() => history.push("/institutions")} />

        <div className="heading">
            <h1>{schoolif ? model.translations[language].name : t("institutions.no-name")}</h1>
            <CircleChart percentage={model && model.fill && model.fill[language] ? model.fill[language] : "ru"}
                className="single-chart single-chart--small" />
        </div>
        <div className="heading__link">
            <IconClip link={link}/> 
            <HeaderSchoolLangSwitcher />
            {/* ссылка на школу */}
            <span>{link ? getApiHost() + link : t("institutions.no_url")}</span>
        </div>
        
    </>
}

export default InstituteHeader;
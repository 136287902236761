import React from "react";
import {statuses} from "../../constants/statuses";
import DiffFieldRenderer from "./DiffFieldRenderer";
import WrapField from "./WrapField";
import HereMap from "../HereMap/HereMap";
import { FormattedMessage } from "react-intl";
import { useIsAdmin } from "../../store";
import { useTranslations } from "../../context/LanguageContext";

function AddressField(props) {
    let {
        status,
        value,
        onChange,
        difference,
        fieldName,
        initialValue,
        author,
        user,
        moderator,
        save
    } = props;

    let isAdmin = useIsAdmin();

    let inputPart = '?';
    let subtitle = '';
    let inputTitle = '';
    let className = "diff-text diff-text--user"
    let t = useTranslations();

    let render = {
        [statuses.STATUS_MANAGER_MODERATION]: () => {
            if (isAdmin) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            } else {
                subtitle = <FormattedMessage id = "general.setToModerator"/>
            }

            inputPart = <DiffFieldRenderer
                className={className}
                direction={'horizontal'}
                initialValue={initialValue}
                difference={difference}/>
        },
        [statuses.STATUS_USER_MODERATION]: () => {
            if (isAdmin) {
                subtitle = t("general.setToUser")
                className = "diff-text diff-text--admin"
            } else {
                subtitle = t("general.ModeratorSuggestsChanges")
                className = "diff-text diff-text--admin"
            }

            inputPart = <DiffFieldRenderer
                className={className}
                direction={'horizontal'}
                initialValue={initialValue}
                difference={difference}/>
        },
        [statuses.STATUS_USER_EDIT]: () => {
            subtitle = <FormattedMessage id = "general.setToModerator"/>
            inputTitle = <FormattedMessage id="general.change"/>
            inputPart = <HereMap
                fieldName={fieldName}
                value={value}
                onChange={value => onChange(fieldName, value)}
                onClick={save}
                language={props.language}
            />
        },
        [statuses.STATUS_MANAGER_EDIT]: () => {
            subtitle = t("general.setToUser")
            if (author.id !== user.model.id) {
                subtitle = t("general.user") + " " + author.name + " " + t("general.suggestsChanges")
            }
            inputTitle = <FormattedMessage id ="general.change"/>
            inputPart = <HereMap
                fieldName={fieldName}
                value={value}
                onChange={value => onChange(fieldName, value)}
                onClick={save}
                language={props.language}
            />
        },
        [statuses.STATUS_EDIT]: () => {
            inputPart = <HereMap
                fieldName={fieldName}
                value={value}
                onChange={value => onChange(fieldName, value)}
                onClick={save}
                language={props.language}
            />
        },
        [statuses.STATUS_INITIAL]: () => {
            inputPart = <input type="text" name={fieldName} readOnly={true} value={value} disabled={true}/>
        }
    }

    if (render[status]) {
        render[status]();
    } else {
        inputPart = 'Status Not Handled'
    }

    return <WrapField {...props}
                        inputPart={inputPart}
                        subtitle={subtitle}
                        inputTitle={inputTitle}
                        status={status}
                        langSwitch={false}
    />
}

export default AddressField;
import React, { useContext, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useFieldsLanguage } from "../../context/LanguageContext";
import { userState } from "../../store";

/**
 * Формирует новый объект
 * @param {*} model - Школа или другая модель
 * @param {*} modelProperty - Поле в модели
 * @returns 
 */
function buildComponentProps(model, modelProperty) {

    let propsNames = [
        "value",
        "initial_value",
        "diff",
        "initialRenderStatus",
        "status",
        "author",
        "initial_subjects"
    ];

    let componentProps = [];

    propsNames.map((item, index) => {
        if (model[modelProperty] !== undefined) {
            componentProps[item] = model[modelProperty][item]
        }
    })

    componentProps['fieldName'] = modelProperty;

    return componentProps;
}


function RenderField({ field, model, save, onChange, handleUserRequest, isLoading }) {

    // language group for concrete field
    let [fieldsLanguage] = useFieldsLanguage();
    let [localLanguage, setLocalLanguage] = useState(fieldsLanguage);
    useEffect(() => {
        setLocalLanguage(fieldsLanguage)
    }, [fieldsLanguage]);
    // end language group

    let name = field.name;
    let additionalProperty = [];

    if (name.indexOf('{lang}' !== -1)) {
        name = name.replace('{lang}', localLanguage);
    }

    let user = useRecoilValue(userState);
    let Component = field.component
    let radioOptions = field.options

    let componentProps = buildComponentProps(model, name);

    if (field.addName) {
        additionalProperty = buildComponentProps(model, field.addName)
    }

    return <div>
        <Component
            setLanguage={setLocalLanguage}
            language={localLanguage}

            status={componentProps.status}
            fieldName={name}
            title={field.title} required={field.required} tip={field.tip}
            initialValue={componentProps.initial_value}
            value={componentProps.value}
            difference={componentProps.diff}
            save={save}
            onChange={onChange}
            options={componentProps.initial_subjects}
            radioOptions={radioOptions}
            initialRenderStatus={componentProps.initialRenderStatus}
            author={componentProps.author}
            user={user}
            handleUserRequest={handleUserRequest}
            additionalProperty={additionalProperty}
            isLoading={isLoading}

        />
    </div>
}

export default RenderField;
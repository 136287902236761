import React from "react";


function BreadCrumb(props) {
    return <div className="breadcrumbs">
            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 1L2 5L6 9" stroke="#C4C4C4" strokeWidth="2"/>
            </svg>
            <a onClick={props.onClick}>{props.label}</a>
        </div>
}

export default BreadCrumb;